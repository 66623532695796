import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AssetService,
  UserDetailsWithAssetsViewDto,
  UserService,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {GroupTableDetailsComponent} from '../group-table-details/group-table-details.component';
import {AssetsTableDetailsComponent} from '../assets-table-details/assets-table-details.component';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ClientsTableDetailsComponent} from '../clients-table-details/clients-table-details.component';
import {SubFolderType} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';
import {IdentityProviderTranslator} from '../../../../../shared/identity-provider-translator';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  assetService: AssetService;
  userService: UserService;
  userId?: string;
  groupId?: string;
  userDetails?: UserDetails;
  displayName?: string;
  private tenantId?: string;

  @ViewChild(AssetsTableDetailsComponent)
  assetsTable?: AssetsTableDetailsComponent;
  @ViewChild(GroupTableDetailsComponent)
  groupsTable?: GroupTableDetailsComponent;
  @ViewChild(ClientsTableDetailsComponent)
  clientsTable?: ClientsTableDetailsComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private apiClientFactory: ApiClientFactoryService
  ) {
    this.assetService = apiClientFactory.getAssetService();
    this.userService = apiClientFactory.getUserService();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;

      this.route.params.subscribe((params: Params) => {
        this.userId = params['userId'];
        this.groupId = params['groupId'] || '';
        this.assetService.getUserDetailsWithAssets(this.tenantId!, this.userId!).subscribe({
          next: async (n: UserDetailsWithAssetsViewDto) => {
            this.userDetails = n;
            this.displayName = this.renderDisplayName(n);

            this.assetsTable?.loadAssets(n.assets);
            this.groupsTable?.loadGroups(n.groups);
            this.clientsTable?.loadClients(n.clients);
          },
          error: async (_: any) => {
            this.toastService.showError(
              this.translateService.instant('common.error'),
              this.translateService.instant('users.errorFetchingUser')
            );
          },
          complete: () => {},
        });
      });
    });
  }

  getIdentityProviderString(): string {
    return IdentityProviderTranslator.getIdentityProviderName(
      this.translateService,
      this.userDetails?.identityProvider
    );
  }

  private renderDisplayName(user: UserDetailsWithAssetsViewDto | undefined): string {
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  back(): Promise<boolean> {
    if (this.groupId !== '') {
      return this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Members]);
    }
    return this.router.navigate(['lancrypt', 'identities', 'users']);
  }

  resendActivation() {
    if (this.userId) {
      this.userService.resendActivation(this.tenantId!, this.userId).subscribe({
        next: (_: any) => {
          this.toastService.showSuccess(
            this.translateService.instant('common.success'),
            this.translateService.instant('activateAccount.activationEmailSent')
          );
        },
        error: (e: any) => {
          let msg = 'activateAccount.error.activateAccount';
          if (e instanceof HttpErrorResponse && e.status === 405) {
            msg = 'activateAccount.error.alreadyActivated';
          }
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant(msg)
          );
        },
      });
    }
  }
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  emailAddress: string;
  connectionName: string;
  connectionType: UserDetailsWithAssetsViewDto.ConnectionTypeEnum;
  lastProfileFetch?: Date;
  lastSignIn?: Date;
  identityProvider: string;
}
