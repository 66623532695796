import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IDropdownSettings, MultiSelectComponent} from 'ng-multiselect-dropdown';
import {DropDownItem} from 'src/app/dtos/lancrypt/dropDownItem';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-lancrypt-multiselect-dropdown',
  templateUrl: './lancrypt-multiselect-dropdown.component.html',
  styleUrls: ['./lancrypt-multiselect-dropdown.component.scss'],
})
export class LancryptMultiselectDropdownComponent implements OnInit, OnChanges {
  @Input() placeholder = 'Select';
  @Input() data: Array<DropDownItem> = [];
  @Input() selectedElements: Array<DropDownItem> = [];
  @Output() elementsSelected = new EventEmitter<DropDownItem[]>();
  @Output() elementsDeselected = new EventEmitter<DropDownItem[]>();
  @Output() allElementsDeselected = new EventEmitter<void>();

  @ViewChild(MultiSelectComponent)
  multiSelect!: MultiSelectComponent;

  dropdownSettings: IDropdownSettings = {};

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(_changes: SimpleChanges) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'itemId',
      textField: 'itemText',
      selectAllText: this.translateService.instant('dropdown.selectAll'),
      unSelectAllText: this.translateService.instant('dropdown.unSelectAll'),
      allowSearchFilter: true,
      itemsShowLimit: 2,
    };

    if (this.multiSelect && this.multiSelect.selectedItems && this.multiSelect.selectedItems.length === 0) {
      this.multiSelect.selectedItems = this.selectedElements.map(item => {
        return {
          id: item.itemId,
          text: item.itemText,
        };
      });
    }
  }

  onSelectAll(items: any) {
    this.elementsSelected.emit(items);
  }

  onItemSelect(item: any) {
    const array = [];
    array.push(item);

    this.elementsSelected.emit(array);
  }

  onDeSelect(item: any) {
    const array = [];
    array.push(item);

    this.elementsDeselected.emit(array);
  }

  onDeSelectAll() {
    this.allElementsDeselected.emit();
  }
}
