import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusyService {
  private _busySource = new BehaviorSubject<boolean>(false);
  busy$ = this._busySource.asObservable();

  private numberOfBusyRequests = 0;

  constructor() {}

  changeBusy(val: boolean) {
    if (val) {
      this.numberOfBusyRequests++;
    } else {
      this.numberOfBusyRequests--;
    }

    this._busySource.next(this.isBusy());
  }

  isBusy(): boolean {
    return this.numberOfBusyRequests !== 0;
  }
}
