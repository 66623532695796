import {NgModule} from '@angular/core';
import {DataExportComponent} from './data-export.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {DatePipe, NgIf} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [DataExportComponent],
  imports: [
    MatCardModule,
    MatProgressBarModule,
    BusyServiceModule,
    TranslateModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    DatePipe,
    NgIf,
  ],
})
export class DataExportModule {}
