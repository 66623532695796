import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditBasicInfoComponent} from './edit-basic-info/edit-basic-info.component';
import {EditTenantInfoComponent} from './edit-tenant-info/edit-tenant-info.component';
import {EditLanguageInfoComponent} from './edit-language-info/edit-language-info.component';
import {GridModule} from '@coreui/angular';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {MatCardModule} from '@angular/material/card';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [EditBasicInfoComponent, EditTenantInfoComponent, EditLanguageInfoComponent],
  exports: [EditBasicInfoComponent, EditTenantInfoComponent, EditLanguageInfoComponent],
  imports: [
    CommonModule,
    GridModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
    BusyServiceModule,
    MatProgressBarModule,
  ],
})
export class EditPersonalInfoModule {}
