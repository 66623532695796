<mat-expansion-panel class="mat-elevation-z1" [(expanded)]="expanded">
  <mat-expansion-panel-header class="title-card-header">
    <mat-panel-title class="settings-header">
      <h2>{{'settings.sync.title' | translate}}</h2>
    </mat-panel-title>
    <mat-action-row (click)=$event.stopPropagation()>
      <button mat-button class="edit-buton" (click)="onEdit()" color="lancrypt-primary-light" [disabled]="!settingsViewDto" [hidden]="editMode">{{'buttons.edit' | translate}}</button>
      <button mat-button class ="save-button" (click)="onSave()" color="lancrypt-primary-light" [hidden]="!editMode">{{'buttons.save' | translate}}</button>
      <button mat-button class ="cancel-button" (click)="onCancel()" [hidden]="!editMode">{{'buttons.cancel' | translate}}</button>
    </mat-action-row>
  </mat-expansion-panel-header>
  <mat-card style="box-shadow: none">
    <mat-card-content>
      <form [formGroup]="formGroup">
        <div class="settings-item">
          <span class="settings-title settings-align-with-slider">
            <mat-label class="settings-name">{{'settings.sync.refreshInterval.title' | translate}}</mat-label>
            <mat-icon class="settings-info-icon" inline="true" matTooltip="{{'settings.sync.refreshInterval.hint' | translate}}">info</mat-icon>
          </span>
          <span class="settings-value">
            <mat-slider (change)="onRefreshChanged()" min="1" max="24" discrete class="settings-field">
              <input matSliderThumb [formControl]="formGroup.controls.refreshInterval"/>
            </mat-slider>
            <span class="settings-space-before">{{refreshText}}</span>
          </span>
        </div>
        <div class="settings-item">
          <span class="settings-title settings-align-with-slider">
            <mat-label class="settings-name">{{'settings.sync.cacheExpiration.title' | translate}}</mat-label>
            <mat-icon class="settings-info-icon" inline="true" matTooltip="{{'settings.sync.cacheExpiration.hint' | translate}}">info</mat-icon>
          </span>
          <span class="settings-value">
            <mat-slider (change)="onCacheChanged()" min="1" max="90" discrete class="settings-field">
              <input matSliderThumb [formControl]="formGroup.controls.cacheExpiration"/>
            </mat-slider>
            <span class="settings-space-before">{{cacheText}}</span>
          </span>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</mat-expansion-panel>

