import {Component} from '@angular/core';
import {LancryptAssetsComponent} from '../../lancrypt-assets.component';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslationHelperService} from '../../../../../services/helper/translation-helper.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {BusyService} from '../../../../../shared/components/busy-service/busy.service';
import {PlaceholderService} from '../../placeholder.service';
import {AssetViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

@Component({
  selector: 'app-asset-recycle-bin',
  templateUrl: '../../lancrypt-assets.component.html',
  styleUrl: '../../lancrypt-assets.component.scss',
})
export class AssetRecycleBinComponent extends LancryptAssetsComponent {
  constructor(
    _jwtHelper: JwtHelperService,
    _apiClientFactory: ApiClientFactoryService,
    translateService: TranslateService,
    toastService: ToastService,
    translationHelperService: TranslationHelperService,
    router: Router,
    dialog: MatDialog,
    busyService: BusyService,
    placeholderService: PlaceholderService
  ) {
    super(
      _jwtHelper,
      _apiClientFactory,
      translateService,
      toastService,
      translationHelperService,
      router,
      dialog,
      busyService,
      placeholderService
    );
  }

  override isRecycleBin() {
    return true;
  }

  override getTitle() {
    return this.translateService.instant('headlines.assetRecycleBin');
  }

  override getColumnsToDisplay() {
    return ['name', 'location', 'type', 'date', 'delete'];
  }

  override callAssetAction(id: string) {
    if (!this.busyService.isBusy()) {
      return this.router.navigate(['lancrypt', 'assets', 'restore-asset', id]);
    }
    return Promise.resolve(false);
  }

  override editAsset(element: AssetViewDto) {
    return this.callAssetAction(element.id);
  }

  override getAssetActionTooltip(): any {
    return this.translateService.instant('buttons.restoreAsset');
  }

  override getAssetActionIcon(): string {
    return 'restore_from_trash';
  }
}
