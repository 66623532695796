import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AssignMembersCreateDto,
  SortOrderDto,
  UserAssignedDto,
  UserSearchRequestDto,
  UserService,
  UserTreeViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {SubFolderType} from 'src/app/dtos/lancrypt/GroupTreeViewIconDto';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {ToastService} from 'src/app/services/toaster.service';
import {MatSort} from '@angular/material/sort';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from '../../../../../shared/lancrypt.constants';

@Component({
  selector: 'app-members-assign',
  templateUrl: './members-assign.component.html',
  styleUrls: ['./members-assign.component.scss'],
})
export class MembersAssignComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['select', 'name', 'emailAddress'];
  dataSource = new MatTableDataSource<UserTreeViewDto>();
  selection = new SelectionModel<UserTreeViewDto>(true, []);

  pageSize = DEFAULT_PAGE_SIZE;
  currentPage = 0;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  totalRows!: number;

  sortSettings: SortOrderDto = {
    descending: false,
    column: 'name',
  };

  nameFilterValue = '';
  nameFilter = new Subject<string>();
  nameFilterTrigger = this.nameFilter.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged());

  groupId = '';
  tenantId = '';

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  private userService: UserService;
  private assignedUserIds: Set<string> = new Set<string>();

  constructor(
    protected toastService: ToastService,
    protected jwtHelperService: JwtHelperService,
    private apiClientFactory: ApiClientFactoryService,
    protected translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userService = this.apiClientFactory.getUserService();
    this.setupSortingProperties();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;

      this.route.params.subscribe(params => {
        this.groupId = params['groupId'];

        this.userService.getUsersAssignedToGroup(this.tenantId, this.groupId).subscribe({
          next: (n: UserAssignedDto[]) => {
            n.forEach(userDto => {
              this.assignedUserIds.add(userDto.id);
            });
            this.loadMembers();
          },
        });
      });
    });

    this.nameFilterTrigger.subscribe(currentValue => {
      this.currentPage = 0;
      this.nameFilterValue = currentValue.trim().toLowerCase();
      this.loadMembers();
    });
  }

  ngAfterViewInit(): void {
    this.sort!.sortChange.subscribe(sortEvent => {
      this.sortSettings = {
        column: sortEvent.active,
        descending: sortEvent.direction === 'desc',
      };
      this.loadMembers();
    });
  }

  cancel() {
    this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Members]);
  }

  getAssignCreateDto(): AssignMembersCreateDto {
    return {
      groupId: this.groupId,
      userIds: Array.from(this.assignedUserIds.values()),
    };
  }

  addAssignment() {
    const createDto = this.getAssignCreateDto();

    this.userService.assignMembersToGroup(createDto, this.tenantId, this.groupId).subscribe({
      next: async (_: any) => {
        this.toastService.showSuccess(
          this.translateService.instant('common.success'),
          this.translateService.instant('users.successfullAssigned')
        );
        this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Members]);
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('users.errorAssigningMembers')
        );
      },
      complete: () => {},
    });
  }

  applyFilter(event: Event) {
    this.nameFilter.next((event.target as HTMLInputElement).value);
  }

  getUserFullName(userTreeViewDto: UserTreeViewDto): String {
    return userTreeViewDto.displayName;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (!this.isAllSelected()) {
      this.selection.select(...this.dataSource.data);
      this.dataSource.data.forEach(userDto => {
        this.assignedUserIds.add(userDto.id);
      });
    } else {
      this.selection.selected.forEach(userDto => {
        this.assignedUserIds.delete(userDto.id);
      });
      this.selection.clear();
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UserTreeViewDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.emailAddress}`;
  }

  private loadMembers() {
    if (!this.tenantId || !this.groupId) {
      return;
    }

    const searchRequestDto: UserSearchRequestDto = {
      pageRequest: {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      },
      sortOrder: this.sortSettings,
      searchString: this.nameFilterValue,
    };

    this.userService.getAllUsersWithGroupAssignedFirst(searchRequestDto, this.tenantId, this.groupId).subscribe({
      next: async (n: any) => {
        this.dataSource.data = n.content;
        this.totalRows = n.totalElements;
        this.paginator.pageIndex = this.currentPage;

        this.setSelection();
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('users.errorLoadingAssignableUsers')
        );
      },
      complete: () => {},
    });
  }

  private setSelection() {
    const selected = this.dataSource.data.filter(value => {
      return this.assignedUserIds.has(value.id);
    });
    this.selection.setSelection(...selected);
  }

  private setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'name':
          return this.getUserFullName(data).toLowerCase();
        case 'emailAddress':
          return data.emailAddress.toLowerCase();
        default:
          return (data as any)[sortHeaderId];
      }
    };
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadMembers();
  }

  toggleSelection(row: UserTreeViewDto) {
    if (!this.selection.isSelected(row)) {
      this.assignedUserIds.add(row.id);
    } else {
      this.assignedUserIds.delete(row.id);
    }
    this.selection.toggle(row);
  }
}
