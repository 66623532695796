import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {
  SettingsService,
  SettingsFileEncryptionUpdateDto,
  SettingsViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';

@Component({
  selector: 'app-settings-file-encryption',
  templateUrl: './settings-file-encryption.component.html',
  styleUrls: ['../../lancrypt-settings.component.scss'],
})
export class SettingsFileEncryptionComponent implements OnChanges, OnInit {
  formGroup;
  expanded = true;
  editMode = false;

  @Input() settingsViewDto: SettingsViewDto | undefined;

  private settingsApiClient: SettingsService;
  private tenantId!: string;

  constructor(
    private _formBuilder: FormBuilder,
    private translationService: TranslateService,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private jwtHelperService: JwtHelperService
  ) {
    this.settingsApiClient = apiClientFactory.getSettingsService();
    this.formGroup = this._formBuilder.group({
      allowDecryption: new FormControl<Boolean | undefined>(false),
      enablePersistentEncryption: new FormControl<Boolean | undefined>(false),
    });
  }

  async ngOnInit() {
    this.tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.formGroup.disable();
  }

  ngOnChanges(): void {
    this.updateFormData();
  }

  onEdit() {
    this.editMode = true;
    this.expanded = true;
    this.formGroup.enable();
  }

  onSave() {
    const updateDto: SettingsFileEncryptionUpdateDto = {
      allowDecryption: this.formGroup.controls.allowDecryption.value as boolean,
      persistent: this.formGroup.controls.enablePersistentEncryption.value as boolean,
      tenantId: this.tenantId,
    };

    this.settingsApiClient.updateSettingsFileEncryption(updateDto, this.tenantId).subscribe({
      next: async () => {
        this.toastService.showSuccess(
          this.translationService.instant('settings.saveToast.title'),
          this.translationService.instant('settings.saveToast.successful')
        );
        this.editMode = false;
        this.formGroup.disable();
        if (this.settingsViewDto) {
          this.settingsViewDto.fileEncryption.userOptions.allowDecryption = updateDto.allowDecryption!;
          this.settingsViewDto.fileEncryption.encryption.persistent = updateDto.persistent!;
        }
      },
      error: async (e: HttpErrorResponse) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('settings.errors.saving', {
            error: e.status,
          })
        );
      },
    });
  }

  onCancel() {
    this.updateFormData();
    this.editMode = false;
    this.formGroup.disable();
  }

  private updateFormData() {
    if (this.settingsViewDto) {
      this.formGroup.patchValue({
        allowDecryption: this.settingsViewDto.fileEncryption.userOptions.allowDecryption,
        enablePersistentEncryption: this.settingsViewDto.fileEncryption.encryption.persistent,
      });
    }
  }
}
