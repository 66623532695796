import {Component} from '@angular/core';
import {ClientService, TenantService, TenantViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent {
  private clientService: ClientService;
  private tenantService: TenantService;
  usedLicensesInMonth = 0;
  licenseType: string = this.translationService.instant('subscriptions.licenseType.trial');
  licenseCount = 99;
  licensePurchaseDate: Date = new Date();
  licensePeriod = 1;
  licenseExpirationDate: Date;

  constructor(
    private apiClientFactoryService: ApiClientFactoryService,
    private toastService: ToastService,
    private jwtHelperService: JwtHelperService,
    private translationService: TranslateService
  ) {
    this.licenseExpirationDate = this.getExpirationDate();
    this.clientService = apiClientFactoryService.getClientService();
    this.tenantService = apiClientFactoryService.getTenantService();
  }

  async ngOnInit() {
    await this.getUsedLicenses();
    await this.getTenantInfo();
  }

  async getUsedLicenses() {
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.clientService.getActiveUsersCount(tenantId).subscribe({
      next: async (n: number) => {
        this.usedLicensesInMonth = n;
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('subscriptions.errors.userCountError')
        );
      },
    });
  }

  async getTenantInfo() {
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.tenantService.getTenantById(tenantId).subscribe({
      next: (n: TenantViewDto) => {
        switch (n.licenseType) {
          case TenantViewDto.LicenseTypeEnum.DISCOVER:
            this.licenseType = this.translationService.instant('subscriptions.licenseType.discover');
            break;
          case TenantViewDto.LicenseTypeEnum.STANDARD:
            this.licenseType = this.translationService.instant('subscriptions.licenseType.standard');
            break;
          case TenantViewDto.LicenseTypeEnum.ADVANCED:
            this.licenseType = this.translationService.instant('subscriptions.licenseType.advanced');
            break;
          default:
            this.licenseType = this.translationService.instant('subscriptions.licenseType.trial');
        }
        this.licenseCount = n.purchasedNumber;
        this.licensePurchaseDate = n.purchasedDate;
        this.licensePeriod = n.subscriptionPeriod;
        this.licenseExpirationDate = this.getExpirationDate();
      },
      error: (_: any) => {
        this.licenseType = this.translationService.instant('subscriptions.licenseType.trial');
        this.licenseCount = 99;
        this.licensePurchaseDate = new Date();
        this.licensePeriod = 1;
        this.licenseExpirationDate = this.getExpirationDate();
      },
    });
  }

  private getExpirationDate(): Date {
    const expDate: Date = new Date(this.licensePurchaseDate);
    expDate.setMonth(expDate.getMonth() + this.licensePeriod);
    return expDate;
  }
}
