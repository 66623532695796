import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {GroupTreeViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {MatDialog} from '@angular/material/dialog';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {GroupElement, GroupTableCommonComponent} from '../group-table-common/group-table-common.component';

@Component({
  selector: 'app-group-table-subgroups',
  templateUrl: '../group-table-common/group-table-common.component.html',
  styleUrls: ['../group-table-common/group-table-common.component.scss'],
})
export class GroupTableSubgroupsComponent extends GroupTableCommonComponent implements OnChanges {
  override readonly noGroupsRowTextKey: string = 'groups.noSubGroups';

  @Input()
  groupId: string | undefined;

  @Output()
  groupDeleted = new EventEmitter<void>();

  constructor(
    dialog: MatDialog,
    apiClientFactory: ApiClientFactoryService,
    jwtHelperService: JwtHelperService,
    toastService: ToastService,
    translateService: TranslateService
  ) {
    super(dialog, apiClientFactory, jwtHelperService, toastService, translateService);
  }

  override async ngOnInit() {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.loadSubGroups();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['groupId'] || changes['tenantId']) {
      this.loadSubGroups();
    }
  }

  override showDelete(element: GroupElement): boolean {
    return !element.synced;
  }

  override deleteGroup(element: GroupElement): void {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('groups.deleteGroup'),
          description: this.translateService.instant('groups.deleteGroupText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.groupService.deleteGroup(this.tenantId!, element.id).subscribe({
            next: (_: any) => {},
            error: (_: any) => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('groups.errorDeletingGroup')
              );
            },
            complete: () => {
              this.loadSubGroups();
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('groups.groupDeleted')
              );
              this.groupDeleted.emit();
            },
          });
        }
      });
  }

  private loadSubGroups() {
    if (!this.groupId || !this.tenantId) {
      return;
    }

    this.dataSource.data = [];

    this.groupService
      .getGroupHierarchy(this.tenantId!, this.groupId!, GroupTreeViewDto.TreeHierarchyDirectionEnum.DESCENDANTS)
      .subscribe({
        next: async (n: GroupTreeViewDto) => {
          if (this.groupId === n.id) {
            this.dataSource.data = this.mapGroupElements(n.children);
          }
        },
        error: async (_: any) => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('groups.errorLoadingGroupHierarchy')
          );
        },
        complete: () => {},
      });
  }
}
