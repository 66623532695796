import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/*
 * Returns the given string if not null and not empty, otherwise "<not available>" will be returned.
 *
 * Usage:
 *   str | notAvailable
 *
 * Examples:
 *   {{ "Hello world!" | notAvailable }}
 *   formats to: "Hello world!"
 *
 *   {{ "" | notAvailable }}
 *   formats to: "<not available>"
 */
@Pipe({name: 'notAvailable'})
export class NotAvailablePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(str?: string | null): string {
    return str ? str : this.translateService.instant('common.notAvailable');
  }
}
