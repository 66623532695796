import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterModule, GridModule} from '@coreui/angular';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {AdministratorsComponent} from './administrators.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {AdministratorCommonComponent} from './components/administrator-common/administrator-common.component';
import {AdministratorCreateComponent} from './components/administrator-create/administrator-create.component';
import {AdministratorUpdateComponent} from './components/administrator-update/administrator-update.component';

@NgModule({
  declarations: [
    AdministratorsComponent,
    AdministratorCommonComponent,
    AdministratorCreateComponent,
    AdministratorUpdateComponent,
  ],
  imports: [
    CommonModule,
    GridModule,
    MatCardModule,
    TranslateModule,
    MatButtonModule,
    FooterModule,
    MatFormFieldModule,
    MatProgressBarModule,
    BusyServiceModule,
    MatTooltipModule,
    MatIconModule,
    AppRoutingModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AdministratorsModule {}
