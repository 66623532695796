<mat-card class="col-md-8 col-sm-10 col-xs-12 col-lg-8">
  <div class="header dense-2">
    <mat-card-title>{{'dataExport.keyExport' | translate}}</mat-card-title>
    <div class="control-elements">
      <button mat-mini-fab color="lancrypt-primary" (click)="startExport()" disableDuringRequest
              matTooltip="{{'dataExport.startExport' | translate}}">
        <mat-icon>output</mat-icon>
      </button>
    </div>
  </div>
  <div class="description">
    {{'dataExport.keyExportDescription' | translate}}
  </div>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

      <!-- Created Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>{{'dataExport.created' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.created | date: 'short'}}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{'dataExport.status' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{statusText(element)}}</td>
      </ng-container>

      <!-- Downloaded Column -->
      <ng-container matColumnDef="downloaded">
        <th mat-header-cell *matHeaderCellDef>{{'dataExport.downloaded' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{(element.downloaded != null) ? (element.downloaded | date: 'short') : ('dataExport.neverDownloaded' | translate)}}</td>
      </ng-container>

      <!-- Download Column -->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-mini-fab color="lancrypt-primary" *ngIf="isAvailable(element)" (click)="downloadFile(element)"
                  matTooltip="{{'dataExport.downloadExportedKeys' | translate}}">
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell mat-cell-empty" [attr.colspan]="displayedColumns.length">
          {{'dataExport.noData' | translate}}
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
  </mat-card-footer>
</mat-card>
