import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  AssetService,
  AuditLogService,
  ClientService,
  Configuration,
  FileService,
  GroupService,
  IdentityProviderConnectionService,
  KeyExportService,
  SettingsService,
  TenantService,
  UserService,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc/';
import {environment} from '../../environments/environment';
import {KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class ApiClientFactoryService {
  constructor(
    private httpClient: HttpClient,
    protected readonly keycloak: KeycloakService
  ) {}

  public getTenantService(): TenantService {
    return new TenantService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getGroupService(): GroupService {
    return new GroupService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getUserService(): UserService {
    return new UserService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getAssetService(): AssetService {
    return new AssetService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getAuditLogService(): AuditLogService {
    return new AuditLogService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getIdentityProviderConnectionService(): IdentityProviderConnectionService {
    return new IdentityProviderConnectionService(
      this.httpClient,
      environment.backends.portalFeCmpBaseUrl,
      new Configuration()
    );
  }

  public getClientService(): ClientService {
    return new ClientService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getSettingsService(): SettingsService {
    return new SettingsService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getFileService(): FileService {
    return new FileService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }

  public getKeyExportService(): KeyExportService {
    return new KeyExportService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }
}
