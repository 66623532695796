import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AssetElement, AssetsTableCommonComponent} from '../assets-table-common/assets-table-common.component';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';
import {MatDialog} from '@angular/material/dialog';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {GroupTreeViewFlatNode} from '../../../../../dtos/lancrypt/GroupTreeViewFlatNode';
import {ActivatedRoute} from '@angular/router';
import {AssetViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

@Component({
  selector: 'app-assets-table-groups',
  templateUrl: '../assets-table-common/assets-table-common.component.html',
  styleUrls: ['../assets-table-common/assets-table-common.component.scss'],
})
export class AssetsTableGroupsComponent extends AssetsTableCommonComponent implements OnChanges {
  @Input() activeNode: GroupTreeViewFlatNode | undefined;

  constructor(
    route: ActivatedRoute,
    _jwtHelper: JwtHelperService,
    _apiClientFactory: ApiClientFactoryService,
    translationService: TranslateService,
    toastService: ToastService,
    dialog: MatDialog
  ) {
    super(route, _jwtHelper, _apiClientFactory, translationService, toastService, dialog);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.activeNode !== undefined) {
      this.fetchAssets();
    }
  }

  override canRemoveAssignment(element: AssetElement): boolean {
    return element.access === AssetViewDto.AssetAccessEnum.GRANTED;
  }

  override removeAssignment(element: AssetElement) {
    const dialogRef = this.dialog.open(DualOptionDialog, {
      width: '500px',
      data: {
        title: this.translationService.instant('treeview.removeAssignment'),
        description: this.translationService.instant('treeview.removeAssignmentEnquiry'),
        positiveTitle: this.translationService.instant('common.confirm'),
        negativeTitle: this.translationService.instant('common.cancel'),
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this._jwtHelper.getTenantIdFromToken().then(tenantId => {
            this.assetService.deleteAssetAssignmentFromGroup(tenantId, element.id, this.activeNode!.id).subscribe({
              next: async (_: any) => {
                this.toastService.showSuccess(
                  this.translationService.instant('common.success'),
                  this.translationService.instant('treeview.deletedAssetAssignmentFromGroup')
                );
                this.fetchAssets();
              },
              error: async (_: any) => {
                this.toastService.showError(
                  this.translationService.instant('common.error'),
                  this.translationService.instant('errors.deleteAssetAssignmentFromGroup')
                );
              },
            });
          });
        }
      },
    });
  }

  private fetchAssets() {
    this._jwtHelper.getTenantIdFromToken().then(tenantId => {
      this.assetService.getGroupAssignedAssets(tenantId, this.activeNode!.id, true).subscribe({
        next: async (n: AssetViewDto[]) => {
          this.dataSource.data = this.mapAssetElements(n);
        },
        error: async (_: any) => {
          this.toastService.showError(
            this.translationService.instant('common.error'),
            this.translationService.instant('errors.gettingAssetList')
          );
        },
      });
    });
  }
}
