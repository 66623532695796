import {Component} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  IdentityProviderConnectionUpdateDto,
  IdentityProviderConnectionViewDto,
  UpdateResult,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {ToastService} from 'src/app/services/toaster.service';
import {ConnectionCommonComponent} from '../connection-common/connection-common.component';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-connection-update',
  templateUrl: '../connection-common/connection-common.component.html',
  styleUrls: ['../connection-common/connection-common.component.scss'],
})
export class ConnectionUpdateComponent extends ConnectionCommonComponent {
  private connectionId?: string;

  constructor(
    apiClientFactory: ApiClientFactoryService,
    jwtHelperService: JwtHelperService,
    formBuilder: FormBuilder,
    translateService: TranslateService,
    toastService: ToastService,
    router: Router,
    private route: ActivatedRoute
  ) {
    super(formBuilder, apiClientFactory, jwtHelperService, translateService, toastService, router);
  }

  override ngOnInit(): void {
    this.formGroup.controls.connectionAzureTenantId.disable();
    this.formGroup.controls.connectionClientId.disable();

    this.route.params.subscribe((params: Params) => {
      this.connectionId = params['connectionId'];
      this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
        this.tenantId = tenantId;
        this.connectionService.getConnectionById(this.tenantId!, this.connectionId!).subscribe({
          next: (n: IdentityProviderConnectionViewDto) => {
            this.formGroup.controls.connectionName.setValue(n.name);
            this.formGroup.controls.connectionAzureTenantId.setValue(n.params.tenantId);
            this.formGroup.controls.connectionClientId.setValue(n.params.clientId);
            this.chosenIdentityProvider = n.identityProvider
              ? n.identityProvider
              : IdentityProviderConnectionUpdateDto.IdentityProviderEnum.LOCAL;
          },
          error: (_: any) => {},
          complete: () => {},
        });
      });
    });
  }

  public override getTitle(): string {
    return 'headlines.editIdentityProviderConnection';
  }

  public override getSubTitle(): string {
    return 'subheadline.editIdentityProviderConnectionSub';
  }

  public override getErrorMessage(): string {
    return 'errors.editIdentityProviderConnection';
  }

  public override getStepTitle(): string {
    return 'identityProviderConnection.stepTitle.editConnection';
  }

  public override getNextTitle(): string {
    return 'buttons.update';
  }

  public override getSuccessMessage(): string {
    return 'identityProviderConnection.connectionUpdated';
  }

  protected override isClientSecretOptional(): boolean {
    return true;
  }

  override getClientSecretText() {
    return 'identityProviderConnection.azureActiveDirectory.secretNew';
  }

  protected override buildConnectionDto(): IdentityProviderConnectionUpdateDto {
    return {
      id: this.connectionId!,
      name: this.formGroup.controls.connectionName.value ?? '',
      connectionType: 'AZURE_AD',
      identityProvider: this.chosenIdentityProvider,
      connectionParams: {
        tenantId: this.formGroup.controls.connectionAzureTenantId.value ?? '',
        clientId: this.formGroup.controls.connectionClientId.value ?? '',
        clientSecret: this.formGroup.controls.connectionClientSecret.value ?? '',
      },
    };
  }

  protected override callService(tenantId: string, dto: IdentityProviderConnectionUpdateDto): Observable<boolean> {
    return this.connectionService.updateConnection(dto, tenantId).pipe(map((result: UpdateResult) => result.success));
  }
}
