import {Component} from '@angular/core';
import {AssetCreateDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {FormBuilder} from '@angular/forms';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AssetCommonComponent} from '../asset-common/asset-common.component';
import {Observable} from 'rxjs';
import {PlaceholderService} from '../../placeholder.service';

@Component({
  selector: 'app-asset-create',
  templateUrl: '../asset-common/asset-common.component.html',
  styleUrls: ['../asset-common/asset-common.component.scss'],
})
export class AssetCreateComponent extends AssetCommonComponent {
  constructor(
    _formBuilder: FormBuilder,
    _apiClientFactory: ApiClientFactoryService,
    _jwtHelperService: JwtHelperService,
    toastService: ToastService,
    translationService: TranslateService,
    router: Router,
    placeholderService: PlaceholderService
  ) {
    super(
      _formBuilder,
      _apiClientFactory,
      _jwtHelperService,
      toastService,
      translationService,
      router,
      placeholderService
    );
  }

  protected override buildDto(): AssetCreateDto {
    return {
      name: this.formGroup.controls.name.value ?? '',
      location: this.placeholderService.translateFromLocalizedPlaceholder(this.formGroup.controls.location.value ?? ''),
      type: this.formGroup.controls.assetType.value!,
      grantAccessGroups: this.formGroup.controls.groupIds.value ?? [''],
      grantAccessUsers: this.formGroup.controls.userIds.value ?? [''],
    };
  }

  protected override callService(tenantId: string, dto: AssetCreateDto): Observable<any> {
    return this.assetService.createAsset(dto, tenantId);
  }

  override getTitle(): string {
    return this.translationService.instant('assets.asset');
  }

  override getErrorMessage(): string {
    return this.translationService.instant('assets.errorCreatingAsset');
  }

  override getSuccessMessage(): string {
    return this.translationService.instant('assets.assetCreated');
  }
}
