import {Component, OnInit} from '@angular/core';
import {UserService, UserViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ActivatedRoute, Params} from '@angular/router';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../services/toaster.service';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';

export enum ViewError {
  None = 0,
  Unknown = 1,
  UserNotFound = 2,
}

@Component({
  selector: 'app-user-activate-account',
  templateUrl: './user-activate-account.component.html',
  styleUrls: ['./user-activate-account.component.scss'],
})
export class UserActivateAccountComponent implements OnInit {
  user: UserViewDto | undefined;

  userService: UserService;
  showDetails = true;
  passwordUpdateNeeded = true;
  viewError: ViewError = ViewError.None;

  // Expose enum to html template
  viewErrorEnum = ViewError;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toastService: ToastService,
    apiClientFactory: ApiClientFactoryService
  ) {
    this.userService = apiClientFactory.getUserService();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.userService.getUserById(params['userId']!).subscribe({
        next: (n: UserViewDto) => {
          this.user = n;
        },
        error: (e: HttpErrorResponse) => {
          if (e.status === HttpStatusCode.NotFound) {
            this.viewError = ViewError.UserNotFound;
          } else {
            this.viewError = ViewError.Unknown;
          }
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('users.errorFetchingUser')
          );
        },
        complete: () => {},
      });
    });
  }

  activateAccount(): void {
    if (this.user) {
      this.userService.activateUser(this.user.id).subscribe({
        next: (_: any) => {
          this.showDetails = false;
          this.passwordUpdateNeeded = UserViewDto.IdentityProviderEnum.LOCAL === this.user!.identityProvider;
        },
        error: (e: any) => {
          let msg = 'activateAccount.error.activateAccount';
          if (e instanceof HttpErrorResponse && e.status === 405) {
            msg = 'activateAccount.error.alreadyActivated';
          }
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant(msg)
          );
        },
        complete: () => {},
      });
    }
  }
}
