<div class="col-md-8 col-sm-10 col-xs-12 col-lg-8 content-container">
  <mat-card>
    <mat-card-title>{{ 'clientsComponent.lancrypt' | translate }}</mat-card-title>
    <mat-card-content>
      <div class="downloadArea">
        <a (click)="downloadFile(clientTypes.LANCRYPTWINDOWS)" href="javascript: void(0)">
          <span class="icon-conpal-cib-windows icon-font"></span>
          {{ 'clientsComponent.downloadText.windows' | translate }}
        </a>
        <div *ngIf="versionInfoForClientType(clientTypes.LANCRYPTWINDOWS) as vi">
          {{ 'clientsComponent.latestVersion' | translate }}: {{ vi.version }}
          <br />
          {{ 'clientsComponent.lastUpdated' | translate }}:
          {{ vi.uploadDate | date: 'shortDate' }}
        </div>
      </div>
      <div class="downloadArea">
        <a (click)="downloadFile(clientTypes.LANCRYPTMACOS)" href="javascript: void(0)">
          <span class="icon-conpal-cib-apple icon-font"></span>
          {{ 'clientsComponent.downloadText.macos' | translate }}
        </a>
        <div *ngIf="versionInfoForClientType(clientTypes.LANCRYPTMACOS) as vi">
          {{ 'clientsComponent.latestVersion' | translate }}: {{ vi.version }}
          <br />
          {{ 'clientsComponent.lastUpdated' | translate }}:
          {{ vi.uploadDate | date: 'shortDate' }}
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>{{ 'clientsComponent.lancrypt2go' | translate }}</mat-card-title>
    <mat-card-content>
      <div class="downloadArea">
        <a (click)="downloadFile(clientTypes.LC2GOWINDOWS)" href="javascript: void(0)">
          <span class="icon-conpal-cib-windows icon-font"></span>
          {{ 'clientsComponent.downloadText.windows' | translate }}
        </a>
        <div *ngIf="versionInfoForClientType(clientTypes.LC2GOWINDOWS) as vi">
          {{ 'clientsComponent.latestVersion' | translate }}: {{ vi.version }}
          <br />
          {{ 'clientsComponent.lastUpdated' | translate }}:
          {{ vi.uploadDate | date: 'shortDate' }}
        </div>
      </div>
      <div class="downloadArea">
        <a (click)="downloadFile(clientTypes.LC2GOMACOS)" href="javascript: void(0)">
          <span class="icon-conpal-cib-apple icon-font"></span>
          {{ 'clientsComponent.downloadText.macos' | translate }}
        </a>
        <div *ngIf="versionInfoForClientType(clientTypes.LC2GOMACOS) as vi">
          {{ 'clientsComponent.latestVersion' | translate }}: {{ vi.version }}
          <br />
          {{ 'clientsComponent.lastUpdated' | translate }}:
          {{ vi.uploadDate | date: 'shortDate' }}
        </div>
      </div>
      <div class="downloadArea">
        <a (click)="downloadFile(clientTypes.LC2GOLINUX)" href="javascript: void(0)">
          <span class="icon-conpal-cib-linux icon-font"></span>
          {{ 'clientsComponent.downloadText.linux' | translate }}
        </a>
        <div *ngIf="versionInfoForClientType(clientTypes.LC2GOLINUX) as vi">
          {{ 'clientsComponent.latestVersion' | translate }}: {{ vi.version }}
          <br />
          {{ 'clientsComponent.lastUpdated' | translate }}:
          {{ vi.uploadDate | date: 'shortDate' }}
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>{{ 'clientsComponent.lancryptMobile' | translate }}</mat-card-title>
    <mat-card-content>
      <a href="{{ 'clientsComponent.downloadLinks.lcmobileIos' | translate }}">
        <img
          cImg
          height="50"
          style="margin: 13px 13px 13px 0"
          ngSrc="assets/img/ios_badge.svg"
          alt="{{ 'clientsComponent.altTexts.ios' | translate }}"
          width="150"
        />
      </a>
      <a href="{{ 'clientsComponent.downloadLinks.lcmobileAndroid' | translate }}">
        <img
          height="50"
          style="margin: 13px"
          cImg
          alt="{{ 'clientsComponent.altTexts.android' | translate }}"
          ngSrc="assets/img/google-play-badge.svg"
          width="169"
        />
      </a>
    </mat-card-content>
  </mat-card>
  <div>
    {{ 'clientsComponent.legal.ios' | translate }}<br />
    {{ 'clientsComponent.legal.android' | translate }}
  </div>
</div>
