import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserMenuComponent} from './user-menu.component';
import {IconModule} from '@coreui/icons-angular';

import {
  AvatarModule,
  ButtonModule,
  DropdownModule,
  HeaderModule,
  NavModule,
  SharedModule,
  UtilitiesModule,
} from '@coreui/angular';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [UserMenuComponent],
  imports: [
    CommonModule,
    IconModule,
    AvatarModule,
    ButtonModule,
    DropdownModule,
    HeaderModule,
    NavModule,
    SharedModule,
    UtilitiesModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [UserMenuComponent],
})
export class UserMenuModule {}
