<div class="create-group-container create-edit-container">

  <mat-card *ngIf="showDetails && viewError == viewErrorEnum.None">
    <mat-card-header>
      <mat-card-title>{{'activateAccount.activateAccount' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'activateAccount.activateAccountSub' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *onlyShowWhenIdle>
      <dl>
        <dt>{{'users.firstName' | translate}}</dt>
        <dd>{{user?.firstName}}</dd>

        <dt>{{'users.lastName' | translate}}</dt>
        <dd>{{user?.lastName}}</dd>

        <dt>{{'users.description' | translate}}</dt>
        <dd>{{user?.description}}</dd>

        <dt>{{'users.emailAddress' | translate}}</dt>
        <dd>{{user?.emailAddress}}</dd>
      </dl>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="lancrypt-primary" (click)="activateAccount()" disableDuringRequest>
        {{'buttons.activate' | translate}}
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>

  <mat-card *ngIf="viewError != viewErrorEnum.None">
    <mat-card-header>
      <mat-card-title>{{'activateAccount.activateAccountError' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [ngSwitch]="viewError">
        <div *ngSwitchCase="viewErrorEnum.Unknown">
          {{'activateAccount.error.unknown' | translate}}
        </div>
        <div *ngSwitchCase="viewErrorEnum.UserNotFound">
          {{'activateAccount.error.userNotFound' | translate}}
        </div>
        <div>
          {{'activateAccount.contactAdministrator' | translate}}
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!showDetails">
    <mat-card-header>
      <mat-card-title>{{'activateAccount.activateAccountSuccess' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      {{passwordUpdateNeeded ? ('activateAccount.activateAccountConfirmation' | translate) : ('activateAccount.activateAccountConfirmationNoPassword' | translate)}}
    </mat-card-content>
  </mat-card>
</div>

