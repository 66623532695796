import {ErrorHandler, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularPlugin} from '@microsoft/applicationinsights-angularplugin-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from 'src/environments/environment';

@Injectable()
export class Insights {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.applicationInsights.instrumentationKey,
      extensions: [<any>this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  constructor(private router: Router) {
    if (environment.applicationInsights.enabled) {
      this.appInsights.loadAppInsights();
    }
  }

  trackEvent(name: string): void {
    this.appInsights.trackEvent({name});
  }

  trackException(error: Error): void {
    this.appInsights.trackException({error});
  }

  trackTrace(message: string): void {
    this.appInsights.trackTrace({message});
  }
}
