import {Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {BusyService} from './busy.service';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[disableDuringRequest]',
})
export class DisableDuringRequestDirective implements OnInit, OnDestroy {
  subscription?: Subscription;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private busyService: BusyService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.checkBusyProgress();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private checkBusyProgress() {
    this.subscription = this.busyService.busy$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(busy => {
      this.el.nativeElement.disabled = busy;
      if (busy) {
        this.el.nativeElement.classList.add('mat-button-disabled');
      } else {
        this.el.nativeElement.classList.remove('mat-button-disabled');
      }
      if (this.el.nativeElement.tagName === 'SPAN') {
        this.renderer.setStyle(this.el.nativeElement, 'display', busy ? 'none' : 'block');
      }
    });
  }
}
