import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  FieldErrorDto,
  IdentityProviderConnectionService,
  IdentityProviderConnectionViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {Observable} from 'rxjs';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {ToastService} from 'src/app/services/toaster.service';
import {FIELD_LENGTH_CONSTRAINTS} from 'src/app/shared/lancrypt.constants';
import {IdentityProviderTranslator} from '../../../../../shared/identity-provider-translator';

@Component({
  selector: 'app-connection-common',
  templateUrl: './connection-common.component.html',
  styleUrls: ['./connection-common.component.scss'],
})
export class ConnectionCommonComponent implements OnInit {
  maxLengthConstraints = {
    connectionClientId: FIELD_LENGTH_CONSTRAINTS.identityProviderConnection.clientId,
    connectionName: FIELD_LENGTH_CONSTRAINTS.identityProviderConnection.name,
    connectionClientSecret: FIELD_LENGTH_CONSTRAINTS.identityProviderConnection.clientSecret,
    connectionAzureTenantId: FIELD_LENGTH_CONSTRAINTS.identityProviderConnection.azureTenantId,
  };

  formGroup;
  showPassword = false;

  editable = true;
  chosenIdentityProvider = IdentityProviderConnectionViewDto.IdentityProviderEnum.LOCAL;
  idpEnum = IdentityProviderConnectionViewDto.IdentityProviderEnum;

  protected updateClientSecret = false;
  protected tenantId?: string;
  protected connectionService: IdentityProviderConnectionService;
  @ViewChild(MatStepper) private stepper!: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    apiClientFactory: ApiClientFactoryService,
    protected jwtHelperService: JwtHelperService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private router: Router
  ) {
    this.connectionService = apiClientFactory.getIdentityProviderConnectionService();

    this.formGroup = this.formBuilder.group({
      connectionName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.connectionName)]],
      connectionAzureTenantId: [
        '',
        [Validators.required, Validators.maxLength(this.maxLengthConstraints.connectionAzureTenantId)],
      ],
      connectionClientId: [
        '',
        [Validators.required, Validators.maxLength(this.maxLengthConstraints.connectionClientId)],
      ],
      connectionClientSecret: ['', [Validators.maxLength(this.maxLengthConstraints.connectionClientSecret)]],
    });

    this.onUpdateClientSecret();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
    });
  }

  cancel() {
    this.router.navigate(['lancrypt', 'connections']);
  }

  onUpdateClientSecret(): void {
    if (this.isClientSecretOptional()) {
      this.formGroup.controls.connectionClientSecret.removeValidators([Validators.required]);
    } else {
      this.formGroup.controls.connectionClientSecret.addValidators([Validators.required]);
    }
    this.formGroup.controls.connectionClientSecret.updateValueAndValidity();
  }

  getIdentityProviderName(idp: any): string {
    return IdentityProviderTranslator.getIdentityProviderName(this.translateService, idp);
  }

  getIdentityProviderHint(idp: any): string {
    return IdentityProviderTranslator.getIdentityProviderHint(this.translateService, idp);
  }

  getClientSecretText(): string {
    return 'identityProviderConnection.azureActiveDirectory.secret';
  }

  createOrUpdateConnection(): void {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const dto = this.buildConnectionDto();

    this.callService(this.tenantId!, dto).subscribe({
      next: (n: any) => {
        if (n) {
          this.toastService.showSuccess(
            this.translateService.instant('common.success'),
            this.translateService.instant(this.getSuccessMessage())
          );
          this.router.navigate(['/lancrypt/connections']);
        } else {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('identityProviderConnection.error.validationError')
          );
        }
      },
      error: async e => {
        const errors = e.error?.errors as FieldErrorDto[];

        if (!errors || errors.length === 0) {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant(this.getErrorMessage())
          );
          return;
        }

        const error = errors[0];
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant(`identityProviderConnection.error.${error.field}`)
        );
      },
      complete: () => {},
    });
  }

  protected buildConnectionDto(): any {
    throw new Error('Method not implemented.');
  }

  public getTitle(): string {
    return '';
  }

  public getSubTitle(): string {
    return '';
  }

  public getErrorMessage(): string {
    return '';
  }

  public getSuccessMessage(): string {
    return '';
  }

  public getStepTitle(): string {
    return '';
  }

  public getNextTitle(): string {
    return '';
  }

  protected isClientSecretOptional(): boolean {
    return false;
  }

  protected callService(_tenantId: string, _dto: any): Observable<boolean> {
    return new Observable<boolean>();
  }
}
