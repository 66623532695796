<div class="lancrypt-auditlogs-container">
  <div class="lancrypt-auditlogs-header">
    <div class="headline-container">
      <h1>{{ 'headlines.auditLog' | translate }}</h1>
    </div>
  </div>

  <mat-divider class="headline-divider"></mat-divider>
  <mat-card>
    <mat-card-header class="header">
      <form [formGroup]="filterFormGroup" class="dense-form-field-2">
        <c-row>
          <c-col class="col-lg-4">
            <mat-form-field>
              <mat-label>{{ 'auditlogs.enterDateRange' | translate }}</mat-label>
              <mat-date-range-input [formGroup]="filterFormGroup" [rangePicker]="picker">
                <input matStartDate formControlName="dateFrom" (dateChange)="search()"
                       placeholder="{{'auditlogs.startDate' | translate}}">
                <input matEndDate formControlName="dateUntil" (dateChange)="search()"
                       placeholder="{{'auditlogs.endDate' | translate}}">
              </mat-date-range-input>

              <mat-hint>{{ translationHelperService.getRegionalDateFormat() }}
                – {{ translationHelperService.getRegionalDateFormat() }}
              </mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker (closed)="search()"></mat-date-range-picker>

              <mat-error *ngIf="filterFormGroup.controls['dateFrom'].hasError('matStartDateInvalid')">
                {{ 'auditlogs.invalidStartDate' | translate }}
              </mat-error>
              <mat-error *ngIf="filterFormGroup.controls['dateUntil'].hasError('matEndDateInvalid')">
                {{ 'auditlogs.invalidEndDate' | translate }}
              </mat-error>
            </mat-form-field>
          </c-col>
          <c-col class="col-lg-3">
            <mat-form-field>
              <mat-label>{{ 'auditlogs.type' | translate }}</mat-label>
              <mat-select formControlName="type" value="" (selectionChange)="search()">
                <mat-option value="">{{ 'auditlogs.allTypes' | translate }}</mat-option>
                <mat-option *ngFor="let typeEntry of translatedTypes"
                            value={{typeEntry.type}}>{{ typeEntry.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </c-col>
          <c-col class="col-lg-4">
            <mat-form-field>
              <mat-label>{{ 'auditlogs.user' | translate }}</mat-label>
              <mat-select ngxLazyMatSelect (infiniteScroll)="loadNextUserBatch()" formControlName="userId"
                          (selectionChange)="search()" [complete]="userPageCounter === userPageCount">
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl"
                                         placeholderLabel="{{ 'common.search' | translate }}"
                                         noEntriesFoundLabel="{{ 'users.noUserFound' | translate}}"/>
                </mat-option>
                <mat-option value="">{{ 'auditlogs.allUsers' | translate }}</mat-option>
                <mat-optgroup label="{{'auditlogs.administrators' | translate}}">
                  <mat-option *ngFor="let administrator of administrators"
                              value={{administrator.id}}>{{ administrator.displayName }}
                  </mat-option>
                </mat-optgroup>
                <mat-optgroup label="{{'auditlogs.users' | translate}}">
                  <mat-option *ngFor="let user of userObservable | async"
                              value={{user.id}}>{{ user.displayName }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </c-col>
          <c-col class="col-lg-1 ">
            <div class="button-container">
              <div class="control-elements">
                <button matTooltip="{{ 'buttons.clear_filters' | translate }}" mat-mini-fab color="lancrypt-primary"
                        type="button" (click)="clearFilter()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </c-col>
        </c-row>
      </form>
    </mat-card-header>

    <mat-card-content>

      <table mat-table matSort [dataSource]="dataSource">
        <!-- Date Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'auditlogs.columns.date' | translate }}</th>
          <td mat-cell *matCellDef="let auditlog">{{ auditlog.created | date: 'short' }}</td>
        </ng-container>

        <!-- Type Column-->
        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'auditlogs.columns.type' | translate }}</th>
          <td mat-cell *matCellDef="let auditlog">{{ getTypeName(auditlog.type) }}</td>
        </ng-container>

        <!-- User Column-->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>{{ 'auditlogs.columns.user' | translate }}</th>
          <td mat-cell
              *matCellDef="let auditlog">{{ getUserNameFromUserId(auditlog.userId) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="userId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'auditlogs.columns.userId' | translate }}</th>
          <td mat-cell *matCellDef="let auditlog">{{ auditlog.userId | notAvailable }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="openDialog(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell mat-cell-empty" [attr.colspan]="columnsToDisplay.length">
            <div *onlyShowWhenIdle>
              {{ 'auditlogs.noauditlogsFound' | translate }}
            </div>
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalRows"
                     [pageIndex]="currentPage" (page)="pageChanged($event)"
                     showFirstLastButtons></mat-paginator>

    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>


