import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../services/helper/translation-helper.service';
import {UserDataService} from '../../services/user-data.service';

@Component({
  selector: 'app-dashboard-account',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss'],
})
export class AccountLayoutComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private translationHelperService: TranslationHelperService,
    private dateAdapter: DateAdapter<any>,
    private userDataService: UserDataService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(_ => {
      const userData = this.userDataService.userData.value;
      this.translate.use(userData.languageInfo);
      this.translationHelperService.setRegionalLocale(userData.regionalFormats);
      this.dateAdapter.setLocale(userData.regionalFormats);
    });
  }
}
