import {TranslateService} from '@ngx-translate/core';

export class IdentityProviderTranslator {
  static getIdentityProviderName(translateService: TranslateService, idp: any): string {
    if (typeof idp === 'string') {
      return translateService.instant('users.identityProviders.' + idp);
    }
    return '';
  }

  static getIdentityProviderHint(translateService: TranslateService, idp: any): string {
    if (typeof idp === 'string') {
      return translateService.instant('users.identityProviders.' + idp + 'Hint');
    } else {
      return translateService.instant('users.identityProviders.emptyHint');
    }
  }
}
