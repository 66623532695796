import {AfterViewInit, Directive, Input} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Directive({
  selector: 'mat-paginator',
  standalone: true,
})
export class StorePaginationPageSizeDirective implements AfterViewInit {
  @Input('paginationStoreTag') elementTag = '';
  private element: MatPaginator;

  get key(): string {
    if (this.elementTag) {
      return `${window.location.pathname}_${this.elementTag}_page_size`;
    }
    return `${window.location.pathname}_page_size`;
  }

  get pageSize() {
    const stored = localStorage.getItem(this.key);
    if (stored) {
      return parseInt(stored);
    }
    return 10;
  }

  set pageSize(size: number) {
    localStorage.setItem(this.key, size.toString());
  }

  constructor(private el: MatPaginator) {
    this.element = el;
  }

  ngAfterViewInit(): void {
    this.element.pageSize = this.pageSize;
    this.element.page.subscribe((page: PageEvent) => {
      this.pageSize = page.pageSize;
    });

    // Emit a `page` event so the handlers are called.
    // This refreshes data with the new page size in cases where the page size is not read from the paginator on creation.
    this.element.page.emit({
      length: this.element.length,
      pageIndex: this.element.pageIndex,
      pageSize: this.element.pageSize,
    } as PageEvent);
  }
}
