<div id="identities-container">
  <div class="headline-container">
    <h1>{{'headlines.connectionImport' | translate}}</h1>
  </div>

  <mat-divider class="headline-divider"></mat-divider>
  <div class="tree-container">
    <div class="col-lg-6">
      <mat-card class="tree local-tree">
        <mat-card-content>

          <div class="tree-view-header">
            <div class="tree-side-view-header">
              <h2>{{ 'connectionImport.localView' | translate }}</h2>
              <p class="subheadline">{{ 'connectionImport.localViewSubheadline' | translate }} </p>
            </div>
          </div>

          <mat-tree [dataSource]="localViewDataSource" [treeControl]="localTreeControl" [class]="'tenant-tree'">

            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node [ngClass]="{'users-node': node.subType == subtypes.Users}"
                           *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
              <!-- use a disabled button to provide padding for tree leaf -->
              <mat-icon class="subnode-icon">{{node.icon}}</mat-icon>
              <span>{{node.name}}</span>

            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
              <button mat-icon-button matTreeNodeToggle class="expand-button" [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{localTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
                <mat-icon>{{node.icon}}</mat-icon>
              </button>
              <span>{{node.name}}</span>
            </mat-tree-node>
          </mat-tree>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-6">
      <mat-card class="tree">
        <mat-card-content>

          <div class="tree-view-header">
            <div class="tree-side-view-header">
              <h2>{{ 'connectionImport.remoteView' | translate }}</h2>
              <p *ngIf="!isSyncInProgress" class="subheadline">{{ 'connectionImport.remoteViewSubheadline' | translate }} </p>
              <mat-error *ngIf="isSyncInProgress">{{ 'connectionImport.error.syncInProgress' | translate }} </mat-error>
            </div>
            <div class="control-elements">
              <button matTooltip="{{ 'connectionImport.startImport' | translate }}" mat-mini-fab color="lancrypt-primary" (click)="importConnection()" [disabled]="!allowImport()">
                <mat-icon>play_circle</mat-icon>
              </button>
            </div>
          </div>

          <div *ngIf="hasLoadingError" class="tree-view-error">{{loadingErrorMessage}}</div>

          <mat-form-field *ngIf="!hasLoadingError" class="import-filter">
            <mat-label>{{ 'connectionImport.filter' | translate }}</mat-label>
            <input matInput maxlength="100" (keyup)="applyFilter($event)" placeholder="{{ 'connectionImport.filter' | translate }}" />
          </mat-form-field>

          <mat-tree *ngIf="!hasLoadingError" [dataSource]="connectionDataSource" [treeControl]="connectionTreeControl" [class]="'tenant-tree remote-tree'"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100"
            [fromRoot]="true"
            [infiniteScrollContainer]="'.remote-tree'"
            (scrolled)="loadMoreUsers()">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node class="leaf-node" *matTreeNodeDef="let node" matTreeNodePadding
                           matTreeNodePaddingIndent="20">
              <mat-checkbox class="subnode-checkbox"
                            (change)="checklistSelection.toggle([node.subType, node.id])"
                            [checked]="checklistSelection.isSelected([node.subType, node.id])"
                            [disabled]="isSyncInProgress"></mat-checkbox>
              <mat-icon class="checkbox-icon">{{node.icon}}</mat-icon>
              <span class="node-label">{{node.name}}</span>
              <mat-progress-bar class="node-progress" mode="indeterminate" *ngIf="node.subType === subtypes.Users && node.level === 1 && isLoadingUsers"></mat-progress-bar>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{connectionTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-checkbox (change)="onCheck($event, node)"
                            [checked]="descendantsAllSelected(node)"
                            [indeterminate]="descendantsPartiallySelected(node)"
                            [disabled]="isSyncInProgress"></mat-checkbox>
              <mat-icon class="checkbox-icon">{{node.icon}}</mat-icon>
              <span class="node-label">{{node.name}}</span>
            </mat-tree-node>
          </mat-tree>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
</div>
