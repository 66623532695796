<div class="administrator-common-container create-edit-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ getTitle() }}</mat-card-title>
        <mat-card-subtitle>{{ getSubTitle() }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="formGroup" class="form center dense-form-field-2">
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>{{ 'administrators.firstName' | translate }}</mat-label>
              <input matInput formControlName="firstName" required>
              <mat-error *ngIf="formGroup.hasError('required','firstName')">
                {{ 'administrators.error.firstNameRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','firstName')">
                {{ 'administrators.error.firstNameMaxLength' | translate: {maxLength: maxLengthConstraints.firstName} }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>{{ 'administrators.lastName' | translate }}</mat-label>
              <input matInput formControlName="lastName" required>
              <mat-error *ngIf="formGroup.hasError('required','lastName')">
                {{ 'administrators.error.lastNameRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','lastName')">
                {{ 'administrators.error.lastNameMaxLength' | translate: {maxLength: maxLengthConstraints.lastName} }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element form-element-with-hint">
              <mat-label>{{ 'administrators.emailAddress' | translate }}</mat-label>
              <input matInput formControlName="emailAddress" required (input)="onEmailAddressChange($event)">
              <mat-hint>{{ getEmailHint() }}</mat-hint>
              <mat-error *ngIf="formGroup.hasError('notValid', 'emailAddress')">
                {{ 'administrators.error.emailAddressInvalid' | translate }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('required','emailAddress')">
                {{ 'administrators.error.emailAddressRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','emailAddress')">
                {{ 'administrators.error.emailAddressMaxLength' | translate: {maxLength: maxLengthConstraints.emailAddress} }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('email','emailAddress')">
                {{ 'administrators.error.emailAddressInvalid' | translate }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('emailInUse','emailAddress')">
                {{ 'administrators.error.emailInUse' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>{{ 'administrators.mobilePhone' | translate }}</mat-label>
              <input matInput formControlName="mobilePhoneNumber" required>
              <mat-error *ngIf="formGroup.hasError('required','mobilePhoneNumber')">
                {{ 'administrators.error.mobilePhoneRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','mobilePhoneNumber')">
                {{ 'administrators.error.mobilePhoneMaxLength' | translate: {maxLength: maxLengthConstraints.mobilePhoneNumber} }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="form-element">
              <mat-label>{{ 'administrators.businessPhone' | translate }}</mat-label>
              <input matInput formControlName="businessPhoneNumber">
              <mat-error *ngIf="formGroup.hasError('maxlength','businessPhoneNumber')">
                {{ 'administrators.error.businessPhoneMaxLength' | translate: {maxLength: maxLengthConstraints.businessPhoneNumber} }}
              </mat-error>
            </mat-form-field>
          </form>
      </mat-card-content>
      <mat-card-actions class="control-elements">
        <button mat-raised-button color="lancrypt-primary" (click)="createOrUpdateAdministrator()" disableDuringRequest>
          {{ 'buttons.save' | translate }}
        </button>
        <button mat-raised-button class="" (click)="cancel()" disableDuringRequest>
          {{ 'buttons.cancel' | translate }}
        </button>
        <button *ngIf="inEditMode && canDelete" mat-raised-button color="warn"
                class="delete-button" (click)="deleteAdministrator()" disableDuringRequest>
          <mat-icon>delete</mat-icon>
          {{ 'administrators.deleteAdministrator' | translate }}
        </button>
      </mat-card-actions>
  
      <mat-card-footer>
        <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
  