<mat-expansion-panel class="mat-elevation-z1" [(expanded)]="expanded">
  <mat-expansion-panel-header class="title-card-header">
    <mat-panel-title class="settings-header">
      <h2>{{'settings.fileEncryption.title' | translate}}</h2>
    </mat-panel-title>
    <mat-action-row (click)=$event.stopPropagation()>
      <button mat-button class="edit-buton" (click)="onEdit()" color="lancrypt-primary-light" [disabled]="!settingsViewDto" [hidden]="editMode">{{'buttons.edit' | translate}}</button>
      <button mat-button class ="save-button" (click)="onSave()" color="lancrypt-primary-light" [hidden]="!editMode">{{'buttons.save' | translate}}</button>
      <button mat-button class ="cancel-button" (click)="onCancel()" [hidden]="!editMode">{{'buttons.cancel' | translate}}</button>
    </mat-action-row>
  </mat-expansion-panel-header>
  <mat-card style="box-shadow: none">
    <mat-card-content>
      <form [formGroup]="formGroup">
        <div class="settings-item">
          <span class="settings-title">
            <mat-label class="settings-name">{{'settings.fileEncryption.allowDecryption.title' | translate}}</mat-label>
            <mat-icon class="settings-info-icon" inline="true" matTooltip="{{'settings.fileEncryption.allowDecryption.hint' | translate}}">info</mat-icon>
          </span>
          <span class="settings-value">
            <mat-slide-toggle [formControl]="formGroup.controls.allowDecryption" class="settings-field"></mat-slide-toggle>
          </span>
        </div>
        <div class="settings-item">
          <span class="settings-title">
            <mat-label class="settings-name">{{'settings.fileEncryption.enablePersistentEncryption.title' | translate}}</mat-label>
            <mat-icon class="settings-info-icon" inline="true" matTooltip="{{'settings.fileEncryption.enablePersistentEncryption.hint' | translate}}">info</mat-icon>
          </span>
          <span class="settings-value">
            <mat-slide-toggle [formControl]="formGroup.controls.enablePersistentEncryption" class="settings-field"></mat-slide-toggle>
          </span>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</mat-expansion-panel>

