<div class="create-connection-container create-edit-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{getTitle() | translate}}</mat-card-title>
      <mat-card-subtitle>
        {{getSubTitle() | translate}}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-stepper [linear]="true" orientation="horizontal" #stepper id="identity-provider-connection-stepper">
        <mat-step [stepControl]="formGroup" [editable]="editable" label="{{getStepTitle() | translate}}">
          <div class="info-header">
            <h2>{{'identityProviderConnection.azureActiveDirectory.title' | translate}}</h2>
            <mat-card-subtitle>
              {{'identityProviderConnection.azureActiveDirectory.description' | translate}}
            </mat-card-subtitle>
          </div>

          <form [formGroup]="formGroup" class="form center dense-form-field-2">
            <mat-form-field class="form-element" appearance="fill">
              <mat-label>{{'identityProviderConnection.name' | translate}}</mat-label>
              <input matInput formControlName="connectionName">
              <mat-error *ngIf="formGroup.hasError('required','connectionName')">
                {{'identityProviderConnection.error.nameRequired' | translate}}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','connectionName')">
                {{'identityProviderConnection.error.nameMaxLength' | translate: {
                maxLength:
                maxLengthConstraints.connectionName
              } }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="form-element" appearance="fill">
              <mat-label>{{'identityProviderConnection.azureActiveDirectory.azureTenantId' | translate}}</mat-label>
              <input matInput formControlName="connectionAzureTenantId">
              <mat-error *ngIf="formGroup.hasError('required','connectionAzureTenantId')">
                {{'identityProviderConnection.error.azureTenantIdRequired' | translate}}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','connectionAzureTenantId')">
                {{'identityProviderConnection.error.azureTenantIdMaxLength' | translate: {
                maxLength:
                maxLengthConstraints.connectionAzureTenantId
              } }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="form-element" appearance="fill">
              <mat-label>{{'identityProviderConnection.azureActiveDirectory.clientId' | translate}}</mat-label>
              <input matInput formControlName="connectionClientId">
              <mat-error *ngIf="formGroup.hasError('required','connectionClientId')">
                {{'identityProviderConnection.error.clientIdRequired' | translate}}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','connectionClientId')">
                {{'identityProviderConnection.error.clientIdMaxLength' | translate: {
                maxLength:
                maxLengthConstraints.connectionClientId
              } }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="form-element" appearance="fill">
              <mat-label>{{getClientSecretText() | translate}}</mat-label>
              <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="connectionClientSecret">
              <mat-icon matSuffix (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' :
                'visibility'}}</mat-icon>
              <mat-error *ngIf="formGroup.hasError('required','connectionClientSecret')">
                {{'identityProviderConnection.error.clientSecretRequired' | translate}}
              </mat-error>
              <mat-error *ngIf="formGroup.hasError('maxlength','connectionClientSecret')">
                {{'identityProviderConnection.error.clientSecretMaxLength' | translate: {
                maxLength:
                maxLengthConstraints.connectionClientSecret
              } }}
              </mat-error>
            </mat-form-field>
          </form>
          <div class="form-element next-container-right">
            <button mat-button matStepperNext class="next-button"
                    disableDuringRequest>{{'buttons.next' | translate}}</button>
            <button mat-raised-button class="cancel-button" (click)="cancel()"
                    disableDuringRequest>{{ 'buttons.cancel' | translate }}</button>
          </div>

        </mat-step>

        <mat-step [editable]="editable"
                  label="{{'users.identityProviders.signIn' | translate}}">
          <div class="info-header">
            <h2>{{'users.identityProviders.signinMethod' | translate}}</h2>
            <mat-card-subtitle>
              {{'users.identityProviders.emptyHint' | translate}}
            </mat-card-subtitle>
          </div>
          <mat-radio-group class="radio-group" [(ngModel)]="chosenIdentityProvider">
            <div *ngFor="let idp of idpEnum | keyvalue"
                 class="radio-div">
              <mat-radio-button [value]="idp.key">{{getIdentityProviderName(idp.key)}}</mat-radio-button>
              <div>
                <mat-card-subtitle class="radio-subtext">
                  {{getIdentityProviderHint(idp.key)}}
                </mat-card-subtitle>
              </div>
            </div>

          </mat-radio-group>
          <div class="form-element next-container">
            <button mat-raised-button mat-button matStepperPrevious>{{'buttons.back' | translate}}</button>
            <div>
              <button mat-button matStepperNext class="next-button"
                      disableDuringRequest>{{'buttons.next' | translate}}</button>
              <button mat-raised-button class="cancel-button" (click)="cancel()"
                      disableDuringRequest>{{ 'buttons.cancel'
                | translate }}</button>
            </div>
          </div>
        </mat-step>

        <mat-step [editable]="editable" label="{{'identityProviderConnection.stepTitle.summary' | translate}}">
          <div class="info-header">
            <h2>{{'identityProviderConnection.stepTitle.summary' | translate}}</h2>
          </div>
          <h3>{{'identityProviderConnection.details' | translate}}</h3>
          <c-container class="connection-details">
            <c-row>
              <c-col class="col-lg-4"><label>{{'identityProviderConnection.name' | translate}}</label></c-col>
              <c-col class="col-lg-8"><span>{{formGroup.controls.connectionName.value}}</span></c-col>
            </c-row>
            <c-row>
              <c-col class="col-lg-4"><label>{{'identityProviderConnection.azureActiveDirectory.azureTenantId' |
                translate}}</label></c-col>
              <c-col class="col-lg-8"><span>{{formGroup.controls.connectionAzureTenantId.value}}</span></c-col>
            </c-row>
            <c-row>
              <c-col class="col-lg-4">
                <label>{{'identityProviderConnection.azureActiveDirectory.clientId' | translate}}</label></c-col>
              <c-col class="col-lg-8"><span>{{formGroup.controls.connectionClientId.value}}</span></c-col>
            </c-row>
            <c-row>
              <c-col class="col-lg-4">
                <label>{{'users.identityProviders.signinMethod' | translate}}</label></c-col>
              <c-col class="col-lg-8"><span>{{ getIdentityProviderName(chosenIdentityProvider)}}</span></c-col>
            </c-row>
          </c-container>
          <div class="form-element next-container">
            <button mat-raised-button mat-button matStepperPrevious>{{'buttons.back' | translate}}</button>
            <div>
              <button mat-raised-button class="next-button" (click)="createOrUpdateConnection()"
                      disableDuringRequest>{{getNextTitle() | translate}}</button>
              <button mat-raised-button class="cancel-button" (click)="cancel()"
                      disableDuringRequest>{{ 'buttons.cancel'| translate }}</button>
            </div>
          </div>
        </mat-step>

      </mat-stepper>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
