import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-account-home',
  templateUrl: './account-home.component.html',
  styleUrls: ['./account-home.component.scss'],
})
export class AccountHomeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
