import {Component} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {GroupCreateDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GroupCommonComponent} from '../group-common/group-common.component';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {SubFolderType} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';

@Component({
  selector: 'app-group-create',
  templateUrl: '../group-common/group-common.component.html',
  styleUrls: ['../group-common/group-common.component.scss'],
})
export class GroupCreateComponent extends GroupCommonComponent {
  private parentGroupId?: string;
  private groupId?: string;
  private parentGroupName?: string;

  constructor(
    private route: ActivatedRoute,
    formBuilder: FormBuilder,
    toastService: ToastService,
    jwtHelperService: JwtHelperService,
    router: Router,
    translateService: TranslateService,
    apiClientFactory: ApiClientFactoryService,
    dialog: MatDialog
  ) {
    super(formBuilder, toastService, jwtHelperService, router, translateService, apiClientFactory, dialog);
  }

  override ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.parentGroupId = params['parentGroupId'];
      this.parentGroupName = params['parentGroupName'];
      super.ngOnInit();
    });
  }

  protected override buildGroupDto(): GroupCreateDto {
    return {
      name: this.formGroup.controls.name.value === null ? '' : this.formGroup.controls.name.value,
      description: this.formGroup.controls.description.value === null ? '' : this.formGroup.controls.description.value,
      parents: this.assignedGroups,
    };
  }

  protected override callService(tenantId: string, dto: GroupCreateDto): Observable<any> {
    return this.groupService.createGroup(dto, tenantId);
  }

  protected override handleResponse(n: any) {
    this.groupId = n.id;
  }

  protected override getParentGroupId(): string | undefined {
    return this.parentGroupId;
  }

  protected override getParentGroupName(): string | undefined {
    return this.parentGroupName;
  }

  override getTitle(): string {
    return this.translateService.instant('headlines.newGroup');
  }

  override getErrorMessage(): string {
    return this.translateService.instant('groups.errorCreatingGroup');
  }

  override getSuccessMessage(): string {
    return this.translateService.instant('groups.groupCreated');
  }

  override navigateOnCompleted(): void {
    this.router.navigate(['/lancrypt/identities/group/' + this.groupId! + '/' + SubFolderType.Group]);
  }

  override cancel() {
    this.router.navigate(['/lancrypt/identities/group/' + this.parentGroupId + '/' + SubFolderType.Group]);
  }
}
