<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">

    <c-row class="headline-row">
      <c-col xs="5" sm="3" lg="3" xl="2" (click)="home()">
        <c-header-brand>
          <img cImg height="30" src="assets/company-logo.svg" alt="Utimaco"/>
        </c-header-brand>
      </c-col>
      <c-col xs="4" sm="7" lg="7" xl="8">
      </c-col>
      <c-col xs="3" sm="2" lg="2" xl="2">
      </c-col>
    </c-row>
  </c-container>
</ng-container>
<!--</c-header>-->
