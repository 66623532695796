import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {firstValueFrom} from 'rxjs';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  private email: string | undefined;
  private userService: UserService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.userService = apiClientFactory.getUserService();
  }

  ngOnInit(): void {
    firstValueFrom(this.route.params).then(params => {
      this.email = params['email'];
    });
  }

  login() {
    return this.router.navigate(['/']);
  }

  resendVerification() {
    if (this.email) {
      this.userService.resendVerification(this.email).subscribe({
        next: () => {},
        error: () => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('createAccount.errors.sendingVerifyEmail')
          );
        },
        complete: () => {
          this.toastService.showSuccess(
            this.translateService.instant('common.success'),
            this.translateService.instant('createAccount.verifyEmailSent')
          );
        },
      });
    }
  }
}
