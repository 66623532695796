<div class="user-common-container create-edit-container">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ getTitle() }}</mat-card-title>
      <mat-card-subtitle>
        {{ getSubTitle() }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formGroup" class="form center dense-form-field-2">
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'users.firstName' | translate }}</mat-label>
          <input matInput formControlName="firstName" required>
          <mat-error *ngIf="formGroup.hasError('required','firstName')">
            {{ 'users.errorFirstNameRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('maxlength','firstName')">
            {{ 'users.errorFirstNameMaxLength' | translate: {maxLength: maxLengthConstraints.firstName} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'users.lastName' | translate }}</mat-label>
          <input matInput formControlName="lastName" required>
          <mat-error *ngIf="formGroup.hasError('required','lastName')">
            {{ 'users.errorLastNameRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('maxlength','lastName')">
            {{ 'users.errorLastNameMaxLength' | translate: {maxLength: maxLengthConstraints.lastName} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'users.description' | translate }}</mat-label>
          <input matInput formControlName="description">
          <mat-error *ngIf="formGroup.hasError('maxlength','description')">
            {{ 'users.errorDescriptionMaxLength' | translate: {maxLength: maxLengthConstraints.description} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'users.emailAddress' | translate }}</mat-label>
          <input matInput formControlName="emailAddress" required (input)="onEmailAddressChange($event)">
          <mat-hint>{{ getEmailHint() }}</mat-hint>
          <mat-error *ngIf="formGroup.hasError('notValid', 'emailAddress')">
            {{ 'users.errorEmailAddressInvalid' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('required','emailAddress')">
            {{ 'users.errorEmailAddressRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('maxlength','emailAddress')">
            {{ 'users.errorEmailAddressMaxLength' | translate: {maxLength: maxLengthConstraints.emailAddress} }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('email','emailAddress')">
            {{ 'users.errorEmailAddressInvalid' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="formGroup.hasError('emailInUse','emailAddress')">
          {{ 'createAccount.errors.emailInUse' | translate }}
        </mat-error>
        <div class="group-multiselect">
          <mat-form-field floatLabel="always">
            <mat-label>{{ 'users.groups' | translate }}</mat-label>

            <mat-select ngxLazyMatSelect (infiniteScroll)="loadNextGroupBatch()"
                        formControlName="groupIds"
                        placeholder="{{getAssignedGroupCountString()}}"
                        [complete]="groupPageCounter === groupPageCount"
                        [multiple]="true">
              <mat-select-trigger>
                {{ getAssignedGroupCountString() }}
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [formControl]="groupFilterCtrl"
                                       placeholderLabel="{{ 'common.search' | translate }}"
                                       noEntriesFoundLabel="{{ 'groups.noGroupFound' | translate}}"/>
              </mat-option>
              <mat-option (onSelectionChange)="changeGroupMultiselect($event)"
                          *ngFor="let group of groupObservable | async"
                          value={{group.id}}>{{ group.name }}
              </mat-option>
            </mat-select>
            <mat-hint>{{ 'users.groupsHint' | translate }}</mat-hint>
          </mat-form-field>
        </div>
        <mat-form-field appearance="fill" class="form-element select-idp">
          <mat-label>{{ 'users.identityProviders.signinMethod' | translate }}</mat-label>
          <mat-select (selectionChange)="updateIdentityProviderHint($event.value)" formControlName="identityProvider">
            <mat-option *ngFor="let idp of idpEnum | keyvalue"
                        value="{{idp.key}}">{{ getIdentityProviderName(idp.key) }}
            </mat-option>
          </mat-select>
          <mat-hint>{{ identityProviderHint }}</mat-hint>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions class="control-elements">
      <button mat-raised-button color="lancrypt-primary" (click)="createOrUpdateUser()" disableDuringRequest>
        {{ 'buttons.save' | translate }}
      </button>
      <button mat-raised-button class="" (click)="cancel()" disableDuringRequest>
        {{ 'buttons.cancel' | translate }}
      </button>
      <button *ngIf="inEditMode && canDelete" mat-raised-button color="warn"
              class="delete-button" (click)="deleteUser()" disableDuringRequest>
        <mat-icon>delete</mat-icon>
        {{ 'users.deleteUser' | translate }}
      </button>
    </mat-card-actions>

    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
