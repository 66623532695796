import {Injectable, inject} from '@angular/core';
import {ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {TenantViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from './apiclient-factory.service';
import {JwtHelperService} from './helper/jwt-helper.service';
import {TenantData} from '../dtos/lancrypt/tenant-data';
import {LogoutService} from './auth/logout.service';

@Injectable({
  providedIn: 'root',
})
export class TenantDataService {
  tenantData: BehaviorSubject<TenantData>;

  constructor() {
    this.tenantData = new BehaviorSubject<TenantData>({
      id: '',
      companyName: '',
      contractNumber: undefined,
      partnerName: undefined,
      purchasedDate: new Date(),
      purchasedNumber: 0,
      subscriptionPeriod: 0,
      vatNr: '',
      licenseType: TenantViewDto.LicenseTypeEnum.TRIAL,
      notForResale: false,
    });
  }
}

export const tenantDataResolver: ResolveFn<TenantData> = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const jwtHelperService = inject(JwtHelperService);
  const tenantService = inject(ApiClientFactoryService).getTenantService();
  const tenantDataService = inject(TenantDataService);
  const logoutService = inject(LogoutService);

  try {
    const tenantId = await jwtHelperService.getTenantIdFromToken();
    const tenantViewDto = (await firstValueFrom(tenantService.getTenantById(tenantId))) as TenantViewDto;
    const tenantData: TenantData = {
      id: tenantViewDto.id,
      companyName: tenantViewDto.companyName,
      contractNumber: tenantViewDto.contractNumber,
      partnerName: tenantViewDto.partnerName,
      purchasedDate: tenantViewDto.purchasedDate,
      purchasedNumber: tenantViewDto.purchasedNumber,
      subscriptionPeriod: tenantViewDto.subscriptionPeriod,
      vatNr: tenantViewDto.vatNr,
      licenseType: tenantViewDto.licenseType,
      notForResale: tenantViewDto.notForResale,
    };
    tenantDataService.tenantData.next(tenantData);
    return tenantData;
  } catch {
    logoutService.logout();
    return {} as TenantData;
  }
};

export {TenantData} from '../dtos/lancrypt/tenant-data';
