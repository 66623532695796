import {Component} from '@angular/core';
import {GroupDto, GroupTableCommonComponent} from '../group-table-common/group-table-common.component';
import {MatDialog} from '@angular/material/dialog';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-group-table-details',
  templateUrl: '../group-table-common/group-table-common.component.html',
  styleUrls: ['../group-table-common/group-table-common.component.scss'],
})
export class GroupTableDetailsComponent extends GroupTableCommonComponent {
  constructor(
    dialog: MatDialog,
    apiClientFactory: ApiClientFactoryService,
    jwtHelperService: JwtHelperService,
    toastService: ToastService,
    translateService: TranslateService
  ) {
    super(dialog, apiClientFactory, jwtHelperService, toastService, translateService);
  }

  override async ngOnInit() {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
    });
  }

  loadGroups(groups: GroupDto[]) {
    this.dataSource.data = this.mapGroupElements(groups);
  }
}
