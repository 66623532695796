<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent account-layout">
  <!--app-header-->
  <app-account-header class="d-print-none header header-sticky header-color" position="sticky" sidebarId="sidebar">
  </app-account-header>
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto w-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-account-footer></app-account-footer>
</div>
