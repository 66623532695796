import {Component, OnInit} from '@angular/core';
import {AssetElement, AssetsTableCommonComponent} from '../assets-table-common/assets-table-common.component';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';
import {MatDialog} from '@angular/material/dialog';
import {AssetViewDto, UserDetailsWithAssetsViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {DualOptionDialog} from 'src/app/shared/components/dual-option-dialog/dual-option-dialog.component';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-assets-table-details',
  templateUrl: '../assets-table-common/assets-table-common.component.html',
  styleUrls: ['../assets-table-common/assets-table-common.component.scss'],
})
export class AssetsTableDetailsComponent extends AssetsTableCommonComponent implements OnInit {
  userId?: string;
  tenantId?: string;

  constructor(
    route: ActivatedRoute,
    _jwtHelper: JwtHelperService,
    _apiClientFactory: ApiClientFactoryService,
    translationService: TranslateService,
    toastService: ToastService,
    dialog: MatDialog
  ) {
    super(route, _jwtHelper, _apiClientFactory, translationService, toastService, dialog);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.userId = params['userId'];
    });
    this._jwtHelper.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
    });
  }

  override canRemoveAssignment(element: AssetElement): boolean {
    return element.access === null;
  }

  loadAssets(assets: AssetViewDto[]) {
    this.dataSource.data = this.mapAssetElements(assets);
  }

  reloadAssets() {
    this.assetService.getUserDetailsWithAssets(this.tenantId!, this.userId!).subscribe({
      next: async (n: UserDetailsWithAssetsViewDto) => {
        this.loadAssets(n.assets);
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('users.errorFetchingUser')
        );
      },
      complete: () => {},
    });
  }

  override removeAssignment(element: AssetElement) {
    const dialogRef = this.dialog.open(DualOptionDialog, {
      width: '500px',
      data: {
        title: this.translationService.instant('assetDetailsView.removeAssignment'),
        description: this.translationService.instant('assetDetailsView.removeAssignmentEnquiry'),
        positiveTitle: this.translationService.instant('common.confirm'),
        negativeTitle: this.translationService.instant('common.cancel'),
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (!result) {
          return;
        }

        this.assetService.deleteAssetAssignmentFromUser(this.tenantId!, element.id, this.userId!).subscribe({
          next: async (_: any) => {
            this.toastService.showSuccess(
              this.translationService.instant('common.success'),
              this.translationService.instant('assetDetailsView.deletedAssetAssignmentFromUser')
            );
            this.reloadAssets();
          },
          error: async (_: any) => {
            this.toastService.showError(
              this.translationService.instant('common.error'),
              this.translationService.instant('errors.deleteAssetAssignmentFromUser')
            );
          },
        });
      },
    });
  }
}
