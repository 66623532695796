import {Component} from '@angular/core';
import {UserCommonComponent} from '../user-common/user-common.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {Observable} from 'rxjs';
import {UserUpdateDto, UserViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {MatDialog} from '@angular/material/dialog';
import {SubFolderType} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';

@Component({
  selector: 'app-user-update',
  templateUrl: '../user-common/user-common.component.html',
  styleUrls: ['../user-common/user-common.component.scss'],
})
export class UserUpdateComponent extends UserCommonComponent {
  userId?: string;
  user?: UserViewDto;
  groupId = '';

  constructor(
    private route: ActivatedRoute,
    formBuilder: FormBuilder,
    toastService: ToastService,
    jwtHelperService: JwtHelperService,
    router: Router,
    translateService: TranslateService,
    apiClientFactory: ApiClientFactoryService,
    dialog: MatDialog
  ) {
    super(formBuilder, toastService, jwtHelperService, router, translateService, apiClientFactory, dialog);
  }

  override ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.userId = params['userId'];
      this.groupId = params['groupId'] ?? '';
      this.userService.getUserById(this.userId!).subscribe({
        next: (n: UserViewDto) => {
          this.user = n;
          super.ngOnInit();
        },
        error: () => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('users.errorFetchingUser')
          );
        },
        complete: () => {},
      });
    });
  }

  protected override buildUserDto(): UserUpdateDto {
    return {
      id: this.userId!,
      firstName: this.formGroup.controls.firstName.value === null ? '' : this.formGroup.controls.firstName.value,
      lastName: this.formGroup.controls.lastName.value === null ? '' : this.formGroup.controls.lastName.value,
      description: this.formGroup.controls.description.value === null ? '' : this.formGroup.controls.description.value,
      emailAddress:
        this.formGroup.controls.emailAddress.value === null ? '' : this.formGroup.controls.emailAddress.value,
      groups: this.assignedGroups,
      identityProvider: this.formGroup.controls.identityProvider.value ?? UserUpdateDto.IdentityProviderEnum.LOCAL,
    };
  }

  protected override callService(tenantId: string, dto: UserUpdateDto): Observable<any> {
    return this.userService.updateUserById(dto, tenantId);
  }

  protected override getUser(): UserViewDto | undefined {
    return this.user;
  }

  override getTitle(): string {
    return this.translateService.instant('headlines.updateUser');
  }

  override getSubTitle(): string {
    return this.translateService.instant('subheadline.updateUserSub');
  }

  override getErrorMessage(): string {
    return this.translateService.instant('users.errorUpdatingUser');
  }

  override getSuccessMessage(): string {
    return this.translateService.instant('users.userUpdated');
  }

  override getEmailHint(): string {
    return this.translateService.instant('users.emailAddressHintUpdate');
  }

  override navigateOnCompleted(): void {
    if (this.groupId !== '') {
      this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Members]);
    } else {
      this.router.navigate(['lancrypt', 'identities', 'users']);
    }
  }
}
