import {Injectable, inject} from '@angular/core';
import {ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from 'rxjs';
import {UserDataService} from '../user-data.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationHelperService {
  private regionalLocale = 'en';

  constructor(private translateService: TranslateService) {}

  setRegionalLocale(locale: string) {
    this.regionalLocale = locale.substring(0, 2);
  }

  getRegionalLocale(): string {
    return this.regionalLocale;
  }

  getRegionalDateFormat(): string {
    return this.translateService.instant(`languages.${this.regionalLocale}.dateFormat`);
  }

  getCurrentTranslationLanguage(): string {
    return this.translateService.getLangs().includes(this.translateService.currentLang)
      ? this.translateService.currentLang
      : this.translateService.getDefaultLang();
  }

  renderUsersAndGroupsText(countUsers: number, countGroups: number): string {
    const translateService = this.translateService;

    function render(singular: string, count: number): string {
      const elementName = `${singular}${count === 1 ? 'AmountSingular' : 'AmountPlural'}`;
      const translatedName = translateService.instant('common.' + elementName, {
        amount: count,
      });

      return `${translatedName}`;
    }

    if (countGroups === 0) {
      return countUsers === 0 ? translateService.instant('common.none') : render('user', countUsers);
    }
    const groupString = render('group', countGroups);
    return countUsers === 0 ? groupString : `${groupString}, ${render('user', countUsers)}`;
  }
}

export const translationDataResolver: ResolveFn<string> = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const translateService = inject(TranslateService);
  const userData = inject(UserDataService).userData.value;
  const languageInfo = userData.languageInfo !== '' ? userData.languageInfo : navigator.languages[0].substring(0, 2);

  translateService.setDefaultLang('en');

  try {
    return await firstValueFrom(translateService.use(languageInfo));
  } catch (_) {
    // If selected language can not be fetched, fallback to English
    return await firstValueFrom(translateService.use('en'));
  }
};
