<mat-card class="col-md-8 col-sm-10 col-xs-12 col-lg-8">
    <mat-card-header class="header">
        <mat-card-title>{{'administrators.title' | translate}}</mat-card-title>
        <div class="control-elements">
            <button matTooltip="{{ 'administrators.button.addAdministrator' | translate }}" mat-mini-fab
                color="lancrypt-primary" [routerLink]="['/account/administrators/create-administrator']">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-card-subtitle>
            {{'administrators.subtitle' | translate}}
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'administrators.name' | translate }}</th>
                <td mat-cell *matCellDef="let administrator">{{ displayName(administrator) }}</td>
            </ng-container>

            <ng-container matColumnDef="emailAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'administrators.email' | translate }}</th>
                <td mat-cell *matCellDef="let administrator">{{ administrator.emailAddress }}
                </td>
            </ng-container>

            <ng-container matColumnDef="primaryContact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'administrators.primaryContact' | translate }}</th>
                <td mat-cell *matCellDef="let administrator">
                    <div class="icon-cell">
                    <button mat-icon-button *ngIf="!administrator.primaryContact"
                        (click)="setPrimaryContact(administrator.id)" disableDuringRequest
                        matTooltip="{{ 'administrators.changePrimaryContactTooltip' | translate }}"
                        [disabled]="administrator.status !== StatusEnum.CONFIRMED">
                        <mat-icon class="material-icons-outlined">check_box_outline_blank</mat-icon>
                    </button>
                    <mat-icon *ngIf="administrator.primaryContact"
                        matTooltip="{{ 'administrators.primaryContactTooltip' | translate }}"
                        class="material-icons-outlined">check
                    </mat-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'administrators.status' | translate }}</th>
                <td mat-cell *matCellDef="let administrator">
                    <div class="icon-cell">
                    <button mat-icon-button *ngIf="administrator.status !== StatusEnum.CONFIRMED"
                        (click)="resendVerification(administrator.emailAddress)" disableDuringRequest
                        matTooltip="{{ 'administrators.verificationPendingTooltip' | translate }}">
                        <mat-icon class="material-icons-outlined">update</mat-icon>
                    </button>
                    <mat-icon *ngIf="administrator.status === StatusEnum.CONFIRMED"
                        matTooltip="{{ 'administrators.verificationCompletedTooltip' | translate }}"
                        class="material-icons-outlined">check
                    </mat-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let administrator" class="action-cell">
                    <button mat-icon-button (click)="editAdministrator(administrator.id)"
                        matTooltip="{{ 'administrators.button.editAdministrator' | translate }}">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteAdministrator(administrator.id)"
                        [disabled]="!allowDelete(administrator)"
                        matTooltip="{{ 'administrators.button.deleteAdministrator' | translate }}">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell mat-cell-empty" [attr.colspan]="displayedColumns.length">
                    <div *onlyShowWhenIdle>{{ 'administrators.noAdministrators' | translate }}</div>
                </td>
            </tr>

        </table>
    </mat-card-content>

    <mat-card-footer>
        <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
</mat-card>