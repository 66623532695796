import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ApiClientFactoryService} from '../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../services/helper/jwt-helper.service';
import {PersonalInfoDto, TenantService, TenantUpdateDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc/';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from 'src/app/services/toaster.service';
import {FIELD_LENGTH_CONSTRAINTS} from '../../../../shared/lancrypt.constants';

@Component({
  selector: 'app-edit-tenant-info',
  templateUrl: './edit-tenant-info.component.html',
  styleUrls: ['./edit-tenant-info.component.scss'],
})
export class EditTenantInfoComponent implements OnInit, OnChanges {
  @Input() personalInfo!: PersonalInfoDto;
  @Output() onFinished = new EventEmitter<PersonalInfoDto>();

  tenantApiClient: TenantService;

  maxLengthConstraints = {
    street1: FIELD_LENGTH_CONSTRAINTS.street1,
    street2: FIELD_LENGTH_CONSTRAINTS.street2,
    city: FIELD_LENGTH_CONSTRAINTS.city,
    state: FIELD_LENGTH_CONSTRAINTS.state,
    postalCode: FIELD_LENGTH_CONSTRAINTS.postalCode,
    country: FIELD_LENGTH_CONSTRAINTS.country,
  };

  constructor(
    private _formBuilder: FormBuilder,
    private translationService: TranslateService,
    private toastService: ToastService,
    private apiClientFactory: ApiClientFactoryService,
    private jwtHelperService: JwtHelperService
  ) {
    this.tenantApiClient = apiClientFactory.getTenantService();
  }

  formGroup = this._formBuilder.group({
    street1: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.street1)]],
    street2: ['', [Validators.maxLength(this.maxLengthConstraints.street2)]],
    city: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.city)]],
    state: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.state)]],
    postalCode: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.postalCode)]],
    country: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.country)]],
  });

  ngOnInit(): void {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.personalInfo) {
      this.formGroup.patchValue({
        street1: this.personalInfo.address.street1,
        street2: this.personalInfo.address.street2,
        city: this.personalInfo.address.city,
        state: this.personalInfo.address.state,
        postalCode: this.personalInfo.address.zip,
        country: this.personalInfo.address.country,
      });
    }
  }

  async onSave() {
    if (this.formGroup.valid) {
      const tenantId = await this.jwtHelperService.getTenantIdFromToken();

      const dto: TenantUpdateDto = {
        id: tenantId,
        address: {
          street1: this.formGroup.controls.street1.value || '',
          street2: this.formGroup.controls.street2.value || '',
          city: this.formGroup.controls.city.value || '',
          state: this.formGroup.controls.state.value || '',
          zip: this.formGroup.controls.postalCode.value || '',
          country: this.formGroup.controls.country.value || '',
        },
      };

      this.tenantApiClient.updateTenantById(dto, tenantId).subscribe({
        next: async (_: any) => {
          this.personalInfo.address.street1 = this.formGroup.controls.street1.value || '';
          this.personalInfo.address.street2 = this.formGroup.controls.street2.value || '';
          this.personalInfo.address.city = this.formGroup.controls.city.value || '';
          this.personalInfo.address.state = this.formGroup.controls.state.value || '';
          this.personalInfo.address.zip = this.formGroup.controls.postalCode.value || '';
          this.personalInfo.address.country = this.formGroup.controls.country.value || '';

          this.onFinished.emit(this.personalInfo);
        },
        error: async (_: any) => {
          this.toastService.showError(
            this.translationService.instant('common.error'),
            this.translationService.instant('errors.updateingPersonInfo')
          );
          this.onFinished.emit(this.personalInfo);
        },
        complete: () => {},
      });
    }
  }

  onCancel(): void {
    this.onFinished.emit(this.personalInfo);
  }
}
