import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LancryptIdentitiesComponent} from './lancrypt-identities.component';
import {GroupCreateComponent} from './components/group-create/group-create.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {CardModule, FormModule, GridModule} from '@coreui/angular';
import {AssetsViewComponent} from './components/assets-view/assets-view.component';
import {MembersViewComponent} from './components/members-view/members-view.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LancryptMultiselectDropdownModule} from '../../../shared/components/lancrypt-multiselect-dropdown/lancrypt-multiselect-dropdown.module';
import {GroupViewComponent} from './components/group-view/group-view.component';
import {RouterModule} from '@angular/router';
import {GroupUpdateComponent} from './components/group-update/group-update.component';
import {GroupCommonComponent} from './components/group-common/group-common.component';
import {UserCreateComponent} from './components/user-create/user-create.component';
import {UserCommonComponent} from './components/user-common/user-common.component';
import {UserUpdateComponent} from './components/user-update/user-update.component';
import {AssetsAssignComponent} from './components/assets-assign/assets-assign.component';
import {MembersAssignComponent} from './components/members-assign/members-assign.component';
import {GroupTableSubgroupsComponent} from './components/group-table-subgroups/group-table-subgroups.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DualOptionDialogModule} from '../../../shared/components/dual-option-dialog/dual-option-dialog.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {UserDetailsComponent} from './components/user-details/user-details.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {GroupTableCommonComponent} from './components/group-table-common/group-table-common.component';
import {GroupTableDetailsComponent} from './components/group-table-details/group-table-details.component';
import {AssetsTableCommonComponent} from './components/assets-table-common/assets-table-common.component';
import {AssetsTableGroupsComponent} from './components/assets-table-groups/assets-table-groups.component';
import {AssetsTableDetailsComponent} from './components/assets-table-details/assets-table-details.component';
import {ClientsTableCommonComponent} from './components/clients-table-common/clients-table-common.component';
import {ClientsTableDetailsComponent} from './components/clients-table-details/clients-table-details.component';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgxLazyMatSelectModule} from 'ngx-lazy-mat-select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StorePaginationPageSizeDirective} from 'src/app/shared/store-pagination-page-size-directive';

@NgModule({
  declarations: [
    LancryptIdentitiesComponent,
    AssetsViewComponent,
    MembersViewComponent,
    GroupViewComponent,
    GroupCommonComponent,
    GroupCreateComponent,
    GroupUpdateComponent,
    UserCreateComponent,
    UserCommonComponent,
    UserUpdateComponent,
    AssetsAssignComponent,
    MembersAssignComponent,
    GroupTableSubgroupsComponent,
    UserDetailsComponent,
    GroupTableCommonComponent,
    GroupTableDetailsComponent,
    AssetsTableCommonComponent,
    AssetsTableGroupsComponent,
    AssetsTableDetailsComponent,
    ClientsTableCommonComponent,
    ClientsTableDetailsComponent,
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    CardModule,
    GridModule,
    TranslateModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    MatCheckboxModule,
    MatCardModule,
    LancryptMultiselectDropdownModule,
    RouterModule,
    MatDialogModule,
    DualOptionDialogModule,
    MatDividerModule,
    MatSortModule,
    MatProgressBarModule,
    BusyServiceModule,
    MatExpansionModule,
    MatSelectModule,
    MatPaginatorModule,
    NgxLazyMatSelectModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
    StorePaginationPageSizeDirective,
  ],
})
export class LancryptIdentitiesModule {}
