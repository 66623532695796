import {Component} from '@angular/core';

@Component({
  selector: 'app-dashboard-empty',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss'],
})
export class EmptyLayoutComponent {
  constructor() {}
}
