import {Component, OnInit} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppRootComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
