import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubFolderType} from 'src/app/dtos/lancrypt/GroupTreeViewIconDto';
import {Router} from '@angular/router';
import {GroupTreeViewFlatNode} from '../../../../../dtos/lancrypt/GroupTreeViewFlatNode';

@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss'],
})
export class GroupViewComponent implements OnInit {
  @Input() activeNode: GroupTreeViewFlatNode | undefined;
  @Input() rootName = '';

  @Output() onSubGroupDeleted = new EventEmitter<void>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  showEdit(): boolean {
    return (
      this.activeNode?.subType === SubFolderType.Group &&
      this.activeNode.id !== undefined &&
      this.activeNode.id !== '' &&
      this.activeNode.level > 0 &&
      !this.activeNode.synced
    );
  }

  edit(): Promise<boolean> {
    return this.router.navigate(['lancrypt', 'identities', 'edit-group', this.activeNode?.id]);
  }

  subGroupDeleted() {
    this.onSubGroupDeleted.emit();
  }
}
