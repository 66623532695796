import {Component} from '@angular/core';
import {GroupUpdateDto, GroupViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {GroupCommonComponent} from '../group-common/group-common.component';
import {Observable} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {MatDialog} from '@angular/material/dialog';
import {SubFolderType} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';

@Component({
  selector: 'app-group-update',
  templateUrl: '../group-common/group-common.component.html',
  styleUrls: ['../group-common/group-common.component.scss'],
})
export class GroupUpdateComponent extends GroupCommonComponent {
  groupId?: string;
  group?: GroupViewDto;

  constructor(
    private route: ActivatedRoute,
    formBuilder: FormBuilder,
    toastService: ToastService,
    jwtHelperService: JwtHelperService,
    router: Router,
    translateService: TranslateService,
    apiClientFactory: ApiClientFactoryService,
    dialog: MatDialog
  ) {
    super(formBuilder, toastService, jwtHelperService, router, translateService, apiClientFactory, dialog);
  }

  override ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.groupId = params['groupId'];
      this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
        this.groupService.getGroupById(tenantId, this.groupId!).subscribe({
          next: (n: GroupViewDto) => {
            this.group = n;
            super.ngOnInit();
          },
          error: (_: any) => {
            this.toastService.showError(
              this.translateService.instant('common.error'),
              this.translateService.instant('groups.errorFetchingGroup')
            );
          },
          complete: () => {},
        });
      });
    });
  }

  protected override buildGroupDto(): GroupUpdateDto {
    return {
      id: this.groupId!,
      name: this.formGroup.controls.name.value === null ? '' : this.formGroup.controls.name.value,
      description: this.formGroup.controls.description.value === null ? '' : this.formGroup.controls.description.value,
      parents: this.assignedGroups,
    };
  }

  protected override callService(tenantId: string, dto: GroupUpdateDto): Observable<any> {
    return this.groupService.updateGroup(dto, tenantId);
  }

  protected override getGroupId(): string | undefined {
    return this.groupId;
  }

  protected override getGroup(): GroupViewDto | undefined {
    return this.group;
  }

  override getTitle(): string {
    return this.translateService.instant('headlines.updateGroup');
  }

  override getErrorMessage(): string {
    return this.translateService.instant('groups.errorUpdatingGroup');
  }

  override getSuccessMessage(): string {
    return this.translateService.instant('groups.groupUpdated');
  }

  override navigateOnCompleted(): void {
    this.navigate();
  }

  override cancel() {
    this.navigate();
  }

  private navigate(): void {
    this.router.navigate(['/lancrypt/identities/group/' + this.groupId + '/' + SubFolderType.Group]);
  }
}
