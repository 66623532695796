import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LancryptMultiselectDropdownComponent} from './lancrypt-multiselect-dropdown.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [LancryptMultiselectDropdownComponent],
  imports: [CommonModule, NgMultiSelectDropDownModule],
  exports: [LancryptMultiselectDropdownComponent],
})
export class LancryptMultiselectDropdownModule {}
