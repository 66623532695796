export const environment = {
  production: (window as any)['env'].production,
  stage: (window as any)['env'].stage,
  keycloak: {
    host: (window as any)['env'].keycloak.host,
    realm: (window as any)['env'].keycloak.realm,
    clientId: (window as any)['env'].keycloak.clientId,
    redirectUrl: (window as any)['env'].keycloak.redirectUrl,
  },
  applicationInsights: {
    enabled: (window as any)['env'].applicationInsights.enabled,
    instrumentationKey: (window as any)['env'].applicationInsights.instrumentationKey,
  },
  recaptcha: {
    siteKey: (window as any)['env'].recaptcha.siteKey,
  },
  backends: {
    portalFeCmpBaseUrl: (window as any)['env'].backends.portalFeCmpBaseUrl,
    featureFlagBaseUrl: (window as any)['env'].backends.featureFlagBaseUrl,
  },
  branding: {
    titlePostfix: (window as any)['env'].branding.titlePostfix,
  },
};
