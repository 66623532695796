import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dual-option-dialog',
  templateUrl: './dual-option-dialog.component.html',
  styleUrls: ['./dual-option-dialog.component.scss'],
})
export class DualOptionDialog {
  result = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

export interface DialogData {
  title: string;
  description: string;
  positiveTitle: string;
  negativeTitle: string;
}
