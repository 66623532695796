import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AuditLogDetailViewDto, AuditLogService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {AuditLogDetailDto} from 'src/app/dtos/lancrypt/auditLogDetailDto';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {ToastService} from 'src/app/services/toaster.service';

@Component({
  selector: 'app-auditlog-detail',
  templateUrl: './auditlog-detail.component.html',
  styleUrls: ['./auditlog-detail.component.scss'],
})
export class AuditlogDetailComponent implements OnInit {
  auditlogService: AuditLogService;

  detail!: AuditLogDetailViewDto;

  payLoad: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AuditLogDetailDto,
    private toastService: ToastService,
    private translateService: TranslateService,
    private _apiClientFactory: ApiClientFactoryService
  ) {
    this.auditlogService = _apiClientFactory.getAuditLogService();
  }

  ngOnInit(): void {
    this.auditlogService.getAuditLogDetailById(this.data.tenantId, this.data.auditLog.id).subscribe({
      next: async (n: AuditLogDetailViewDto) => {
        this.detail = n;
        this.payLoad = JSON.parse(n.payload);
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.errorGettingAuditLogs')
        );
      },
    });
  }
}
