import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {UserService, ConpalUserViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../services/toaster.service';
import {BusyService} from '../../../shared/components/busy-service/busy.service';
import {DualOptionDialog} from '../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {UserDataService} from '../../../services/user-data.service';

@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrl: './administrators.component.scss',
})
export class AdministratorsComponent implements OnInit {
  dataSource: MatTableDataSource<ConpalUserViewDto>;

  displayedColumns: string[] = ['name', 'emailAddress', 'primaryContact', 'status', 'actions'];
  userService: UserService;
  tenantId: string | undefined = '';
  StatusEnum = ConpalUserViewDto.StatusEnum;

  constructor(
    private _apiClientFactory: ApiClientFactoryService,
    private jwtHelperService: JwtHelperService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router,
    private busyService: BusyService,
    private userDataService: UserDataService
  ) {
    this.dataSource = new MatTableDataSource<ConpalUserViewDto>();
    this.userService = _apiClientFactory.getUserService();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.loadAdministrators();
    });
  }

  private loadAdministrators(): void {
    this.userService.getConpalUsers(this.tenantId!).subscribe({
      next: (n: ConpalUserViewDto[]) => {
        n.sort((a, b) => (a.lastName > b.lastName ? 1 : b.lastName > a.lastName ? -1 : 0));
        this.dataSource.data = n;
      },
      error: (_e: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('administrators.error.failedLoadingAdministrators')
        );
      },
    });
  }

  editAdministrator(administratorId: string): Promise<any> {
    if (!this.busyService.isBusy()) {
      return this.router.navigate(['account', 'administrators', 'edit-administrator', administratorId]);
    }
    return Promise.resolve(false);
  }

  deleteAdministrator(administratorId: string) {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('administrators.deleteAdministrator'),
          description: this.translateService.instant('administrators.deleteAdministratorText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.userService.deleteConpalUser(this.tenantId!, administratorId).subscribe({
            next: () => {},
            error: () => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('administrators.errorDeletingAdministrator')
              );
            },
            complete: () => {
              this.loadAdministrators();
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('administrators.administratorDeleted')
              );
            },
          });
        }
      });
  }

  setPrimaryContact(administratorId: string) {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('administrators.changePrimaryContact'),
          description: this.translateService.instant('administrators.changePrimaryContactText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.userService.updatePrimaryContact(this.tenantId!, administratorId).subscribe({
            next: () => {},
            error: () => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('administrators.errorChangingPrimaryContact')
              );
            },
            complete: () => {
              this.loadAdministrators();
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('administrators.primaryContactChanged')
              );
            },
          });
        }
      });
  }

  resendVerification(administratorEmailAddress: string) {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('administrators.resendVerification'),
          description: this.translateService.instant('administrators.resendVerificationText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.userService.resendVerification(administratorEmailAddress).subscribe({
            next: () => {},
            error: () => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('administrators.errorResendingVerification')
              );
            },
            complete: () => {
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('administrators.verificationSent')
              );
            },
          });
        }
      });
  }

  public allowDelete(administrator: ConpalUserViewDto): boolean {
    return !administrator.primaryContact && administrator.id !== this.userDataService.userData.value.id;
  }

  public displayName(administrator: ConpalUserViewDto): String {
    if (administrator.id === this.userDataService.userData.value.id) {
      return this.translateService.instant('administrators.displayNameYou', {
        firstName: administrator.firstName,
        lastName: administrator.lastName,
      });
    }
    return this.translateService.instant('administrators.displayName', {
      firstName: administrator.firstName,
      lastName: administrator.lastName,
    });
  }
}
