import {MatTableDataSource} from '@angular/material/table';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {GroupService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-group-table-common',
  templateUrl: './group-table-common.component.html',
  styleUrls: ['./group-table-common.component.scss'],
})
export class GroupTableCommonComponent implements AfterViewInit, OnInit {
  dataSource: MatTableDataSource<GroupElement>;
  columnsToDisplay = ['name', 'description'];
  columnsToDisplayWithDelete = [...this.columnsToDisplay, 'delete'];
  tenantId: string | undefined;
  readonly noGroupsRowTextKey: string = 'groups.noGroups';

  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild('paginator') paginator?: MatPaginator;

  protected groupService: GroupService;

  constructor(
    protected dialog: MatDialog,
    protected apiClientFactory: ApiClientFactoryService,
    protected jwtHelperService: JwtHelperService,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {
    this.groupService = apiClientFactory.getGroupService();
    this.dataSource = new MatTableDataSource<GroupElement>();
    this.setupSortingProperties();
  }

  ngOnInit(): Promise<void> {
    return Promise.resolve();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }

  showDelete(_element: GroupElement): boolean {
    return false;
  }

  deleteGroup(_element: GroupElement): void {}

  protected mapGroupElements(groups: GroupDto[]): GroupElement[] {
    return groups.map(g => {
      return {
        id: g.id,
        name: g.name,
        description: g.description ?? '',
        synced: g.syncedGroup,
      };
    });
  }

  protected setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        default:
          return (data as any)[sortHeaderId].toLowerCase();
      }
    };
  }
}

export interface GroupDto {
  id: string;
  name: string;
  description?: string;
  syncedGroup: boolean;
}

export interface GroupElement {
  id: string;
  name: string;
  description: string;
  synced: boolean;
}
