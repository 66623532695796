import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService, SettingsUpdateDto, SettingsViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';

@Component({
  selector: 'app-settings-sync',
  templateUrl: './settings-sync.component.html',
  styleUrls: ['../../lancrypt-settings.component.scss'],
})
export class SettingsSyncComponent implements OnChanges, OnInit {
  formGroup;
  expanded = true;
  editMode = false;
  refreshText = '';
  cacheText = '';

  @Input() settingsViewDto: SettingsViewDto | undefined;

  private settingsApiClient: SettingsService;
  private tenantId!: string;

  constructor(
    private _formBuilder: FormBuilder,
    private translationService: TranslateService,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private jwtHelperService: JwtHelperService
  ) {
    this.settingsApiClient = apiClientFactory.getSettingsService();
    this.formGroup = this._formBuilder.group({
      refreshInterval: new FormControl<Number | undefined>(1),
      cacheExpiration: new FormControl<Number | undefined>(1),
    });
  }

  async ngOnInit() {
    this.tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.formGroup.controls.refreshInterval.disable();
    this.formGroup.controls.cacheExpiration.disable();
  }

  ngOnChanges(): void {
    this.updateFormData();
  }

  onEdit() {
    this.editMode = true;
    this.expanded = true;
    this.formGroup.controls.refreshInterval.enable();
    this.formGroup.controls.cacheExpiration.enable();
  }

  onSave() {
    const settingsUpdateDto: SettingsUpdateDto = {
      refreshInterval: (this.formGroup.controls.refreshInterval.value as number) * 60,
      cacheExpiration: (this.formGroup.controls.cacheExpiration.value as number) * 60 * 24,
      tenantId: this.tenantId,
    };

    this.settingsApiClient.updateSettings(settingsUpdateDto, this.tenantId).subscribe({
      next: async () => {
        this.toastService.showSuccess(
          this.translationService.instant('settings.saveToast.title'),
          this.translationService.instant('settings.saveToast.successful')
        );
        this.editMode = false;
        this.formGroup.controls.refreshInterval.disable();
        this.formGroup.controls.cacheExpiration.disable();
        if (this.settingsViewDto) {
          this.settingsViewDto.cacheExpiration = settingsUpdateDto.cacheExpiration!;
          this.settingsViewDto.refreshInterval = settingsUpdateDto.refreshInterval!;
        }
      },
      error: async (e: HttpErrorResponse) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('settings.errors.saving', {
            error: e.status,
          })
        );
      },
    });
  }

  onCancel() {
    this.updateFormData();
    this.editMode = false;
    this.formGroup.controls.refreshInterval.disable();
    this.formGroup.controls.cacheExpiration.disable();
  }

  private updateFormData() {
    if (this.settingsViewDto) {
      this.formGroup.patchValue({
        refreshInterval: this.settingsViewDto.refreshInterval / 60,
        cacheExpiration: this.settingsViewDto.cacheExpiration / 60 / 24,
      });
      this.computeRefreshText();
      this.computeCacheText();
    }
  }

  onRefreshChanged(): void {
    this.computeRefreshText();
  }

  onCacheChanged(): void {
    this.computeCacheText();
  }

  private computeRefreshText() {
    const refreshInterval = this.formGroup.controls.refreshInterval.value;
    if (refreshInterval === 0) {
      this.refreshText = this.translationService.instant('settings.sync.refreshInterval.disabled');
    } else if (refreshInterval) {
      this.refreshText = this.translationService.instant('settings.sync.refreshInterval.value', {
        value: refreshInterval.toString(),
      });
    }
  }

  private computeCacheText() {
    const cacheExpiration = this.formGroup.controls.cacheExpiration.value;
    if (cacheExpiration) {
      this.cacheText = this.translationService.instant('settings.sync.cacheExpiration.value', {
        value: cacheExpiration.toString(),
      });
    }
  }
}
