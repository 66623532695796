import {Component} from '@angular/core';

import {HeaderComponent} from '@coreui/angular';
import {MatDialog} from '@angular/material/dialog';
import {DialogWhatsNew} from '../../../shared/components/dialog-whats-new/dialog-whats-new.component';

@Component({
  selector: 'app-lancrypt-admin-header',
  templateUrl: './lancrypt-admin-header.component.html',
  styleUrls: ['./lancrypt-admin-header.component.scss'],
})
export class LancryptAdminHeaderComponent extends HeaderComponent {
  constructor(private dialog: MatDialog) {
    super();
  }

  openWhatsNewDialog() {
    this.dialog.open(DialogWhatsNew, {
      width: '800px',
    });
  }
}
