<mat-card class="col-md-8 col-sm-10 col-xs-12 col-lg-8">

  <mat-card-header>
    <mat-card-title>{{'subscriptions.title' | translate}}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <c-container>
      <c-row>
        <c-col class="col-md-5 col-lg-5">
          <mat-label>{{'subscriptions.activeUsers' | translate}}</mat-label>
        </c-col>
        <c-col class="col-md-3 col-lg-3">
          <div>
            {{ usedLicensesInMonth }}
          </div>
        </c-col>
      </c-row>

      <c-row>
        <c-col class="col-md-5 col-lg-5">
          <mat-label>{{'subscriptions.licenseTypeName' | translate}}</mat-label>
        </c-col>
        <c-col class="col-md-3 col-lg-3">
          <div>
            {{ licenseType }}
          </div>
        </c-col>
      </c-row>

      <c-row>
        <c-col class="col-md-5 col-lg-5">
          <mat-label>{{'subscriptions.licenseCount' | translate}}</mat-label>
        </c-col>
        <c-col class="col-md-3 col-lg-3">
          <div>
            {{ licenseCount }}
          </div>
        </c-col>
      </c-row>

      <c-row>
        <c-col class="col-md-5 col-lg-5">
          <mat-label>{{'subscriptions.licensePurchaseDate' | translate}}</mat-label>
        </c-col>
        <c-col class="col-md-3 col-lg-3">
          <div>
            {{ licensePurchaseDate | date }}
          </div>
        </c-col>
      </c-row>

      <c-row>
        <c-col class="col-md-5 col-lg-5">
          <mat-label>{{'subscriptions.licensePeriod' | translate}}</mat-label>
        </c-col>
        <c-col class="col-md-3 col-lg-3">
          <div>
            {{ licensePeriod }}
          </div>
        </c-col>
      </c-row>

      <c-row>
        <c-col class="col-md-5 col-lg-5">
          <mat-label>{{'subscriptions.licenseExpirationDate' | translate}}</mat-label>
        </c-col>
        <c-col class="col-md-3 col-lg-3">
          <div>
            {{ licenseExpirationDate | date }}
          </div>
        </c-col>
      </c-row>
    </c-container>
  </mat-card-content>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
  </mat-card-footer>

</mat-card>

