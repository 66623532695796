<div class="create-group-container create-edit-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ getTitle() }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formGroup" class="form center dense-form-field-2">
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'groups.groupName' | translate }}</mat-label>
          <input matInput formControlName="name" required>
          <mat-error *ngIf="formGroup.hasError('required','name')">
            {{ 'groups.errorNameRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('maxlength','name')">
            {{ 'groups.errorNameMaxLength' | translate: {maxLength: maxLengthConstraints.name} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'groups.groupDescription' | translate }}</mat-label>
          <input matInput formControlName="description">
          <mat-error *ngIf="formGroup.hasError('maxlength','description')">
            {{ 'groups.errorDescriptionMaxLength' | translate: {maxLength: maxLengthConstraints.description} }}
          </mat-error>
        </mat-form-field>
        <div class="group-multiselect">
          <mat-form-field floatLabel="always">
            <mat-label>{{ 'groups.parentGroup' | translate }}</mat-label>
            <mat-select ngxLazyMatSelect (infiniteScroll)="loadNextGroupBatch()"
                        formControlName="groupIds"
                        placeholder="{{getAssignedGroupCountString()}}"
                        [complete]="groupPageCounter === groupPageCount"
                        [multiple]="true">
              <mat-select-trigger>
                {{ getAssignedGroupCountString() }}
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [formControl]="groupFilterCtrl"
                                       placeholderLabel="{{ 'common.search' | translate }}"
                                       noEntriesFoundLabel="{{ 'groups.noGroupFound' | translate }}"/>
              </mat-option>
              <mat-option (onSelectionChange)="changeGroupMultiselect($event)"
                          *ngFor="let group of groupObservable | async"
                          value={{group.id}}>{{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="lancrypt-primary" (click)="createOrUpdateGroup()" disableDuringRequest>
        {{ 'buttons.save' | translate }}
      </button>
      <button mat-raised-button class="" (click)="cancel()" disableDuringRequest>
        {{ 'buttons.cancel' | translate }}
      </button>
      <button mat-raised-button color="warn" *ngIf="getGroupId() !== undefined"
              class="delete-button" (click)="deleteGroup()" disableDuringRequest>
        <mat-icon>delete</mat-icon>
        {{ 'groups.deleteGroup' | translate }}
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
