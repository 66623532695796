import {Component} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {IdentityProviderConnectionCreateDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {ConnectionCommonComponent} from '../connection-common/connection-common.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-connection-create',
  templateUrl: '../connection-common/connection-common.component.html',
  styleUrls: ['../connection-common/connection-common.component.scss'],
})
export class ConnectionCreateComponent extends ConnectionCommonComponent {
  constructor(
    apiClientFactory: ApiClientFactoryService,
    jwtHelperService: JwtHelperService,
    formBuilder: FormBuilder,
    toastService: ToastService,
    translationService: TranslateService,
    router: Router
  ) {
    super(formBuilder, apiClientFactory, jwtHelperService, translationService, toastService, router);
  }

  protected override buildConnectionDto(): IdentityProviderConnectionCreateDto {
    return {
      name: this.formGroup.controls.connectionName.value ?? '',
      connectionType: 'AZURE_AD',
      identityProvider: this.chosenIdentityProvider,
      connectionParams: {
        tenantId: this.formGroup.controls.connectionAzureTenantId.value ?? '',
        clientId: this.formGroup.controls.connectionClientId.value ?? '',
        clientSecret: this.formGroup.controls.connectionClientSecret.value ?? '',
      },
    };
  }

  public override getTitle(): string {
    return 'headlines.newIdentityProviderConnection';
  }

  public override getSubTitle(): string {
    return 'subheadline.newIdentityProviderConnectionSub';
  }

  public override getErrorMessage(): string {
    return 'errors.createIdentityProviderConnection';
  }

  public override getStepTitle(): string {
    return 'identityProviderConnection.stepTitle.createConnection';
  }

  public override getNextTitle(): string {
    return 'buttons.create';
  }

  public override getSuccessMessage(): string {
    return 'identityProviderConnection.connectionCreated';
  }

  protected override callService(tenantId: string, dto: IdentityProviderConnectionCreateDto): Observable<boolean> {
    return this.connectionService.createConnection(dto, tenantId).pipe(map(result => result.id !== null));
  }
}
