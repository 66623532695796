import {Component} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {AdministratorCommonComponent} from '../administrator-common/administrator-common.component';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {UserDataService} from '../../../../../services/user-data.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslationHelperService} from '../../../../../services/helper/translation-helper.service';
import {ConpalUserCreateDto, ConpalUserViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

@Component({
  selector: 'app-administrator-create',
  templateUrl: '../administrator-common/administrator-common.component.html',
  styleUrl: '../administrator-common/administrator-common.component.scss',
})
export class AdministratorCreateComponent extends AdministratorCommonComponent {
  constructor(
    formBuilder: FormBuilder,
    apiClientFactory: ApiClientFactoryService,
    jwtHelperService: JwtHelperService,
    userDataService: UserDataService,
    toastService: ToastService,
    translateService: TranslateService,
    translationHelperService: TranslationHelperService,
    router: Router,
    dialog: MatDialog
  ) {
    super(
      formBuilder,
      apiClientFactory,
      jwtHelperService,
      userDataService,
      toastService,
      translateService,
      translationHelperService,
      router,
      dialog
    );
  }

  public override getTitle(): string {
    return this.translateService.instant('administrators.newAdministratorTitle');
  }

  public override getSubTitle(): string {
    return this.translateService.instant('administrators.newAdministratorSubtitle');
  }

  public override getErrorMessage(): string {
    return this.translateService.instant('administrators.error.errorCreatingAdministrator');
  }

  public override getSuccessMessage(): string {
    return this.translateService.instant('administrators.administratorCreated');
  }

  public override getEmailHint(): string {
    return this.translateService.instant('administrators.emailAddressHint');
  }

  protected override getAdministrator(): ConpalUserViewDto | undefined {
    return undefined;
  }

  protected override buildAdministratorDto(): ConpalUserCreateDto {
    return {
      firstName: this.formGroup.controls.firstName.value === null ? '' : this.formGroup.controls.firstName.value,
      lastName: this.formGroup.controls.lastName.value === null ? '' : this.formGroup.controls.lastName.value,
      emailAddress:
        this.formGroup.controls.emailAddress.value === null ? '' : this.formGroup.controls.emailAddress.value,
      languageInfo: this.translationHelperService.getCurrentTranslationLanguage(),
      regionalFormats: this.translationHelperService.getCurrentTranslationLanguage(),
      mobilePhoneNumber:
        this.formGroup.controls.mobilePhoneNumber.value === null ? '' : this.formGroup.controls.mobilePhoneNumber.value,
      businessPhoneNumber:
        this.formGroup.controls.businessPhoneNumber.value === null
          ? ''
          : this.formGroup.controls.businessPhoneNumber.value,
    };
  }

  protected override callService(tenantId: string, dto: any): Observable<any> {
    return this.userService.createConpalUser(dto, tenantId);
  }

  protected override navigateOnCompleted(): void {
    this.router.navigate(['account', 'administrators']);
  }
}
