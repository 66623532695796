import {Component} from '@angular/core';
import {UserCommonComponent} from '../user-common/user-common.component';
import {FormBuilder} from '@angular/forms';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {Observable} from 'rxjs';
import {UserCreateDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-user-create',
  templateUrl: '../user-common/user-common.component.html',
  styleUrls: ['../user-common/user-common.component.scss'],
})
export class UserCreateComponent extends UserCommonComponent {
  constructor(
    formBuilder: FormBuilder,
    toastService: ToastService,
    jwtHelperService: JwtHelperService,
    router: Router,
    translateService: TranslateService,
    apiClientFactory: ApiClientFactoryService,
    dialog: MatDialog
  ) {
    super(formBuilder, toastService, jwtHelperService, router, translateService, apiClientFactory, dialog);
  }

  protected override buildUserDto(): UserCreateDto {
    return {
      firstName: this.formGroup.controls.firstName.value === null ? '' : this.formGroup.controls.firstName.value,
      lastName: this.formGroup.controls.lastName.value === null ? '' : this.formGroup.controls.lastName.value,
      description: this.formGroup.controls.description.value === null ? '' : this.formGroup.controls.description.value,
      emailAddress:
        this.formGroup.controls.emailAddress.value === null ? '' : this.formGroup.controls.emailAddress.value,
      groups: this.assignedGroups,
      identityProvider: this.formGroup.controls.identityProvider.value ?? UserCreateDto.IdentityProviderEnum.LOCAL,
    };
  }

  protected override callService(tenantId: string, dto: UserCreateDto): Observable<any> {
    return this.userService.createUser(dto, tenantId);
  }

  override getTitle(): string {
    return this.translateService.instant('headlines.newUser');
  }

  override getSubTitle(): string {
    return this.translateService.instant('subheadline.newUserSub');
  }

  override getErrorMessage(): string {
    return this.translateService.instant('users.errorCreatingUser');
  }

  override getSuccessMessage(): string {
    return this.translateService.instant('users.userCreated');
  }

  override getEmailHint(): string {
    return this.translateService.instant('users.emailAddressHint');
  }

  override navigateOnCompleted(): void {
    this.router.navigate(['lancrypt', 'identities', 'users']);
  }
}
