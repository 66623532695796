import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {ConpalUserViewDto, FieldErrorDto, UserService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {UserDataService} from '../../../../../services/user-data.service';
import {TranslationHelperService} from '../../../../../services/helper/translation-helper.service';
import {FIELD_LENGTH_CONSTRAINTS} from '../../../../../shared/lancrypt.constants';
import {CustomValidators} from '../../../../../shared/components/validators/custom-validators';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';

@Component({
  selector: 'app-administrator-common',
  templateUrl: './administrator-common.component.html',
  styleUrl: './administrator-common.component.scss',
})
export class AdministratorCommonComponent implements OnInit {
  protected userService: UserService;

  private tenantId!: string;

  inEditMode = false;
  canDelete = false;

  formGroup;
  emailDomainValid = true;

  maxLengthConstraints = {
    firstName: FIELD_LENGTH_CONSTRAINTS.firstName,
    lastName: FIELD_LENGTH_CONSTRAINTS.lastName,
    emailAddress: FIELD_LENGTH_CONSTRAINTS.email,
    mobilePhoneNumber: FIELD_LENGTH_CONSTRAINTS.mobilePhoneNumber,
    businessPhoneNumber: FIELD_LENGTH_CONSTRAINTS.businessPhoneNumber,
  };

  constructor(
    private _formBuilder: FormBuilder,
    apiClientFactory: ApiClientFactoryService,
    protected jwtHelperService: JwtHelperService,
    protected userDataService: UserDataService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected translationHelperService: TranslationHelperService,
    protected router: Router,
    private dialog: MatDialog
  ) {
    this.userService = apiClientFactory.getUserService();
    this.formGroup = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.firstName)]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.lastName)]],
      emailAddress: [
        '',
        [
          Validators.required,
          this.emailDomainValidValidator.bind(this),
          Validators.maxLength(this.maxLengthConstraints.emailAddress),
          Validators.email,
        ],
      ],
      mobilePhoneNumber: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.mobilePhoneNumber)]],
      businessPhoneNumber: ['', Validators.maxLength(this.maxLengthConstraints.businessPhoneNumber)],
    });
  }

  ngOnInit(): void {
    const userData = this.userDataService.userData.value;
    this.tenantId = userData.tenantId;

    const admin = this.getAdministrator();
    if (admin !== undefined) {
      this.inEditMode = true;
      this.canDelete = admin.id !== userData.id && !admin.primaryContact;
      this.formGroup.controls.firstName.setValue(admin!.firstName);
      this.formGroup.controls.lastName.setValue(admin!.lastName);
      this.formGroup.controls.emailAddress.setValue(admin!.emailAddress);
      this.formGroup.controls.mobilePhoneNumber.setValue(admin!.mobilePhoneNumber);
      this.formGroup.controls.businessPhoneNumber.setValue(admin!.businessPhoneNumber ?? '');
      this.formGroup.controls.emailAddress.addAsyncValidators([
        CustomValidators.createEmailValidatorWithInitValue(admin!.emailAddress, true, this.userService),
      ]);
    } else {
      this.formGroup.controls.emailAddress.addAsyncValidators([
        CustomValidators.createEmailValidator(true, this.userService),
      ]);
      this.inEditMode = false;
      this.canDelete = false;
    }
  }

  public getTitle(): string {
    return '';
  }

  public getSubTitle(): string {
    return '';
  }

  public getErrorMessage(): string {
    return '';
  }

  public getSuccessMessage(): string {
    return '';
  }

  public getEmailHint(): string {
    return '';
  }

  protected getAdministrator(): ConpalUserViewDto | undefined {
    return undefined;
  }

  protected buildAdministratorDto(): any {
    return undefined;
  }

  protected callService(_tenantId: string, _dto: any): Observable<any> {
    return new Observable<any>();
  }

  protected navigateOnCompleted(): void {}

  private emailDomainValidValidator(_: AbstractControl): ValidationErrors | null {
    return this?.emailDomainValid ? null : {notValid: true};
  }

  cancel() {
    this.navigateOnCompleted();
  }

  onEmailAddressChange(_: Event): void {
    this.emailDomainValid = true;
    this.formGroup.controls.emailAddress.updateValueAndValidity();
  }

  deleteAdministrator() {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('administrators.deleteAdministrator'),
          description: this.translateService.instant('administrators.deleteAdministratorText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          const admin = this.getAdministrator();
          this.userService.deleteConpalUser(this.tenantId, admin!.id).subscribe({
            next: () => {},
            error: () => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('administrators.errorDeletingAdministrator')
              );
            },
            complete: () => {
              this.navigateOnCompleted();
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('administrators.administratorDeleted')
              );
            },
          });
        }
      });
  }

  createOrUpdateAdministrator() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const dto = this.buildAdministratorDto();

    this.callService(this.tenantId, dto).subscribe({
      next: (_: any) => {},
      error: async (e: HttpErrorResponse) => {
        const errors = e.error.errors;
        if (errors !== undefined) {
          errors.forEach((x: FieldErrorDto) => {
            if (x.field === 'email') {
              this.emailDomainValid = false;
              this.formGroup.controls.emailAddress.updateValueAndValidity();
            }
          });
        }

        this.toastService.showError(this.translateService.instant('common.error'), this.getErrorMessage());
      },
      complete: () => {
        this.navigateOnCompleted();
        this.toastService.showSuccess(this.translateService.instant('common.success'), this.getSuccessMessage());
      },
    });
  }
}
