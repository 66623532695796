import {Component} from '@angular/core';
import {FooterComponent} from '@coreui/angular';

@Component({
  selector: 'app-lancrypt-admin-footer',
  templateUrl: './lancrypt-admin-footer.component.html',
  styleUrls: ['./lancrypt-admin-footer.component.scss'],
})
export class LancryptAdminFooterComponent extends FooterComponent {
  constructor() {
    super();
  }
}
