<div class="assign-members-container">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'headlines.editMembers' | translate }}</mat-card-title>
      <mat-card-subtitle>
        {{ 'subheadline.assignMemberSub0' | translate }}
        <a routerLink="/lancrypt/identities/create-user">{{ 'subheadline.assignMemeberSub1' | translate }}</a>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="dense-form-field-2">
      <mat-form-field>
        <mat-label>{{ 'users.filter' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="{{'users.search' | translate}}" #input>
      </mat-form-field>

      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z1">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? toggleSelection(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'users.columns.name' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ getUserFullName(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'users.columns.logonName' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.emailAddress }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="$event ? toggleSelection(row) : null">
        </tr>
      </table>

      <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalRows"
                     [pageIndex]="currentPage" (page)="pageChanged($event)"
                     showFirstLastButtons>
      </mat-paginator>

    </mat-card-content>

    <mat-card-actions class="control-elements">
      <button mat-raised-button color="lancrypt-primary" (click)="addAssignment()" disableDuringRequest>
        {{ 'buttons.save' | translate }}
      </button>
      <button mat-raised-button class="" (click)="cancel()" disableDuringRequest>
        {{ 'buttons.cancel' | translate }}
      </button>
    </mat-card-actions>

    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
