import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FooterModule, GridModule, ImgModule} from '@coreui/angular';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {EditPersonalInfoModule} from '../edit-personal-info/edit-personal-info.module';
import {MatButtonModule} from '@angular/material/button';
import {SubscriptionsComponent} from './subscriptions.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';

@NgModule({
  declarations: [SubscriptionsComponent],
  imports: [
    CommonModule,
    GridModule,
    MatCardModule,
    TranslateModule,
    EditPersonalInfoModule,
    MatButtonModule,
    ImgModule,
    FooterModule,
    NgOptimizedImage,
    MatFormFieldModule,
    MatProgressBarModule,
    BusyServiceModule,
  ],
})
export class SubscriptionModule {}
