import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {
  IdentityProviderConnectionService,
  IdentityProviderConnectionViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {ToastService} from 'src/app/services/toaster.service';
import {DualOptionDialog} from '../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {BusyService} from '../../../shared/components/busy-service/busy.service';

@Component({
  selector: 'app-lancrypt-connections',
  templateUrl: './lancrypt-connections.component.html',
  styleUrls: ['./lancrypt-connections.component.scss'],
})
export class LancryptConnectionsComponent implements OnInit {
  dataSource: MatTableDataSource<IdentityProviderConnectionViewDto>;

  displayedColumns: string[] = ['name', 'connectionType', 'activeJob', 'actions'];
  api: IdentityProviderConnectionService;
  tenantId: string | undefined = '';

  constructor(
    private _apiClientFactory: ApiClientFactoryService,
    private _jwtHelper: JwtHelperService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private translationService: TranslateService,
    private router: Router,
    private busyService: BusyService
  ) {
    this.dataSource = new MatTableDataSource<IdentityProviderConnectionViewDto>();
    this.api = _apiClientFactory.getIdentityProviderConnectionService();
  }

  ngOnInit(): void {
    this._jwtHelper.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.loadConnections();
    });
  }

  private loadConnections(): void {
    this.api.getConnectionList(this.tenantId!).subscribe({
      next: (n: IdentityProviderConnectionViewDto[]) => {
        this.dataSource.data = n;
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('identityProviderConnection.error.failedLoadingConnectionList')
        );
      },
    });
  }

  importConnection(connectionId: string): Promise<any> {
    if (!this.busyService.isBusy()) {
      return this.router.navigate(['lancrypt', 'connections', 'import', connectionId]);
    }
    return Promise.resolve(false);
  }

  deleteConnection(connectionId: string): void {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translationService.instant('identityProviderConnection.deleteConnection'),
          description: this.translationService.instant('identityProviderConnection.deleteConnectionText'),
          positiveTitle: this.translationService.instant('common.confirm'),
          negativeTitle: this.translationService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.api.deleteConnection(this.tenantId!, connectionId).subscribe({
          next: (_: any) => {},
          error: (_: any) => {
            this.toastService.showError(
              this.translationService.instant('common.error'),
              this.translationService.instant('identityProviderConnection.error.failedToDelete')
            );
          },
          complete: () => {
            this.loadConnections();
            this.toastService.showSuccess(
              this.translationService.instant('common.success'),
              this.translationService.instant('identityProviderConnection.connectionDeleted')
            );
          },
        });
      });
  }

  editConnection(connection: IdentityProviderConnectionViewDto) {
    if (this.allowAction(connection)) {
      return this.router.navigate(['lancrypt', 'connections', 'edit-connection', connection.id]);
    }
    return Promise.resolve(false);
  }

  public allowAction(connection: IdentityProviderConnectionViewDto): boolean {
    return !connection.activeImportJob && !connection.activeDeletionJob && !this.busyService.isBusy();
  }
}
