import {Component, OnInit} from '@angular/core';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {PersonalInfoDto, UserService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc/';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DualOptionDialog} from '../../../shared/components/dual-option-dialog/dual-option-dialog.component';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  userApiClient: UserService;
  tenantId: string | undefined;

  isEditingBasicInfo = false;
  isEditingTenantInfo = false;
  isEditingLanguageInfo = false;

  personalInfo: PersonalInfoDto = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    businessPhone: '',
    companyName: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    languageInfo: '',
    regionalFormats: '',
    localIdpPasswordAvailable: false,
  };

  constructor(
    private apiClientFactory: ApiClientFactoryService,
    private translationService: TranslateService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService,
    private dialog: MatDialog
  ) {
    this.userApiClient = apiClientFactory.getUserService();
  }

  async ngOnInit() {
    this.tenantId = await this.jwtHelperService.getTenantIdFromToken();

    this.userApiClient.getPersonalInfo(this.tenantId!).subscribe({
      next: async (n: PersonalInfoDto) => {
        this.personalInfo = n;
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('errors.gettingPersonalInfo')
        );
      },
      complete: () => {},
    });
  }

  mapLanguage(key: string): string {
    if (!key) {
      return '';
    }

    return this.translationService.instant(`languages.${key.substring(0, 2)}.title`);
  }

  hideEditComponents() {
    this.isEditingBasicInfo = false;
    this.isEditingTenantInfo = false;
    this.isEditingLanguageInfo = false;
  }

  onFinished(personalInfo: PersonalInfoDto): void {
    this.hideEditComponents();
    this.personalInfo = personalInfo;
  }

  onStartChangingPassword(): void {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translationService.instant('personalInfo.changePassword.title'),
          description: this.translationService.instant('personalInfo.changePassword.description'),
          positiveTitle: this.translationService.instant('common.confirm'),
          negativeTitle: this.translationService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.userApiClient.updateConpalUserPassword(this.tenantId!, this.personalInfo.id).subscribe({
          next: (_: any) => {},
          error: (_: any) => {
            this.toastService.showError(
              this.translationService.instant('common.error'),
              this.translationService.instant('personalInfo.changePassword.error.failedChangingPassword')
            );
          },
          complete: () => {
            this.toastService.showSuccess(
              this.translationService.instant('common.success'),
              this.translationService.instant('personalInfo.changePassword.requestSuccess')
            );
          },
        });
      });
  }

  onStartEditingBasicInfo(): void {
    this.isEditingBasicInfo = true;
  }

  onStartEditingTenantInfo(): void {
    this.isEditingTenantInfo = true;
  }

  onStartEditingLanguageInfo(): void {
    this.isEditingLanguageInfo = true;
  }

  error(): void {
    this.toastService.showError(
      this.translationService.instant('common.error'),
      this.translationService.instant('errors.updateingPersonInfo')
    );
  }
}
