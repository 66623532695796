import {Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {BusyService} from './busy.service';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[onlyShowWhenIdle]',
})
export class OnlyShowWhenIdleDirective implements OnInit, OnDestroy {
  subscription?: Subscription;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private busyService: BusyService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.checkBusyProgress();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private checkBusyProgress() {
    this.subscription = this.busyService.busy$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(busy => {
      if (busy) {
        this.viewContainer.clear();
      } else {
        if (this.viewContainer.length === 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    });
  }
}
