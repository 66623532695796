import {Component} from '@angular/core';
import {ClientsTableCommonComponent} from '../clients-table-common/clients-table-common.component';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';
import {ClientInfoViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

@Component({
  selector: 'app-clients-table-details',
  templateUrl: '../clients-table-common/clients-table-common.component.html',
  styleUrls: ['../clients-table-common/clients-table-common.component.scss'],
})
export class ClientsTableDetailsComponent extends ClientsTableCommonComponent {
  constructor(
    _jwtHelper: JwtHelperService,
    _apiClientFactory: ApiClientFactoryService,
    translationService: TranslateService,
    toastService: ToastService
  ) {
    super(_jwtHelper, _apiClientFactory, translationService, toastService);
  }

  loadClients(clients: ClientInfoViewDto[]) {
    this.dataSource.data = this.mapClientElements(clients);
  }
}
