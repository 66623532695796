import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn} from '@angular/forms';
import {debounceTime, first, Observable, of, switchMap} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {EmailValidationResult, UserService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

export class CustomValidators {
  static createEmailValidator(isAdmin: boolean, userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.asyncEmailValidation(isAdmin, control, userService);
    };
  }

  static createEmailValidatorWithInitValue(
    initalValue: string,
    isAdmin: boolean,
    userService: UserService
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value === initalValue) {
        return of(null);
      }
      return this.asyncEmailValidation(isAdmin, control, userService);
    };
  }

  private static asyncEmailValidation(isAdmin: boolean, control: AbstractControl, userService: UserService) {
    return control.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(value => userService.validateEmail(value)),
      map((result: EmailValidationResult) => {
        if (isAdmin) {
          return result.adminEmailInUse ? {emailInUse: true} : null;
        }
        return result.userEmailInUse ? {emailInUse: true} : null;
      }),
      first()
    );
  }

  /**
   * Creates a validator that checks for a given prefix (e.g. placeholders: "<network share>") and only matches the rest with the pattern.
   * @param prefix prefix that should not be checked.
   * @param pattern regex for validation.
   */
  static createIgnorePrefixPatternValidator(prefix: string[], pattern: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      if (!value) {
        return null;
      }

      const hasPrefix = prefix.find(placeholder => value.startsWith(placeholder));
      if (!hasPrefix) {
        return new RegExp(pattern).test(value) ? null : {pattern: true};
      }

      const path = value.substring(hasPrefix.length);
      if (!path) {
        return null;
      }
      return new RegExp(pattern).test(path) ? null : {pattern: true};
    };
  }
}
