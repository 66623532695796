<c-container class="outer-container create-edit-container">
  <mat-card>
    <mat-card-header class="title-card-header">
      <mat-card-title>
        {{'personalInfo.edit.language.updateLanguage' | translate}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formGroup" class="dense-form-field-2">
        <mat-form-field appearance="fill">
          <mat-label>{{'personalInfo.edit.language.preferredLanguage' | translate}}</mat-label>
          <mat-select formControlName="preferredLanguage" required>
            <mat-option *ngFor="let language of languages" [value]="language.value">
              {{language.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{'personalInfo.edit.language.regionalFormats' | translate}}</mat-label>
          <mat-select formControlName="regionalFormats" required>
            <mat-option *ngFor="let language of languages" [value]="language.value">
              {{language.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions class="control-elements">
      <button mat-raised-button color="lancrypt-primary" (click)="onSave()" disableDuringRequest>
        {{'common.save' | translate}}
      </button>
      <button mat-raised-button (click)="onCancel()" disableDuringRequest>
        {{'common.cancel' | translate}}
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</c-container>
