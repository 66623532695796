import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LancryptDashboardComponent} from './lancrypt-dashboard.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [LancryptDashboardComponent],
  imports: [
    CommonModule,
    NgxChartsModule,
    MatGridListModule,
    MatTableModule,
    TranslateModule,
    BusyServiceModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
  ],
})
export class LancryptDashboardModule {}
