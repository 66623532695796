import {Component, OnInit} from '@angular/core';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {ToastService} from '../../../services/toaster.service';
import {SettingsService, SettingsViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-lancrypt-settings',
  templateUrl: './lancrypt-settings.component.html',
  styleUrls: ['./lancrypt-settings.component.scss'],
})
export class LancryptSettingsComponent implements OnInit {
  settingsViewDto: SettingsViewDto | undefined;
  private settingsApiClient: SettingsService;
  tenantId!: string;

  constructor(
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private translationService: TranslateService,
    private jwtHelperService: JwtHelperService
  ) {
    this.settingsApiClient = apiClientFactory.getSettingsService();
  }

  async ngOnInit() {
    this.tenantId = await this.jwtHelperService.getTenantIdFromToken();

    this.settingsApiClient.getSettings(this.tenantId).subscribe({
      next: async (n: SettingsViewDto) => {
        this.settingsViewDto = n;
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('settings.errors.loading')
        );
      },
    });
  }
}
