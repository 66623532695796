<div class="verify-email-container ">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{'createAccount.verifyEmailTitle' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'createAccount.verifyEmailSubTitle' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-card-actions>
        <div class="resend-container">
          <a cNavLink routerLinkActive="false"
             (click)="resendVerification()">{{'createAccount.resendVerification' | translate}}</a>
        </div>
      </mat-card-actions>
      <div class="mat-hint">{{'createAccount.notConfirmedAccountDeleteText' | translate}}</div>
    </mat-card-content>
  </mat-card>
</div>
