<c-container *ngIf="!(isEditingBasicInfo || isEditingTenantInfo || isEditingLanguageInfo)">
  <c-row>
    <c-col>
      <mat-card>
        <button mat-button class="edit-button" color="lancrypt-primary-light" (click)="onStartEditingBasicInfo()">{{'common.edit' | translate}}</button>
        <mat-card-title>{{'personalInfo.basicInfo' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'personalInfo.name' | translate}}</mat-card-subtitle>
        <mat-card-content>
          {{personalInfo.firstName}} {{personalInfo.lastName}}
        </mat-card-content>
        <mat-card-subtitle>{{'personalInfo.email' | translate}}</mat-card-subtitle>
        <mat-card-content>
          {{personalInfo.email}}
        </mat-card-content>
        <mat-card-content *ngIf="personalInfo.localIdpPasswordAvailable" class="change-password-button">
          <button mat-button color="lancrypt-primary" (click)="onStartChangingPassword()">{{'personalInfo.changePassword.title' | translate}}</button>
        </mat-card-content>
      </mat-card>
    </c-col>
  </c-row>
  <c-row>
    <c-col>
      <mat-card>
        <button mat-button class="edit-button" color="lancrypt-primary-light"
                (click)="onStartEditingTenantInfo()">{{'common.edit' | translate}}</button>
        <mat-card-title>{{'personalInfo.contactInfo' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'personalInfo.address' | translate}}</mat-card-subtitle>
        <mat-card-content>
          <p>
            {{personalInfo.companyName}}
            <br>
            {{personalInfo.address.street1}}
            <br>
            <ng-template [ngIf]="personalInfo.address.street2">
              {{personalInfo.address.street2}}
              <br>
            </ng-template>
            {{personalInfo.address.city}}, {{personalInfo.address.state}} {{personalInfo.address.zip}}
            <br>
            {{personalInfo.address.country}}
          </p>
        </mat-card-content>
      </mat-card>
    </c-col>
  </c-row>
  <c-row>
    <c-col>
      <mat-card>
        <button mat-button class="edit-button" color="lancrypt-primary-light"
                (click)="onStartEditingLanguageInfo()">{{'common.edit' | translate}}</button>
        <mat-card-title>{{'personalInfo.languageInfo' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'personalInfo.preferredLanguage' | translate}}</mat-card-subtitle>
        <mat-card-content>
          {{mapLanguage(personalInfo.languageInfo)}}
        </mat-card-content>
        <mat-card-subtitle>{{'personalInfo.regionalFormats' | translate}}</mat-card-subtitle>
        <mat-card-content>
          {{mapLanguage(personalInfo.regionalFormats)}}
        </mat-card-content>
      </mat-card>
    </c-col>
  </c-row>
</c-container>
<app-edit-basic-info [personalInfo]="personalInfo" (onFinished)="onFinished($event)" *ngIf="isEditingBasicInfo"></app-edit-basic-info>
<app-edit-tenant-info [personalInfo]="personalInfo" (onFinished)="onFinished($event)" *ngIf="isEditingTenantInfo"></app-edit-tenant-info>
<app-edit-language-info [personalInfo]="personalInfo" (onFinished)="onFinished($event)" *ngIf="isEditingLanguageInfo"></app-edit-language-info>
