<table mat-table matSort
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z1">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'clients.fields.id' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'clients.fields.type' | translate}}</th>
    <td mat-cell
        *matCellDef="let element"> {{'clients.systemType.' + element.operatingSystemType.toString().toLowerCase() | translate}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="clients-element-row">
  </tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell mat-cell-empty" [attr.colspan]="columnsToDisplay.length">
      {{noClientsRowTextKey | translate}}
    </td>
  </tr>
</table>
