import {Component} from '@angular/core';
import {FooterComponent} from '@coreui/angular';

@Component({
  selector: 'app-empty-footer',
  templateUrl: './empty-footer.component.html',
  styleUrls: ['./empty-footer.component.scss'],
})
export class EmptyFooterComponent extends FooterComponent {
  constructor() {
    super();
  }
}
