import {Component} from '@angular/core';
import {FooterComponent} from '@coreui/angular';

@Component({
  selector: 'app-account-footer',
  templateUrl: './account-footer.component.html',
  styleUrls: ['./account-footer.component.scss'],
})
export class AccountFooterComponent extends FooterComponent {
  constructor() {
    super();
  }
}
