import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {
  GroupSearchRequestDto,
  GroupSelectDto,
  GroupSelectPageResultDto,
  GroupService,
  GroupViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, debounceTime, distinctUntilChanged, Observable} from 'rxjs';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {scan} from 'rxjs/operators';
import {MatOptionSelectionChange} from '@angular/material/core';
import {DEFAULT_DEBOUNCE_TIME, DEFAULT_DROPDOWN_PAGE_SIZE} from '../../../../../shared/lancrypt.constants';

@Component({
  selector: 'app-group-common',
  templateUrl: './group-common.component.html',
  styleUrls: ['./group-common.component.scss'],
})
export class GroupCommonComponent implements OnInit {
  maxLengthConstraints = {
    name: 100,
    description: 254,
  };

  formGroup = this._formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.name)]],
    description: ['', [Validators.maxLength(this.maxLengthConstraints.description)]],
    groupIds: new FormControl<string[]>(['']),
  });

  parentSelected = true;

  protected groupService: GroupService;
  private tenantId: string | null = null;

  groupPageCounter = 0;
  groupPageCount = 0;

  groupObservable: Observable<GroupSelectDto[]>;
  groupOptions!: BehaviorSubject<GroupSelectDto[]>;

  groupIdNameMap: Map<string, string> = new Map<string, string>();
  groupFilterCtrl: FormControl = new FormControl();
  groupFilter = '';
  assignedGroups: string[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    protected toastService: ToastService,
    protected jwtHelperService: JwtHelperService,
    protected router: Router,
    protected translateService: TranslateService,
    apiClientFactory: ApiClientFactoryService,
    private dialog: MatDialog
  ) {
    this.groupService = apiClientFactory.getGroupService();
    this.groupObservable = this.getUpdatedGroupObservable();
  }

  ngOnInit(): void {
    const group = this.getGroup();
    if (group) {
      this.assignedGroups = group.parents.map((dto: GroupSelectDto) => dto.id);
      this.formGroup.controls.name.setValue(group.name);
      this.formGroup.controls.description.setValue(group.description ?? '');
      this.formGroup.controls.groupIds.setValue(this.assignedGroups);
    }

    this.groupFilterCtrl.valueChanges
      .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe(value => {
        if (this.groupFilter !== value) {
          this.groupFilter = value;
          this.groupPageCounter = 0;
          this.groupObservable = this.getUpdatedGroupObservable();
          this.groupIdNameMap.clear();
          if (this.getParentGroupId() && this.getParentGroupName() && this.getParentGroupName()?.includes(value)) {
            this.groupIdNameMap.set(this.getParentGroupId()!, this.getParentGroupName()!);
          }
          this.loadNextGroupBatch();
        }
      });

    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;

      if (this.getParentGroupId() && this.getParentGroupName()) {
        this.assignedGroups.push(this.getParentGroupId()!);
        this.groupIdNameMap.set(this.getParentGroupId()!, this.getParentGroupName()!);
      }
      this.loadNextGroupBatch();
    });
  }

  deleteGroup() {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('groups.deleteGroup'),
          description: this.translateService.instant('groups.deleteGroupText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.groupService.deleteGroup(this.tenantId!, this.getGroupId()!).subscribe({
            next: (_: any) => {},
            error: (_: any) => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('groups.errorDeletingGroup')
              );
            },
            complete: () => {
              this.router.navigate(['lancrypt', 'identities']);
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('groups.groupDeleted')
              );
            },
          });
        }
      });
  }

  protected buildGroupDto(): any {
    return undefined;
  }

  protected callService(_tenantId: string, _dto: any): Observable<any> {
    return new Observable<any>();
  }

  protected handleResponse(_n: any): void {}

  public getTitle(): string {
    return '';
  }

  public getErrorMessage(): string {
    return '';
  }

  public getSuccessMessage(): string {
    return '';
  }

  protected getGroupId(): string | undefined {
    return undefined;
  }

  protected getParentGroupId(): string | undefined {
    return undefined;
  }

  protected getParentGroupName(): string | undefined {
    return undefined;
  }

  protected getGroup(): GroupViewDto | undefined {
    return undefined;
  }

  protected navigateOnCompleted(): void {}

  cancel() {}

  createOrUpdateGroup() {
    this.checkParents();
    if (!this.formGroup.valid || !this.parentSelected) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const createModel = this.buildGroupDto();

    this.callService(this.tenantId as string, createModel).subscribe({
      next: n => {
        this.handleResponse(n);
      },
      error: _ => {
        this.toastService.showError(this.translateService.instant('common.error'), this.getErrorMessage());
      },
      complete: () => {
        this.navigateOnCompleted();
        this.toastService.showSuccess(this.translateService.instant('common.success'), this.getSuccessMessage());
      },
    });
  }

  private checkParents() {
    const length = this.assignedGroups.length;
    this.parentSelected = length !== undefined && length > 0;
  }

  loadNextGroupBatch() {
    const group = this.getGroup();
    const newSearchDto: GroupSearchRequestDto = {
      pageRequest: {
        pageNumber: this.groupPageCounter,
        pageSize: DEFAULT_DROPDOWN_PAGE_SIZE,
      },
      searchString: this.groupFilter,
      sortOrder: {
        descending: false,
        column: 'name',
      },
    };

    this.groupService.availableParentsForTenant(newSearchDto, this.tenantId!, group?.id).subscribe({
      next: (n: GroupSelectPageResultDto) => {
        this.formGroup.controls.groupIds.setValue(this.assignedGroups);
        this.addGroupsToMap(n.content);
        this.groupOptions.next(n.content);
        this.groupPageCount = n.totalPages;
        this.groupPageCounter += 1;
      },
    });
  }

  getAssignedGroupCountString() {
    const count = this.assignedGroups.length;
    let template = 'assets.groups.assignedMulti';
    if (count === 1) {
      template = 'assets.groups.assignedSingle';
    }
    return this.translateService.instant(template, {count: count});
  }

  private getUpdatedGroupObservable() {
    this.groupOptions = new BehaviorSubject<GroupSelectDto[]>([]);
    return this.groupOptions.asObservable().pipe(
      scan((acc, cur) => {
        if (!acc || !cur) return [];
        return [...acc, ...cur];
      })
    );
  }

  changeGroupMultiselect(event: MatOptionSelectionChange<string>) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.assignedGroups.push(event.source.value);
      } else {
        const index = this.assignedGroups.indexOf(event.source.value);
        if (index > -1) {
          this.assignedGroups.splice(index, 1);
        }
      }
    }
  }

  private addGroupsToMap(groups: GroupSelectDto[]) {
    for (const group of groups) {
      this.groupIdNameMap.set(group.id, group.name);
    }
  }
}
