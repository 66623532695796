import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonalInfoComponent} from './personal-info.component';
import {GridModule} from '@coreui/angular';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {EditPersonalInfoModule} from '../edit-personal-info/edit-personal-info.module';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [PersonalInfoComponent],
  imports: [CommonModule, GridModule, MatCardModule, TranslateModule, EditPersonalInfoModule, MatButtonModule],
})
export class PersonalInfoModule {}
