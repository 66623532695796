import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LancryptConnectionsComponent} from './lancrypt-connections.component';
import {ConnectionCreateComponent} from './components/connection-create/connection-create.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FormModule, GridModule} from '@coreui/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BusyServiceModule} from '../../../shared/components/busy-service/busy-service.module';
import {MatDividerModule} from '@angular/material/divider';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ConnectionUpdateComponent} from './components/connection-update/connection-update.component';
import {ConnectionCommonComponent} from './components/connection-common/connection-common.component';
import {ConnectionImportComponent} from './components/connection-import/connection-import.component';
import {MatTreeModule} from '@angular/material/tree';
import {ConnectionImportJobsComponent} from './components/connection-import-jobs/connection-import-jobs.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatOptionModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StorePaginationPageSizeDirective} from 'src/app/shared/store-pagination-page-size-directive';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    LancryptConnectionsComponent,
    ConnectionCreateComponent,
    ConnectionUpdateComponent,
    ConnectionCommonComponent,
    ConnectionImportComponent,
    ConnectionImportJobsComponent,
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    GridModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    BusyServiceModule,
    MatDividerModule,
    AppRoutingModule,
    MatTableModule,
    MatCheckboxModule,
    MatTreeModule,
    MatPaginatorModule,
    MatRadioModule,
    MatOptionModule,
    MatTooltipModule,
    StorePaginationPageSizeDirective,
    InfiniteScrollDirective,
  ],
})
export class LancryptConnectionsModule {}
