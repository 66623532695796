import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {BehaviorSubject, debounceTime, distinctUntilChanged, Observable} from 'rxjs';
import {
  FieldErrorDto,
  GroupSearchRequestDto,
  GroupSelectDto,
  GroupSelectPageResultDto,
  GroupService,
  UserService,
  UserViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_DROPDOWN_PAGE_SIZE,
  FIELD_LENGTH_CONSTRAINTS,
} from '../../../../../shared/lancrypt.constants';
import {MatDialog} from '@angular/material/dialog';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {CustomValidators} from '../../../../../shared/components/validators/custom-validators';
import {IdentityProviderTranslator} from '../../../../../shared/identity-provider-translator';
import {MatOptionSelectionChange} from '@angular/material/core';
import {scan} from 'rxjs/operators';

@Component({
  selector: 'app-user-common',
  templateUrl: './user-common.component.html',
  styleUrls: ['./user-common.component.scss'],
})
export class UserCommonComponent implements OnInit {
  idpEnum = UserViewDto.IdentityProviderEnum;

  maxLengthConstraints = {
    firstName: FIELD_LENGTH_CONSTRAINTS.firstName,
    lastName: FIELD_LENGTH_CONSTRAINTS.lastName,
    description: FIELD_LENGTH_CONSTRAINTS.description,
    emailAddress: FIELD_LENGTH_CONSTRAINTS.email,
    identityProvider: FIELD_LENGTH_CONSTRAINTS.email,
  };

  identityProviderHint = '';

  protected userService: UserService;
  protected groupService: GroupService;
  private tenantId: string | null = null;

  inEditMode = false;
  canDelete = false;
  editUserId: string | undefined = undefined;
  formGroup;

  emailDomainValid = true;

  groupPageCounter = 0;
  groupPageCount = 0;

  groupObservable: Observable<GroupSelectDto[]>;
  groupOptions!: BehaviorSubject<GroupSelectDto[]>;

  groupIdNameMap: Map<string, string> = new Map<string, string>();
  groupFilterCtrl: FormControl = new FormControl();
  groupFilter = '';
  assignedGroups: string[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    protected toastService: ToastService,
    protected jwtHelperService: JwtHelperService,
    protected router: Router,
    protected translateService: TranslateService,
    apiClientFactory: ApiClientFactoryService,
    private dialog: MatDialog
  ) {
    this.userService = apiClientFactory.getUserService();
    this.groupService = apiClientFactory.getGroupService();
    this.formGroup = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.firstName)]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.lastName)]],
      description: ['', [Validators.maxLength(this.maxLengthConstraints.description)]],
      emailAddress: [
        '',
        [
          Validators.required,
          this.emailDomainValidValidator.bind(this),
          Validators.maxLength(this.maxLengthConstraints.emailAddress),
          Validators.email,
        ],
      ],
      identityProvider: [
        UserViewDto.IdentityProviderEnum.LOCAL,
        [Validators.maxLength(this.maxLengthConstraints.identityProvider)],
      ],
      groupIds: new FormControl<string[]>(['']),
    });

    this.groupObservable = this.getUpdatedGroupObservable();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;

      const user = this.getUser();
      if (user !== undefined) {
        this.inEditMode = true;
        this.canDelete = true;
        this.editUserId = user.id;
        this.formGroup.controls.firstName.setValue(user!.firstName);
        this.formGroup.controls.lastName.setValue(user!.lastName);
        this.formGroup.controls.description.setValue(user!.description ?? '');
        this.formGroup.controls.emailAddress.setValue(user!.emailAddress);
        this.formGroup.controls.identityProvider.setValue(user!.identityProvider);
        this.updateIdentityProviderHint(user!.identityProvider);
        this.formGroup.controls.emailAddress.addAsyncValidators([
          CustomValidators.createEmailValidatorWithInitValue(user!.emailAddress, false, this.userService),
        ]);
        this.assignedGroups = user.groups.filter(group => !group.root).map(group => group.id);
      } else {
        this.formGroup.controls.emailAddress.addAsyncValidators([
          CustomValidators.createEmailValidator(false, this.userService),
        ]);
        this.formGroup.controls.identityProvider.setValue(UserViewDto.IdentityProviderEnum.LOCAL);
        this.updateIdentityProviderHint(UserViewDto.IdentityProviderEnum.LOCAL);
        this.inEditMode = false;
        this.canDelete = false;
      }

      this.loadNextGroupBatch();

      this.groupFilterCtrl.valueChanges
        .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged())
        .subscribe(value => {
          if (this.groupFilter !== value) {
            this.groupFilter = value;
            this.groupPageCounter = 0;
            this.groupObservable = this.getUpdatedGroupObservable();
            this.groupIdNameMap.clear();
            this.loadNextGroupBatch();
          }
        });
    });
  }

  private getUpdatedGroupObservable() {
    this.groupOptions = new BehaviorSubject<GroupSelectDto[]>([]);
    return this.groupOptions.asObservable().pipe(
      scan((acc, cur) => {
        if (!acc || !cur) return [];
        return [...acc, ...cur];
      })
    );
  }

  private addGroupsToMap(groups: GroupSelectDto[]) {
    for (const group of groups) {
      this.groupIdNameMap.set(group.id, group.name);
    }
  }

  loadNextGroupBatch() {
    const newSearchDto: GroupSearchRequestDto = {
      pageRequest: {
        pageNumber: this.groupPageCounter,
        pageSize: DEFAULT_DROPDOWN_PAGE_SIZE,
      },
      searchString: this.groupFilter,
      sortOrder: {
        descending: false,
        column: 'name',
      },
    };

    if (this.editUserId) {
      this.groupService
        .availableGroupsForTenantNotSyncedAssignedGroupsFirst(newSearchDto, this.editUserId, this.tenantId!)
        .subscribe({
          next: (n: GroupSelectPageResultDto) => {
            this.formGroup.controls.groupIds.setValue(this.assignedGroups);
            this.addGroupsToMap(n.content);
            this.groupOptions.next(n.content);
            this.groupPageCount = n.totalPages;
            this.groupPageCounter += 1;
          },
        });
    } else {
      this.groupService.availableGroupsForTenantNotSynced(newSearchDto, this.tenantId!).subscribe({
        next: (n: GroupSelectPageResultDto) => {
          this.formGroup.controls.groupIds.setValue(this.assignedGroups);
          this.addGroupsToMap(n.content);
          this.groupOptions.next(n.content);
          this.groupPageCount = n.totalPages;
          this.groupPageCounter += 1;
        },
      });
    }
  }

  getAssignedGroupCountString() {
    const count = this.assignedGroups.length;
    let template = 'assets.groups.assignedMulti';
    if (count === 1) {
      template = 'assets.groups.assignedSingle';
    }
    return this.translateService.instant(template, {count: count});
  }

  changeGroupMultiselect(event: MatOptionSelectionChange<string>) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.assignedGroups.push(event.source.value);
      } else {
        const index = this.assignedGroups.indexOf(event.source.value);
        if (index > -1) {
          this.assignedGroups.splice(index, 1);
        }
      }
    }
  }

  getIdentityProviderName(idp: any): string {
    return IdentityProviderTranslator.getIdentityProviderName(this.translateService, idp);
  }

  updateIdentityProviderHint(idp: any) {
    this.identityProviderHint = IdentityProviderTranslator.getIdentityProviderHint(this.translateService, idp);
  }

  deleteUser() {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('users.deleteUser'),
          description: this.translateService.instant('users.deleteUserText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.userService.deleteUser(this.tenantId!, this.editUserId!).subscribe({
            next: () => {},
            error: () => {
              this.toastService.showError(
                this.translateService.instant('common.error'),
                this.translateService.instant('users.errorDeletingUser')
              );
            },
            complete: () => {
              this.router.navigate(['lancrypt', 'identities']);
              this.toastService.showSuccess(
                this.translateService.instant('common.success'),
                this.translateService.instant('users.userDeleted')
              );
            },
          });
        }
      });
  }

  protected buildUserDto(): any {
    return undefined;
  }

  protected callService(_tenantId: string, _dto: any): Observable<any> {
    return new Observable<any>();
  }

  public getTitle(): string {
    return '';
  }

  public getSubTitle(): string {
    return '';
  }

  public getErrorMessage(): string {
    return '';
  }

  public getSuccessMessage(): string {
    return '';
  }

  public getEmailHint(): string {
    return this.translateService.instant('users.emailAddressHintUpdate');
  }

  protected getUser(): UserViewDto | undefined {
    return undefined;
  }

  protected navigateOnCompleted(): void {}

  cancel() {
    this.navigateOnCompleted();
  }

  onEmailAddressChange(_: Event): void {
    this.emailDomainValid = true;
    this.formGroup.controls.emailAddress.updateValueAndValidity();
  }

  private emailDomainValidValidator(_: AbstractControl): ValidationErrors | null {
    return this?.emailDomainValid ? null : {notValid: true};
  }

  createOrUpdateUser() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const createModel = this.buildUserDto();

    this.callService(this.tenantId as string, createModel).subscribe({
      next: _ => {},
      error: async (e: HttpErrorResponse) => {
        const errors = e.error.errors;
        if (errors !== undefined) {
          errors.forEach((x: FieldErrorDto) => {
            if (x.field === 'email') {
              this.emailDomainValid = false;
              this.formGroup.controls.emailAddress.updateValueAndValidity();
            }
          });
        }

        this.toastService.showError(this.translateService.instant('common.error'), this.getErrorMessage());
      },
      complete: () => {
        this.navigateOnCompleted();
        this.toastService.showSuccess(this.translateService.instant('common.success'), this.getSuccessMessage());
      },
    });
  }
}
