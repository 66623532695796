import {Component, OnInit} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {GroupService, GroupTreeViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {ToastService} from 'src/app/services/toaster.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {GroupTreeViewFlatNode} from 'src/app/dtos/lancrypt/GroupTreeViewFlatNode';
import {GroupTreeViewIconDto, SubFolderType} from 'src/app/dtos/lancrypt/GroupTreeViewIconDto';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GroupTreeService, GroupTreeTrait} from '../../../services/group-tree.service';

@Component({
  selector: 'app-lancrypt-identities',
  templateUrl: './lancrypt-identities.component.html',
  styleUrls: ['./lancrypt-identities.component.scss'],
})
export class LancryptIdentitiesComponent implements OnInit {
  private groupApiClient: GroupService;

  subtypes = SubFolderType;

  showAssets = false;
  showMembers = false;
  showGroupView = false;

  rootName = '';
  foundGroups = '';
  foundGroupsIdx = 0;

  private _transformer = (node: GroupTreeViewIconDto, level: number): GroupTreeViewFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      subType: node.subType,
      groupName: node.groupName,
      childrenAmount: node.children.length,
      synced: node.syncedGroup,
      identityProviderConnectionId: node.identityProviderConnectionId,
    };
  };

  treeControl = new FlatTreeControl<GroupTreeViewFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: GroupTreeViewFlatNode) => node.expandable;

  activeNode: GroupTreeViewFlatNode | undefined;

  SubFolderType = SubFolderType;

  constructor(
    private translationService: TranslateService,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private jwtHelperService: JwtHelperService,
    private groupTreeService: GroupTreeService
  ) {
    this.groupApiClient = this.apiClientFactory.getGroupService();
  }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.groupApiClient.getGroupTreeByTenantId(tenantId).subscribe({
      next: async (n: Array<GroupTreeViewDto>) => {
        if (!n) {
          this.toastService.showInfo(
            this.translationService.instant('common.info'),
            this.translationService.instant('infos.nogroupsFoundForTenant')
          );
          return;
        }

        const tree = await this.groupTreeService.buildTreeView(n, GroupTreeTrait.All);

        // Add node for importing groups and users
        const importFolder: GroupTreeViewIconDto = {
          id: '',
          name: this.translationService.instant('buttons.importUsers'),
          children: [],
          icon: 'add_circle_outline',
          subType: SubFolderType.LoadMoreUsers,
          groupName: '',
          parents: [],
          description: '',
          treeHierarchyDirection: 'ANCESTORS',
          root: false,
          syncedGroup: false,
          identityProviderConnectionId: '',
        };
        tree.push(importFolder);

        // Set tree as datasource for tree control
        this.dataSource.data = tree;

        // Expand all root nodes
        this.treeControl.dataNodes.forEach(node => {
          if (node.level === 0) {
            this.treeControl.expand(node);
          }
        });

        this.moveToNodeBasedOnParams();
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('errors.gettingGroups')
        );
      },
      complete: () => {},
    });
  }

  moveToNodeBasedOnParams() {
    if (this.route.snapshot.url.pop()!.path === 'users') {
      const usersNode = this.treeControl.dataNodes.find(x => x.subType === SubFolderType.AllUsers);

      if (usersNode) {
        this.nodeSelected(usersNode);
      } else {
        this.nodeSelected(this.treeControl.dataNodes[0]);
      }
    } else {
      this.route.params.subscribe(params => {
        const groupId = params['groupId']?.toLowerCase();
        const subfolder = parseInt(params['subfolder']);

        const node = this.treeControl.dataNodes.find(x => x.id === groupId && x.subType === subfolder);
        const groupNode = this.treeControl.dataNodes.find(x => x.id === groupId && x.subType === SubFolderType.Group);

        if (groupNode) {
          this.treeControl.expand(groupNode);
          this.nodeSelected(node ? node : groupNode);

          // It is a sub-group, therefore we need to expand the nodes above as well
          if (groupNode.level > 0) {
            this.expandParents(groupNode);
          }
        } else {
          // Select root group
          this.nodeSelected(this.treeControl.dataNodes[0]);
        }
      });
    }
  }

  expandParents(node: GroupTreeViewFlatNode) {
    const parent = this.getParent(node);
    if (parent) this.treeControl.expand(parent);

    if (parent && parent.level > 0) {
      this.expandParents(parent);
    }
  }

  getParent(node: GroupTreeViewFlatNode): GroupTreeViewFlatNode | null {
    const currentLevel = this.treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  nodeSelected(node: GroupTreeViewFlatNode) {
    this.activeNode = node;

    // get root for selected node
    let root: GroupTreeViewFlatNode | null = this.activeNode;
    while (root && root.level !== 0) {
      root = this.getParent(root);
    }
    this.rootName = root?.name ?? '';

    // Show data selected for sub folder type and update URL in browser.
    switch (node.subType) {
      case SubFolderType.Assets:
        this.location.go('/lancrypt/identities/group/' + node.id + '/' + SubFolderType.Assets);
        this.showAssets = true;
        this.showMembers = false;
        this.showGroupView = false;
        break;
      case SubFolderType.Group:
        this.location.go('/lancrypt/identities/group/' + node.id + '/' + SubFolderType.Group);
        this.showAssets = false;
        this.showMembers = false;
        this.showGroupView = true;
        break;
      case SubFolderType.Users:
        this.location.go('/lancrypt/identities/group/' + node.id + '/' + SubFolderType.Users);
        this.showAssets = false;
        this.showMembers = true;
        this.showGroupView = false;
        break;
      case SubFolderType.AllUsers:
        this.location.go('/lancrypt/identities/users');
        this.showAssets = false;
        this.showMembers = true;
        this.showGroupView = false;
        break;
      case SubFolderType.Members:
        this.location.go('/lancrypt/identities/group/' + node.id + '/' + SubFolderType.Members);
        this.showAssets = false;
        this.showMembers = true;
        this.showGroupView = false;
        break;
      case SubFolderType.LoadMoreUsers:
        // Navigate to create connection view
        this.router.navigate(['lancrypt', 'connections', 'create-connection']);
        break;
      case SubFolderType.Undefined:
      default:
        this.showAssets = false;
        this.showMembers = false;
        this.showGroupView = false;
        break;
    }
  }

  async groupDeleted() {
    await this.load();
  }

  jumpToNode(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue === '') {
      this.nodeSelected(this.treeControl.dataNodes[0]);
      this.foundGroupsIdx = 0;
      this.foundGroups = '';

      return;
    }

    const nodes = this.treeControl.dataNodes.filter(
      node => node.childrenAmount !== 0 && node.name.toLowerCase().includes(filterValue.toLowerCase())
    );

    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (this.foundGroupsIdx < nodes.length - 1) {
        this.foundGroupsIdx++;
      } else {
        this.foundGroupsIdx = 0;
      }
    }

    this.foundGroups = `${nodes.length === 0 ? 0 : this.foundGroupsIdx + 1}/${nodes.length}`;

    if (nodes.length > 0) {
      this.nodeSelected(nodes[this.foundGroupsIdx]);
      this.expandParents(nodes[this.foundGroupsIdx]);
    }
  }

  isActiveNode = (node: GroupTreeViewFlatNode) =>
    node.id === this.activeNode?.id && node.subType === this.activeNode.subType;
}
