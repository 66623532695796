import {Component} from '@angular/core';

import {HeaderComponent} from '@coreui/angular';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {LogoutService} from '../../../services/auth/logout.service';

@Component({
  selector: 'app-empty-header',
  templateUrl: './empty-header.component.html',
  styleUrls: ['./empty-header.component.scss'],
})
export class EmptyHeaderComponent extends HeaderComponent {
  constructor(
    private jwtHelperService: JwtHelperService,
    private logoutService: LogoutService
  ) {
    super();
  }

  home() {
    this.jwtHelperService
      .getUserDataFromToken()
      .then(_ => {})
      .catch(_ => this.logoutService.logout());
  }
}
