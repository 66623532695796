<div class="dashboard-container">
  <div class="headline-container">
    <h1>{{'headlines.dashboard' | translate}}</h1>
  </div>

  <mat-divider class="headline-divider"></mat-divider>
  <mat-grid-list [cols]="breakpoint" gutterSize="16px" rowHeight="500px" (window:resize)="onResize($event)">
    <!--  pie charts  -->
    <mat-grid-tile colspan="1">
      <div class="os-pie-chart">
        <h2 class="dashboard-tile-header">{{osTitle | translate}}</h2>
        <button mat-mini-fab class="back-button" (click)="returnFromDrillDownOperatingSystems()"
                *ngIf="osDrilledDown" disableDuringRequest>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <ngx-charts-pie-chart *ngIf="this.operatingSystemsData !== undefined && this.operatingSystemsData.length !== 0"
          [view]="view"
          [scheme]="'cool'"
          [results]="operatingSystems"
          [labels]="true"
          [maxLabelLength]="50"
          [labelFormatting]="labelFormatter"
          [trimLabels] = "false"
          [scheme] = "this.pieColors"
          (select)="onSelectOperatingSystems($event)">
        </ngx-charts-pie-chart>
        <div class="data-missing" *ngIf="this.operatingSystemsData !== undefined && this.operatingSystemsData.length === 0">
          <div>{{'dashboard.noOperatingSystemDataExist' | translate}}</div>
        </div>
        <div class="data-missing" *ngIf="this.operatingSystemsData === undefined">
          <div>{{'dashboard.loading' | translate}}</div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <div class="products-pie-chart">
        <h2 class="dashboard-tile-header">{{productsTitle | translate}}</h2>
        <button mat-mini-fab class="back-button" (click)="returnFromDrillDownProducts()"
                *ngIf="productsDrillInLevel > 0" disableDuringRequest>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <ngx-charts-pie-chart *ngIf="this.productsData !== undefined && this.productsData.length !== 0"
          [view]="view"
          [scheme]="'cool'"
          [results]="products"
          [labels]="true"
          [maxLabelLength]="50"
          [labelFormatting]="labelFormatter"
          [trimLabels] = "false"
          [scheme] = "this.pieColors"
          (select)="onSelectProducts($event)">
        </ngx-charts-pie-chart>
        <div class="data-missing" *ngIf="this.productsData !== undefined && this.productsData.length === 0">
          <div>{{'dashboard.noInstalledProductsExist' | translate}}</div>
        </div>
        <div class="data-missing" *ngIf="this.productsData === undefined">
          <div>{{'dashboard.loading' | translate}}</div>
        </div>
      </div>
    </mat-grid-tile>

    <!--  tables  -->
    <mat-grid-tile>
      <div class="last-fetched-tables">
        <h2 class="dashboard-tile-header">{{'dashboard.lastFetched.title' | translate}}</h2>

        <h3 class="dashboard-tile-sub-header">{{'dashboard.lastFetched.clients' | translate}}</h3>
        <table mat-table class="grid-sub-tile" [dataSource]="clientsLastFetched">
          <!--    Last 24 hours    -->
          <ng-container matColumnDef="last24Hours">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.last24Hours' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.last24HoursCount}} </td>
          </ng-container>

          <!--    Last week    -->
          <ng-container matColumnDef="lastWeek">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.lastWeek' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.lastWeekCount}} </td>
          </ng-container>

          <!--    Older than a week    -->
          <ng-container matColumnDef="olderThanAWeek">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.olderThanAWeek' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.olderThanAWeekCount}} </td>
          </ng-container>

          <!--    Never    -->
          <ng-container matColumnDef="never">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.never' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.neverCount}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell mat-cell-empty" [attr.colspan]="displayedColumns.length">
              {{'dashboard.noClientsExist' | translate}}
            </td>
          </tr>
        </table>

        <h3 class="dashboard-tile-sub-header">{{'dashboard.lastFetched.users' | translate}}</h3>
        <table mat-table class="grid-sub-tile" [dataSource]="usersLastFetched">
          <!--    Last 24 hours    -->
          <ng-container matColumnDef="last24Hours">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.last24Hours' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.last24HoursCount}} </td>
          </ng-container>

          <!--    Last week    -->
          <ng-container matColumnDef="lastWeek">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.lastWeek' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.lastWeekCount}} </td>
          </ng-container>

          <!--    Older than a week    -->
          <ng-container matColumnDef="olderThanAWeek">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.olderThanAWeek' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.olderThanAWeekCount}} </td>
          </ng-container>

          <!--    Never    -->
          <ng-container matColumnDef="never">
            <th mat-header-cell *matHeaderCellDef>{{'dashboard.lastFetched.never' | translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.neverCount}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell mat-cell-empty" [attr.colspan]="displayedColumns.length">
              {{'dashboard.noUsersExist' | translate}}
            </td>
          </tr>
        </table>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
