import {Component} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {AdministratorCommonComponent} from '../administrator-common/administrator-common.component';
import {ToastService} from '../../../../../services/toaster.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {UserDataService} from '../../../../../services/user-data.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslationHelperService} from '../../../../../services/helper/translation-helper.service';
import {ConpalUserUpdateDto, ConpalUserViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

@Component({
  selector: 'app-administrator-update',
  templateUrl: '../administrator-common/administrator-common.component.html',
  styleUrl: '../administrator-common/administrator-common.component.scss',
})
export class AdministratorUpdateComponent extends AdministratorCommonComponent {
  administratorId?: string;
  administrator?: ConpalUserViewDto;

  constructor(
    formBuilder: FormBuilder,
    apiClientFactory: ApiClientFactoryService,
    jwtHelperService: JwtHelperService,
    userDataService: UserDataService,
    toastService: ToastService,
    translateService: TranslateService,
    translationHelperService: TranslationHelperService,
    router: Router,
    dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super(
      formBuilder,
      apiClientFactory,
      jwtHelperService,
      userDataService,
      toastService,
      translateService,
      translationHelperService,
      router,
      dialog
    );
  }

  override ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.administratorId = params['administratorId'];
      const userData = this.userDataService.userData.value;
      this.userService.getConpalUser(userData.tenantId, this.administratorId!).subscribe({
        next: (n: ConpalUserViewDto) => {
          this.administrator = n;
          super.ngOnInit();

          // Decision: An administrator is not allowed to change their own email address
          //           Wait for keycloak's update email feature to become final.
          if (this.administrator.id === userData.id) {
            this.formGroup.controls.emailAddress.disable();
          }
        },
        error: () => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('administrators.error.failedLoadingAdministrator')
          );
        },
        complete: () => {},
      });
    });
  }

  public override getTitle(): string {
    return this.translateService.instant('administrators.editAdministratorTitle');
  }

  public override getSubTitle(): string {
    return this.translateService.instant('administrators.editAdministratorSubtitle');
  }

  public override getErrorMessage(): string {
    return this.translateService.instant('administrators.error.errorUpdatingAdministrator');
  }

  public override getSuccessMessage(): string {
    return this.translateService.instant('administrators.administratorUpdated');
  }

  public override getEmailHint(): string {
    const userData = this.userDataService.userData.value;

    if (userData.id === this.administratorId) {
      return this.translateService.instant('administrators.emailAddressHintUpdateSelf');
    }

    return this.translateService.instant('administrators.emailAddressHintUpdate');
  }

  protected override getAdministrator(): ConpalUserViewDto | undefined {
    return this.administrator;
  }

  protected override buildAdministratorDto(): ConpalUserUpdateDto {
    return {
      id: this.administratorId!,
      firstName: this.formGroup.controls.firstName.value === null ? '' : this.formGroup.controls.firstName.value,
      lastName: this.formGroup.controls.lastName.value === null ? '' : this.formGroup.controls.lastName.value,
      emailAddress:
        this.formGroup.controls.emailAddress.value === null ? '' : this.formGroup.controls.emailAddress.value,
      mobilePhoneNumber:
        this.formGroup.controls.mobilePhoneNumber.value === null ? '' : this.formGroup.controls.mobilePhoneNumber.value,
      businessPhoneNumber:
        this.formGroup.controls.businessPhoneNumber.value === null
          ? ''
          : this.formGroup.controls.businessPhoneNumber.value,
    };
  }

  protected override callService(tenantId: string, dto: any): Observable<any> {
    return this.userService.updateConpalUser(dto, tenantId);
  }

  protected override navigateOnCompleted(): void {
    this.router.navigate(['account', 'administrators']);
  }
}
