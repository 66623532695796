import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {FeatureFlagService} from '../services/feature-flag.service';

@Directive({
  selector: '[removeIfFeatureIsOff]',
  standalone: true,
})
export class RemoveIfFeatureIsOffDirective implements OnInit {
  @Input('removeIfFeatureIsOff') featureName: string;

  constructor(
    private el: ElementRef,
    private featureFlagService: FeatureFlagService
  ) {
    this.featureName = '';
  }

  ngOnInit() {
    if (!this.featureFlagService.isFeatureEnabled(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
