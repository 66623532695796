import {Component, OnInit} from '@angular/core';
import {
  ClientDownloadVersionInfoDto,
  DownloadFileResponseDto,
  FileService,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  private fileService: FileService;
  clientTypes = ClientDownloadVersionInfoDto.FileTypeEnum;

  currentClientVersions?: ClientDownloadVersionInfoDto[];
  constructor(
    private apiClientFactoryService: ApiClientFactoryService,
    private toastService: ToastService,
    private translationService: TranslateService
  ) {
    this.fileService = apiClientFactoryService.getFileService();
  }

  ngOnInit(): void {
    this.fileService.getClientDownloadVersions().subscribe({
      next: (versions: ClientDownloadVersionInfoDto[]) => {
        this.currentClientVersions = versions;
      },
      error: async () => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('clientsComponent.errors.failedToGetVersions')
        );
      },
    });
  }

  downloadFile(filetype: string) {
    this.fileService.downloadFile(filetype).subscribe({
      next: (n: DownloadFileResponseDto) => {
        window.open(n.downloadLink);
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('clientsComponent.errors.downloadFailed')
        );
      },
    });
  }

  versionInfoForClientType(type: ClientDownloadVersionInfoDto.FileTypeEnum): ClientDownloadVersionInfoDto | undefined {
    return this.currentClientVersions?.find(e => e.fileType === type);
  }
}
