import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable()
export class LancryptTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private translateService: TranslateService
  ) {
    super();
  }

  updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      firstValueFrom(this.translateService.get(title)).then(translatedTitle => {
        this.title.setTitle(`${translatedTitle} | ` + environment.branding.titlePostfix);
      });
    }
  }
}
