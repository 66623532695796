import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {CardModule, FormModule, GridModule} from '@coreui/angular';
import {TranslateModule} from '@ngx-translate/core';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {UserActivateAccountComponent} from './user-activate-account/user-activate-account.component';
import {MatStepperModule} from '@angular/material/stepper';
import {CreateAccountComponent} from './create-account/create-account.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component';
import {ClientLoginSuccessComponent} from './client-login-success/client-login-success.component';
import {BusyServiceModule} from '../../shared/components/busy-service/busy-service.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    UserActivateAccountComponent,
    CreateAccountComponent,
    VerifyEmailComponent,
    ClientLoginSuccessComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    CardModule,
    GridModule,
    TranslateModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    MatCheckboxModule,
    MatCardModule,
    RouterModule,
    MatStepperModule,
    BusyServiceModule,
    MatProgressBarModule,
  ],
})
export class PublicModule {}
