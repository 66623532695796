import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ClientInfoViewDto, ClientService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';

@Component({
  selector: 'app-clients-table-common',
  templateUrl: './clients-table-common.component.html',
  styleUrls: ['./clients-table-common.component.scss'],
})
export class ClientsTableCommonComponent implements AfterViewInit {
  clientService: ClientService;

  @ViewChild(MatSort) sort?: MatSort;

  dataSource: MatTableDataSource<ClientElement>;
  columnsToDisplay = ['id', 'type'];
  readonly removalAllowed: boolean = false;
  readonly noClientsRowTextKey: string = 'clients.noClients';

  constructor(
    protected _jwtHelper: JwtHelperService,
    protected _apiClientFactory: ApiClientFactoryService,
    protected translationService: TranslateService,
    protected toastService: ToastService
  ) {
    this.clientService = _apiClientFactory.getClientService();

    this.dataSource = new MatTableDataSource<ClientElement>();
    this.setupSortingProperties();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort!;
  }

  removeAssignment(_element: ClientElement) {}

  protected mapClientElements(dtos: ClientInfoViewDto[]): ClientElement[] {
    return dtos.map((d: ClientInfoViewDto) => ({
      id: d.clientId,
      operatingSystemType: d.operatingSystemType,
    }));
  }

  private setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'type':
          return data.operatingSystemType.toLowerCase();
        default:
          return (data as any)[sortHeaderId].toLowerCase();
      }
    };
  }
}

export interface ClientElement {
  id: string;
  operatingSystemType: string;
}
