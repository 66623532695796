import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../../../services/toaster.service';
import {MatDialog} from '@angular/material/dialog';
import {AssetService, AssetViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-assets-table-common',
  templateUrl: './assets-table-common.component.html',
  styleUrls: ['./assets-table-common.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AssetsTableCommonComponent implements AfterViewInit {
  assetService: AssetService;

  @ViewChild(MatPaginator)
  paginator: MatPaginator | undefined;

  @ViewChild(MatSort) sort?: MatSort;

  dataSource: MatTableDataSource<AssetElement>;
  columnsToDisplay = ['name', 'access', 'inherited'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: AssetElement | undefined;
  readonly noAssetsRowTextKey: string = 'treeview.noAssets';

  constructor(
    protected route: ActivatedRoute,
    protected _jwtHelper: JwtHelperService,
    protected _apiClientFactory: ApiClientFactoryService,
    protected translationService: TranslateService,
    protected toastService: ToastService,
    public dialog: MatDialog
  ) {
    this.assetService = _apiClientFactory.getAssetService();

    this.dataSource = new MatTableDataSource<AssetElement>();
    this.setupSortingProperties();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }

  canRemoveAssignment(_element: AssetElement): boolean {
    return false;
  }

  removeAssignment(_element: AssetElement) {}

  protected mapAssetElements(dtos: AssetViewDto[]): AssetElement[] {
    return dtos.map((d: AssetViewDto) => ({
      id: d.id,
      name: d.name,
      location: d.location,
      type: d.type,
      access: d.assetAccess,
      inherited: d.assetAccess === AssetViewDto.AssetAccessEnum.INHERITED,
      rootGroupName: d.rootGroup?.name,
      numberOfGrantAccessGroups: d.grantAccessGroups.length,
    }));
  }

  private setupSortingProperties() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'name':
          return data.name.toLowerCase();
        case 'access':
          if (data.access !== null) {
            return this.translationService
              .instant('treeview.assetAccess.' + data.access.toString().toLowerCase())
              .toLowerCase();
          }
          return this.translationService.instant('treeview.assetAccess.personal').toLowerCase();
        default:
          return (data as any)[sortHeaderId];
      }
    };
  }
}

export interface AssetElement {
  id: string;
  name: string;
  location: string;
  type: AssetViewDto.TypeEnum;
  access: AssetViewDto.AssetAccessEnum;
  rootGroupName: string;
  numberOfGrantAccessGroups: number;
  inherited: boolean;
}
