<div class="members-view-container">
  <div class="members-view-header">
    <div class="side-view-header">
      <h2>
        {{
          (activeNode?.subType === SubFolderType.Members
            ? 'headlines.members'
            : activeNode?.subType === SubFolderType.Users
              ? 'headlines.users'
              : 'headlines.allUsers'
          ) | translate
        }}
      </h2>
      <p class="subheadline">
        {{
          activeNode?.subType === SubFolderType.AllUsers
            ? ('subheadline.usersAreMemberOfOrganization' | translate)
            : ('subheadline.usersAreMemberOf' | translate: {name: activeNode?.groupName})
        }}
      </p>
    </div>
    <div class="button-container">
      <div *ngIf="allowEditMemberAssignment()" class="control-elements">
        <button
          matTooltip="{{ 'buttons.assignMembers' | translate }}"
          mat-mini-fab
          color="lancrypt-primary"
          [routerLink]="['/lancrypt/identities/members/assign/group', activeNode?.id]"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div *ngIf="activeNode?.subType === SubFolderType.Users && !activeNode?.synced" class="control-elements">
        <button
          matTooltip="{{ 'buttons.newUser' | translate }}"
          mat-mini-fab
          color="lancrypt-primary"
          (click)="newUser()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="members-view-body dense-form-field-2">
    <mat-form-field>
      <mat-label>{{ 'users.filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'users.search' | translate }}" />
    </mat-form-field>
    <mat-card>
      <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="column === 'rootGroup' || column === 'lastActivity'"
          >
            {{ 'treeview.fields.' + column | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="column !== 'status' && column !== 'lastActivity'">{{ element[column] }}</span>
            <span *ngIf="column === 'lastActivity'">
              {{
                element[column] !== undefined ? (element[column] | date: 'short') : ('users.details.never' | translate)
              }}
            </span>
            <div *ngIf="column === 'status'" class="status-cell">
              <button
                mat-icon-button
                *ngIf="column === 'status' && element.status === StatusEnum.CREATED"
                (click)="resendActivation(element.id); $event.stopPropagation()"
                disableDuringRequest
                matTooltip="{{ 'users.verificationPending' | translate }}"
              >
                <mat-icon title="{{ 'users.verificationPending' | translate }}" class="material-icons-outlined"
                  >update
                </mat-icon>
              </button>
              <mat-icon
                *ngIf="column === 'status' && element.status === StatusEnum.CONFIRMED"
                matTooltip="{{ 'users.verificationCompleted' | translate }}"
                title="{{ 'users.verificationCompleted' | translate }}"
                class="material-icons-outlined"
                >check
              </mat-icon>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
            >
              <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="showUserDetails(element)"
              matTooltip="{{ 'buttons.userDetails' | translate }}"
            >
              <mat-icon title="{{ 'users.showDetails' | translate }}" class="material-symbols-outlined"
                >arrow_forward
              </mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="showEditUser(element)"
              (click)="editUser(element)"
              matTooltip="{{ 'buttons.editUser' | translate }}"
            >
              <mat-icon title="{{ 'common.edit' | translate }}">edit</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="remove member"
              *ngIf="allowEditMemberAssignment()"
              (click)="removeAssignment(element); $event.stopPropagation()"
              disableDuringRequest
              matTooltip="{{ 'buttons.unassignMember' | translate }}"
            >
              <mat-icon>close</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="element-description">
                {{ element.emailAddress }}
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplayWithExpand"
          class="element-row"
          [class.expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell mat-cell-empty" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div *onlyShowWhenIdle>
              {{ (activeNode?.subType === SubFolderType.Members ? 'users.noMembers' : 'users.noUsers') | translate }}
            </div>
          </td>
        </tr>
      </table>
      <mat-paginator
        #paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalRows"
        [pageIndex]="currentPage"
        (page)="pageChanged($event)"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card>
  </div>
</div>
