import {Component, OnInit} from '@angular/core';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {
  ClientDashboardViewDto,
  ClientService,
  OperatingSystemDashboardViewDto,
  ProductDashboardViewDto,
  UserDashboardViewDto,
  DashboardViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ToastService} from '../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-lancrypt-dashboard',
  templateUrl: './lancrypt-dashboard.component.html',
  styleUrls: ['./lancrypt-dashboard.component.scss'],
})
export class LancryptDashboardComponent implements OnInit {
  private clientApiClient: ClientService;

  private static readonly MAX_PRODUCTS_DRILL_IN_LEVEL: number = 2;
  private static readonly OS_DISPLAY_NAME: Map<string, string> = new Map([
    ['WINDOWS', 'Windows'],
    ['MACOS', 'macOS'],
    ['ANDROID', 'Android'],
    ['IOS', 'iOS'],
    ['LINUX', 'Linux'],
  ]);

  view: [number, number] = [440, 400];

  osTitle = 'dashboard.operatingSystems.title';
  operatingSystemsData?: OperatingSystemDashboardViewDto[] = undefined;
  operatingSystems: PieChartData[] = [];
  osDrilledDown = false;

  productsTitle = 'dashboard.products.title';
  productsData?: ProductDashboardViewDto[] = undefined;
  products: PieChartData[] = [];
  productsDrillInLevel = 0;
  currentProduct?: PieChartData = undefined;

  clientsLastFetched: MatTableDataSource<LastFetchedData>;
  usersLastFetched: MatTableDataSource<LastFetchedData>;

  displayedColumns: string[] = ['last24Hours', 'lastWeek', 'olderThanAWeek', 'never'];

  breakpoint = 3;

  pieColors: any = {
    domain: ['#0096A0', '#FBBA00', '#EF7C00', '#D94190', '#0068B4'],
  };

  constructor(
    private translationService: TranslateService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService,
    private apiClientFactory: ApiClientFactoryService
  ) {
    this.clientApiClient = apiClientFactory.getClientService();

    this.clientsLastFetched = new MatTableDataSource<LastFetchedData>();
    this.usersLastFetched = new MatTableDataSource<LastFetchedData>();
  }

  async loadDashboard() {
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.clientApiClient.getDashboardClientInfo(tenantId).subscribe({
      next: async (n: DashboardViewDto) => {
        this.operatingSystemsData = n.operatingSystems;
        this.operatingSystems = this.mapOperatingSystems(this.operatingSystemsData);

        this.productsData = n.installedProducts;
        this.products = this.mapProducts(this.productsData);

        if (!Object.values(n.clientsLastFetched).every(x => x === null)) {
          this.clientsLastFetched.data = [this.mapLastFetched(n.clientsLastFetched)];
        }
        if (!Object.values(n.userClientsLastFetched).every(x => x === null)) {
          this.usersLastFetched.data = [this.mapLastFetched(n.userClientsLastFetched)];
        }
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('errors.loadingClientInfo')
        );
      },
      complete: () => {},
    });
  }

  async ngOnInit() {
    this.breakpoint = this.chooseBreakpoint(window);
    await this.loadDashboard();
  }

  onResize(event: any) {
    this.breakpoint = this.chooseBreakpoint(event.target);
  }

  private chooseBreakpoint(target: any): number {
    return target.innerWidth <= 1350 ? 1 : 2;
  }

  async onSelectOperatingSystems(item: any): Promise<void> {
    // drill down only when on upper level
    if (this.osDrilledDown) {
      return;
    }

    // drill down
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.clientApiClient
      .getOperatingSystemInfo(tenantId, LancryptDashboardComponent.osByDisplayName(item.name))
      .subscribe({
        next: async (n: OperatingSystemDashboardViewDto[]) => {
          this.operatingSystems = this.mapOperatingSystems(n);
          this.osTitle = item.name;
          this.osDrilledDown = true;
        },
        error: async (_: any) => {
          this.toastService.showError(
            this.translationService.instant('common.error'),
            this.translationService.instant('errors.loadingOperatingSystemInfo')
          );
        },
        complete: () => {},
      });
  }

  async onSelectProducts(item: any): Promise<void> {
    // don't drill down when already on bottom level
    if (this.productsDrillInLevel >= LancryptDashboardComponent.MAX_PRODUCTS_DRILL_IN_LEVEL) {
      return;
    }

    // drill down
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();

    let observable: Observable<any>;

    if (this.productsDrillInLevel === 0) {
      observable = this.clientApiClient.getOperatingSystemCountByProduct(tenantId, item.name);
      this.currentProduct = item;
    } else {
      // this.productsDrillInLevel == 1
      observable = this.clientApiClient.getProductInfo(
        tenantId,
        this.currentProduct!.name,
        LancryptDashboardComponent.osByDisplayName(item.name)
      );
    }

    observable.subscribe({
      next: async n => {
        if (this.productsDrillInLevel === 0) {
          this.products = this.mapOperatingSystems(n);
          this.productsTitle = item.name;
        } else {
          // this.productsDrillInLevel == 1
          this.products = this.mapProducts(n);
          this.productsTitle = this.translationService.instant('dashboard.products.productOnOsDisplayName', {
            product: this.currentProduct!.name,
            os: item.name,
          });
        }
        this.productsDrillInLevel++;
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('errors.loadingProductInfo')
        );
      },
      complete: () => {},
    });
  }

  // Cuts off labels after 20 chars and adds an ellipsis (reason: ngx-charts-pie-chart's built-in trimLabels
  // does not work that well).
  // Moreover, adds an 'en quad' (a simple space is unfortunately cropped by the chart library) at the
  // text's head and tail to add some spacing between the label text and the adjacent line.
  labelFormatter(lbl: string): string {
    return '\u2000' + (lbl.length > 20 ? lbl.slice(0, 20).trim() + '\u2026' : lbl) + '\u2000';
  }

  returnFromDrillDownOperatingSystems(): void {
    this.operatingSystems = this.mapOperatingSystems(this.operatingSystemsData!);
    this.osDrilledDown = false;
    this.osTitle = 'dashboard.operatingSystems.title';
  }

  returnFromDrillDownProducts(): void {
    // drill out...
    this.productsDrillInLevel--;

    if (this.productsDrillInLevel === 1) {
      // drill out one more level since onSelectProducts is
      // implemented to be entered from the level above...
      this.productsDrillInLevel--;
      this.onSelectProducts(this.currentProduct);
    } else {
      this.products = this.mapProducts(this.productsData!);
      this.productsTitle = 'dashboard.products.title';
    }
  }

  mapOperatingSystems(items: OperatingSystemDashboardViewDto[]): PieChartData[] {
    return items.map((os: OperatingSystemDashboardViewDto) => {
      return {
        name: LancryptDashboardComponent.osByName(os.operatingSystem),
        value: os.count,
      };
    });
  }

  mapProducts(items: ProductDashboardViewDto[]): PieChartData[] {
    return items.map((p: ProductDashboardViewDto) => {
      return {
        name: p.installedProduct,
        value: p.count,
      };
    });
  }

  mapLastFetched(item: ClientDashboardViewDto | UserDashboardViewDto): LastFetchedData {
    return {
      last24HoursCount: item.last24HoursCount,
      lastWeekCount: item.lastWeekCount,
      olderThanAWeekCount: item.olderThanAWeekCount,
      neverCount: item.neverCount,
    };
  }

  private static osByName(name: string): string {
    return LancryptDashboardComponent.OS_DISPLAY_NAME.get(name) ?? name;
  }

  private static osByDisplayName(displayName: string): string {
    for (const [name, dispName] of LancryptDashboardComponent.OS_DISPLAY_NAME) {
      if (dispName === displayName) {
        return name;
      }
    }
    return displayName;
  }
}

export interface PieChartData {
  name: string;
  value: number;
}

export interface LastFetchedData {
  last24HoursCount: number;
  lastWeekCount: number;
  olderThanAWeekCount: number;
  neverCount: number;
}
