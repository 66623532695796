import {Injectable, inject} from '@angular/core';
import {ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn} from '@angular/router';
import {Observable} from 'rxjs';
import {FliptWebProvider} from '@openfeature/flipt-web-provider';
import {OpenFeature, EvaluationContext} from '@openfeature/web-sdk';
import {environment} from '../../environments/environment';
import {UserDataService} from './user-data.service';
import {TenantDataService} from './tenant-data.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor() {}

  async clear() {
    await OpenFeature.setContext({});
  }

  isFeatureEnabled(featureName: string): boolean {
    return OpenFeature.getClient().getBooleanValue(featureName, false);
  }
}

export const featureFlagDataResolver: ResolveFn<void> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const userData = inject(UserDataService).userData.value;
  const tenantData = inject(TenantDataService).tenantData.value;

  const evaluationContext: EvaluationContext = {
    targetingKey: tenantData.id,
    companyName: tenantData.companyName,
    email: userData.email,
    licenseType: tenantData.licenseType,
    notForResale: tenantData.notForResale,
    production: environment.production,
    stage: environment.stage,
  };

  return OpenFeature.setContext(evaluationContext);
};

export const featureFlagGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<boolean> => {
  return new Observable(observer => {
    OpenFeature.setProviderAndWait(new FliptWebProvider('default', {url: environment.backends.featureFlagBaseUrl}))
      .then(_ => observer.next(true))
      .catch(error => {
        observer.next(false);
        observer.error(error);
      });
  });
};
