import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BusyService} from './busy.service';
import {finalize, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusyInterceptor implements HttpInterceptor {
  constructor(private busyService: BusyService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.busyService.changeBusy(true);
    return next.handle(req).pipe(finalize(() => this.busyService.changeBusy(false)));
  }
}
