import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DisableDuringRequestDirective} from './disable-during-request.directive';
import {OnlyShowWhenBusyDirective} from './only-show-when-busy.directive';
import {OnlyShowWhenIdleDirective} from './only-show-when-idle.directive';

@NgModule({
  declarations: [DisableDuringRequestDirective, OnlyShowWhenBusyDirective, OnlyShowWhenIdleDirective],
  exports: [DisableDuringRequestDirective, OnlyShowWhenBusyDirective, OnlyShowWhenIdleDirective],
  imports: [CommonModule],
})
export class BusyServiceModule {}
