import {Component, OnInit} from '@angular/core';
import {
  BatchJobResponseDto,
  DownloadFileResponseDto,
  KeyExportService,
  KeyExportViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {JwtHelperService} from '../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../services/toaster.service';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss'],
})
export class DataExportComponent implements OnInit {
  dataSource: Array<KeyExportViewDto> = [];
  displayedColumns = ['created', 'status', 'downloaded', 'download'];

  private keyExportService: KeyExportService;
  private tenantId: string | undefined;

  constructor(
    private apiClientFactory: ApiClientFactoryService,
    private translateService: TranslateService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService
  ) {
    this.keyExportService = apiClientFactory.getKeyExportService();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.loadData();
    });
  }

  startExport() {
    this.keyExportService.startKeyExport(this.tenantId!).subscribe({
      error: async () => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('dataExport.errorStartingExport')
        );
      },
      next: (result: BatchJobResponseDto) => {
        if (result.started) {
          this.toastService.showSuccess(
            this.translateService.instant('common.success'),
            this.translateService.instant('dataExport.exportSuccessfullyStarted')
          );
          this.loadData();
        } else {
          this.toastService.showInfo(
            this.translateService.instant('common.info'),
            this.translateService.instant('dataExport.exportNotStarted')
          );
        }
      },
      complete: () => {},
    });
  }

  isAvailable(element: KeyExportViewDto) {
    return element.status === 'AVAILABLE' || element.status === 'DOWNLOADED';
  }

  statusText(element: KeyExportViewDto): string {
    return this.translateService.instant('dataExport.statusEnum.' + element.status.toString());
  }

  downloadFile(element: KeyExportViewDto) {
    this.keyExportService.downloadKeysExport(this.tenantId!, element.id).subscribe({
      next: (n: DownloadFileResponseDto) => {
        window.open(n.downloadLink);
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('dataExport.errorDownloadingExport')
        );
      },
    });
  }

  private loadData(): void {
    this.keyExportService.listKeyExports(this.tenantId!).subscribe({
      next: async (n: Array<KeyExportViewDto>) => {
        this.dataSource = n;
      },
      error: async () => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('dataExport.errorGettingExports')
        );
      },
      complete: () => {},
    });
  }
}
