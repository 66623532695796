import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UserDataService, UserData} from '../../../services/user-data.service';
import {
  ContactMessageCreateDto,
  TenantService,
  TenantViewDto,
  UserService,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../services/apiclient-factory.service';
import {ToastService} from 'src/app/services/toaster.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-contact-mgm',
  templateUrl: './contact-mgm.component.html',
  styleUrls: ['./contact-mgm.component.scss'],
})
export class ContactMgmComponent implements OnInit {
  userData: UserData | undefined;
  company: TenantViewDto | undefined;

  tenantService!: TenantService;
  userService!: UserService;

  contactFormGroup = this._formBuilder.group({
    subject: ['', [Validators.required, Validators.maxLength(100)]],
    message: [''],
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _apiClientFactory: ApiClientFactoryService,
    private toasterService: ToastService,
    private translateService: TranslateService,
    private userDataService: UserDataService
  ) {
    this.tenantService = this._apiClientFactory.getTenantService();
    this.userService = this._apiClientFactory.getUserService();
  }

  async ngOnInit(): Promise<void> {
    this.userData = this.userDataService.userData.value;
    this.tenantService.getTenantById(this.userData.tenantId).subscribe({
      next: (n: TenantViewDto) => {
        this.company = n;
      },
      error: (_: any) => {},
      complete: () => {},
    });
  }

  buildContactMessageDto(): ContactMessageCreateDto {
    const dto: ContactMessageCreateDto = {
      subject:
        this.contactFormGroup.controls.subject.value === null ? '' : this.contactFormGroup.controls.subject.value,
      message:
        this.contactFormGroup.controls.message.value === null ? '' : this.contactFormGroup.controls.message.value,
      tenantId: this.userData!.tenantId,
      email: this.userData!.email,
    };

    return dto;
  }

  send() {
    if (this.contactFormGroup.invalid) {
      return;
    }

    const dto = this.buildContactMessageDto();

    this.userService.createContactMessage(dto, this.userData!.tenantId).subscribe({
      next: (_: any) => {
        this.toasterService.showSuccess(
          this.translateService.instant('common.success'),
          this.translateService.instant('contact.successMessage')
        );
      },
      error: (_: any) => {},
      complete: () => {},
    });
  }
}
