<div class="create-account-container">
  <mat-card>
    <mat-card-content>
      <button mat-button (click)="logout()">{{ 'buttons.cancel' | translate }}</button>
      <mat-stepper [linear]="true" orientation="horizontal" #stepper id="signup-stepper">
        <mat-step [stepControl]="cookieConsentFormGroup"
                  label="{{'createAccount.cookieConsent.cookieConsentTitle' | translate}}">
          <form [formGroup]="cookieConsentFormGroup" class="form center">
            <div class="dense-form-field-2">
              <h2>{{ 'createAccount.cookieConsent.welcomeTitle' | translate }}</h2>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.welcomeText1' | translate }}
                </mat-label>
              </p>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.welcomeText2' | translate }}
                </mat-label>
              </p>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.welcomeText3' | translate }}
                </mat-label>
              </p>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.welcomeText4' | translate }}
                </mat-label>
              </p>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.welcomeText5' | translate }}
                </mat-label>
              </p>

              <h3>{{ 'createAccount.cookieConsent.cookieConsent' | translate }}</h3>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.consentText' | translate }}
                </mat-label>
              </p>
              <p>
                <mat-label>
                  {{ 'createAccount.cookieConsent.consentLinkText' | translate }} <a
                  href="{{'createAccount.cookieConsent.consentLink'|translate}}"
                  target="_blank">{{ 'createAccount.cookieConsent.consentLink'|translate }}</a>
                </mat-label>
              </p>
              <div class="form-element">
                <mat-checkbox required formControlName="consentCheckbox"
                              [ngClass]="{'errorCheckbox': acceptCookieConsentError()}">
                  {{ 'createAccount.cookieConsent.accept' | translate }}
                </mat-checkbox>
                <mat-error *ngIf="acceptCookieConsentError()">
                  {{ 'createAccount.errors.consentError' | translate }}
                </mat-error>
              </div>
            </div>
            <div class="form-element next-container-first">
              <button mat-button matStepperNext class="next-button"
                      (click)="checkCookieConsent()">{{ 'buttons.next' | translate }}
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="firstFormGroup" label="{{'createAccount.createAccount' | translate}}">
          <form [formGroup]="firstFormGroup" class="form center">
            <div class="dense-form-field-2">
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.firstName' | translate }}</mat-label>
                <input matInput formControlName="firstName" required>
                <mat-error *ngIf="firstFormGroup.hasError('maxlength','firstName')">
                  {{ 'createAccount.errors.firstNameMaxLength' | translate: {maxLength: maxLengthConstraints.firstName} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.lastName' | translate }}</mat-label>
                <input matInput formControlName="lastName" required>
                <mat-error *ngIf="firstFormGroup.hasError('maxlength','lastName')">
                  {{ 'createAccount.errors.lastNameMaxLength' | translate: {maxLength: maxLengthConstraints.lastName} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.email' | translate }}</mat-label>
                <input matInput formControlName="email" required [readonly]="tokenAvailable">
                <mat-error *ngIf="firstFormGroup.hasError('email', 'email')">
                  {{ 'createAccount.errors.email' | translate }}
                </mat-error>
                <mat-error *ngIf="firstFormGroup.hasError('maxlength','email')">
                  {{ 'createAccount.errors.emailMaxLength' | translate: {maxLength: maxLengthConstraints.email} }}
                </mat-error>
                <mat-error *ngIf="firstFormGroup.hasError('emailInUse', 'email')">
                  {{ 'createAccount.errors.emailInUse' | translate }}
                </mat-error>
              </mat-form-field>
              <div *ngIf="!tokenAvailable">
                <mat-form-field appearance="fill" class="form-element">
                  <mat-label>{{ 'createAccount.password' | translate }}</mat-label>
                  <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'">
                  <mat-icon matSuffix (click)="showPassword = !showPassword">{{
                      showPassword ? 'visibility_off' :
                        'visibility'
                    }}
                  </mat-icon>
                  <mat-error *ngIf="firstFormGroup.hasError('required', 'password')">
                    {{ 'createAccount.errors.passwordEmpty' | translate }}
                  </mat-error>
                  <mat-error *ngIf="firstFormGroup.hasError('pattern', 'password')">
                    {{ 'createAccount.errors.passwordRequirementsContains' | translate }}
                  </mat-error>
                  <mat-error *ngIf="firstFormGroup.hasError('maxlength','password') && !firstFormGroup.hasError('pattern', 'password')">
                    {{ 'createAccount.errors.passwordRequirementsMaxLength' | translate: {maxLength: maxLengthConstraints.password} }}
                  </mat-error>
                  <mat-error *ngIf="firstFormGroup.hasError('minlength','password') && !firstFormGroup.hasError('pattern', 'password')">
                    {{ 'createAccount.errors.passwordRequirementsMinLength' | translate: {minLength: minLengthConstraints.password} }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-element">
                  <mat-label>{{ 'createAccount.confirmPassword' | translate }}</mat-label>
                  <input matInput required formControlName="confirmPassword"
                         [type]="showConfirmPassword ? 'text' : 'password'" [errorStateMatcher]="matcher">
                  <mat-icon matSuffix (click)="showConfirmPassword = !showConfirmPassword">{{
                      showConfirmPassword ?
                        'visibility_off' :
                        'visibility'
                    }}
                  </mat-icon>
                  <mat-error *ngIf="firstFormGroup.hasError('notMatch')">
                    {{ 'createAccount.errors.passwordMismatch' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-element next-container">
              <button mat-button matStepperPrevious>{{ 'buttons.back' | translate }}</button>
              <button mat-button matStepperNext class="next-button">{{ 'buttons.next' | translate }}</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" label="{{'createAccount.address' | translate}}">
          <form [formGroup]="secondFormGroup" class="form center">
            <div class="dense-form-field-2">
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.companyName' | translate }}</mat-label>
                <input matInput formControlName="companyName" required>
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','companyName')">
                  {{ 'createAccount.errors.companyNameMaxLength' | translate: {maxLength: maxLengthConstraints.companyName} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.street1' | translate }}</mat-label>
                <input matInput formControlName="street1" required>
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','street1')">
                  {{ 'createAccount.errors.street1MaxLength' | translate: {maxLength: maxLengthConstraints.street1} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.street2' | translate }}</mat-label>
                <input matInput formControlName="street2">
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','street2')">
                  {{ 'createAccount.errors.street2MaxLength' | translate: {maxLength: maxLengthConstraints.street2} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.city' | translate }}</mat-label>
                <input matInput formControlName="city" required>
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','city')">
                  {{ 'createAccount.errors.cityMaxLength' | translate: {maxLength: maxLengthConstraints.city} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.stateProvince' | translate }}</mat-label>
                <input matInput formControlName="state" required>
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','state')">
                  {{ 'createAccount.errors.stateProvinceMaxLength' | translate: {maxLength: maxLengthConstraints.state} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.zipPostalCode' | translate }}</mat-label>
                <input matInput formControlName="postalCode" required>
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','postalCode')">
                  {{ 'createAccount.errors.zipPostalCodeMaxLength' | translate: {maxLength: maxLengthConstraints.postalCode} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.countryRegion' | translate }}</mat-label>
                <input matInput formControlName="country" required>
                <mat-error *ngIf="secondFormGroup.hasError('maxlength','country')">
                  {{ 'createAccount.errors.countryRegionMaxLength' | translate: {maxLength: maxLengthConstraints.country} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-element next-container">
              <button mat-button matStepperPrevious>{{ 'buttons.back' | translate }}</button>
              <button mat-button matStepperNext class="next-button">{{ 'buttons.next' | translate }}</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="fourthFormGroup" label="{{'createAccount.confirmation' | translate}}">
          <form [formGroup]="fourthFormGroup" class="form center">
            <div class="dense-form-field-2">
              <mat-card class="summary-card">
                <mat-card-title>{{ 'createAccount.summary' | translate }}</mat-card-title>
                <fieldset>
                  <div class="form-group">
                    <label>
                      {{ 'createAccount.firstName' | translate }}
                    </label>
                    <span>{{ firstFormGroup.controls.firstName.value }}</span>
                  </div>
                  <div class="form-group">
                    <label>
                      {{ 'createAccount.lastName' | translate }}
                    </label>
                    <span>{{ firstFormGroup.controls.lastName.value }}</span>
                  </div>
                  <div class="form-group">
                    <label>
                      {{ 'createAccount.email' | translate }}
                    </label>
                    <span>{{ firstFormGroup.controls.email.value }}</span>
                  </div>
                  <div class="form-group">
                    <label>
                      {{ 'createAccount.address' | translate }}
                    </label>
                    <span>
                {{ getAddressForSummary() }}
              </span>
                  </div>

                </fieldset>
              </mat-card>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.mobilePhone' | translate }}</mat-label>
                <input matInput formControlName="mobilePhoneNumber" required>
                <mat-error *ngIf="fourthFormGroup.hasError('maxlength','mobilePhoneNumber')">
                  {{ 'createAccount.errors.mobilePhoneMaxLength' | translate: {maxLength: maxLengthConstraints.mobilePhoneNumber} }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="form-element">
                <mat-label>{{ 'createAccount.businessPhone' | translate }}</mat-label>
                <input matInput formControlName="businessPhoneNumber">
                <mat-error *ngIf="fourthFormGroup.hasError('maxlength','businessPhoneNumber')">
                  {{ 'createAccount.errors.businessPhoneMaxLength' | translate: {maxLength: maxLengthConstraints.businessPhoneNumber} }}
                </mat-error>
              </mat-form-field>

              <div class="form-element">
                <mat-checkbox required formControlName="acceptTerms" [ngClass]="{'errorCheckbox': acceptTermsError()}">
                  {{ 'createAccount.termsAndServices1' | translate }}<a target="_blank"
                                                                        href="{{'createAccount.termsAndServicesLink' | translate}}">{{ 'createAccount.termsAndServices2' | translate }}</a>{{ 'createAccount.termsAndServices3' | translate }}
                </mat-checkbox>
                <mat-error *ngIf="acceptTermsError()">
                  {{ 'createAccount.errors.termsAndServicesAccept' | translate }}
                </mat-error>
              </div>
            </div>
            <div class="form-element next-container">
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext (click)="createAccount()" class="next-button" disableDuringRequest
                      [disabled]="buttonDisabled">
                {{ 'buttons.create' | translate }}
              </button>
            </div>
          </form>

        </mat-step>

        <!-- Icon overrides -->
        <ng-template matStepperIcon="create">
          <span>not</span>
        </ng-template>
        <ng-template matStepperIcon="active">
          <span>active</span>
        </ng-template>
        <ng-template matStepperIcon="optional">
          <span>optional</span>
        </ng-template>

      </mat-stepper>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
